import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "./redux/store/index";
import Routes from "./routes/Routes";
import {ConfigProvider} from "antd"

const App = () => (
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          // Seed Token，影响范围大
          colorPrimary: '#00b96b',
        },
        components: {
          Radio: {
            /* 这里是你的组件 token */
            radioSize:22,
            buttonPaddingInline:18,
            dotSize:8
          },
        },
      }}
    >
      <Routes />
    </ConfigProvider>
    <ReduxToastr
      timeOut={5000}
      newestOnTop={true}
      position="top-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  </Provider>
);

export default App;
