import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
    loginPanel as loginPanelRoutes,
    companyLoginPanel as companyLoginRoutes,
    thankYou as thankYouRoutes,
    companyThankYou as companyThankYouRoutes,
    dashboard as dashboardRoutes,
    page as pageRoutes,
    landingPage as landingPageRoutes,//landingPage
    loginBycompanyname,
    partnerLoginPanel,
    loginBycompanyname1,
    loginBycompanyname2,
    resetPassword as ResetPasswordRoutes,
    changePassword as changePasswordRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import LoginLayout from "../layouts/Login";
import LandingPageLayout from "../layouts/LandingPage";
import Page404 from "../pages/auth/Page404";
import ScrollToTop from "../components/ScrollToTop";



const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    );

const Routes = () => (
    <Router>
        <ScrollToTop>
            <Switch>
                {childRoutes(LoginLayout, loginPanelRoutes)}
                {childRoutes(LoginLayout, ResetPasswordRoutes)}
                {childRoutes(LoginLayout, changePasswordRoutes)}
                {childRoutes(LoginLayout, companyLoginRoutes)}
                {childRoutes(AuthLayout, thankYouRoutes)}
                {childRoutes(AuthLayout, companyThankYouRoutes)}
                {childRoutes(DashboardLayout, dashboardRoutes)}
                {childRoutes(AuthLayout, pageRoutes)}
                {childRoutes(LoginLayout, loginBycompanyname)}
                {childRoutes(LoginLayout, partnerLoginPanel)}
                {childRoutes(LandingPageLayout, landingPageRoutes)}
                {childRoutes(LoginLayout, loginBycompanyname1)}
                {childRoutes(LoginLayout, loginBycompanyname2)}
               
                
                <Route
                    render={() => (
                        <AuthLayout>
                            <Page404 />
                        </AuthLayout>
                    )}
                />
            </Switch>
        </ScrollToTop>
    </Router>
);

export default Routes;
