import React, {useState, useRef, useEffect} from "react";

// Services
import AuthService from "../../services/auth.service.js";
import userService from "../../services/user.service.js";
import companiesService from "../../services/companies.service";
import assessmentService from "../../services/assessment.service.js";
import validator from "validator";
// Components
import { Link ,Redirect } from "react-router-dom";
import {
    Card,
    CardBody,
    Form,
    FormGroup,
    Col,
    Input,
    UncontrolledAlert,
    Alert
} from "reactstrap";
import {  Check, X,Send } from "react-feather";
import { Popover } from 'antd';
import CoverIMG from "../../assets/img/photos/Frame.png"
import CompanyCoverIMG from "../../assets/img/photos/man.jpg"
import styles from "./css/Login.module.css";
import helpCenterstyles from "../sgacontainer/style/HelpCenter/helpcenter.module.css";
import pentacle from "../../assets/img/icons/pentacle.png"
import testimg from "../../assets/img/logo/AHRI-Logo.png"
import {toast} from 'react-toastify';
// Constants
const colors = "danger";
toast.configure();
/*
* Login Page
*
* The following page will include
*
* Login form for all users of the platform - (COMPLETED)
* TODO: Registration of user as Public users to the platform (note: need to be tested)
* TODO: Companies to Register their interest to the platform
* TODO: Company Team members to login using their own platform landing page
*
* */

const LoginPanel = (props) => {
    var currentUser = null;
    var companyProfile = null;
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;

    // Get current user from local storage if exist
    const user = AuthService.getCurrentUser();
    if (user &&props.location.search.indexOf('sessionKey') ==-1) {
        currentUser = user;
        props.history.push("/dashboard/default");
        window.location.reload();
    }

    var companLogoIfo = AuthService.getcompanLogoIfo()
    var companyLogoUrl1 = (companLogoIfo!==null && (window.location.pathname === "/companyLogin"||(window.location.pathname === "/login"||window.location.pathname === "/results")) )?(companLogoIfo.companyLogoUrl?true:false):false;
    var partnerLogoUrl = (companLogoIfo!==null && (window.location.pathname === "/partnerLogin" || window.location.pathname === "/companyLogin"||(window.location.pathname === "/login"||window.location.pathname === "/results")) ) ?(companLogoIfo.partnerLogoUrl?true:false):false;

    // 显示状态
    const [inputStatus,setInputStatus] =useState(1)

    // Get current user company from local storage if exist
    const company = AuthService.getCurrentCompany();

    const formUser = useRef();
    const formUserSignup = useRef();

    const [loginEmail, setLoginEmail] = useState("");
    const [password, setPassword] = useState("");

    const [passwordSignup, setPasswordSignup] = useState("");
    const [emailSignup, setEmailSignup] = useState("");

    const [firstNameSignup, setFirstNameSignup] = useState("");
    const [lastNameSignup, setLastNameSignup] = useState("");
    const [jobRoles, setJobRoles] = useState("");
    const [currentJobRole, setCurrentJobRole] = useState(0);

    const [message, setMessage] = useState("");
    const [companymessage, setcompanymessage] = useState("");
    const [messageSignup, setMessageSignup] = useState("");
    const [loading, setLoading] = useState(false);
    const [companyUserEmail, setCompanyUserEmail] = useState("");
    const [passwordscore, setPasswordscore] = useState();
    const [signupMsgvisible, setsignupMsgvisible] = useState(false);
    const signupMsgvisibleDismiss = () => setsignupMsgvisible(false);

    //password rule
    const [PasspasswordRule1, setPasspasswordRule1] = useState(false);
    const [PasspasswordRule2, setPasspasswordRule2] = useState(false);
    const [PasspasswordRule3, setPasspasswordRule3] = useState(false);
    const [PasspasswordRule4, setPasspasswordRule4] = useState(false);

    const onChangeEmail = (e) => {
        const loginMail = e.target.value;
        setLoginEmail(loginMail);
    };
    const onChangeCompanyUserEmail = (e) => {
        setCompanyUserEmail(e.target.value);
    };

    // Fetch job roles from API & populate dropdown
    useEffect(() => {
        if (jobRoles.length === 0) {
            userService.GetJobRoles().then((response) => {
                if (response !== null) {
                    setJobRoles(response);
                }
            });
        }
    });
    useEffect(() =>{
        const generalResolver =()=>{
            let {companyname,login,results} = props.match.params
            let {landingpage} = props.match.params;
            const host = window.location.host;
            const arr = host.split('.');
            if(props.location.search == ''&&window.location.pathname =="/" &&landingpage===undefined&&companyname===undefined && login === undefined && results === undefined){
                AuthService.GetSamlRequest('','').then((response) =>{
                    if(response.code == "200"){
                         // SSO login
                         window.location.href = response.ssoRequest;
                        // console.log("SSO login");
                    }
                })
            }else{
                // if(arr.length == 3){
                //     if(arr[0] !== 'staging'){
                //         AuthService.GetLoginLogoByCompanydefault(arr[0],window.location.href).then((response) =>{
                //             if(response.ssoStatus){
                //                  // SSO login
                //                 window.location.href = response.ssoRequest;
                //             }
                //         })
                //     }
                // }
                if(props.location.search !== ''&&props.location.search.indexOf("login") == -1){
                    let searchData = props.location.search
                    if(searchData.indexOf("AuthId") !== -1){
                        if(searchData.split('?')[1].split('&')[0].split('=')[0] === 'AuthId'){
                            let AuthId = searchData.split('?')[1].split('&')[0].split('=')[1]
                            AuthService.CheckActiveAccount(AuthId).then((response) =>{
                                if(response.code=200){
                                    if(response.message === ''){
                                        props.history.push("/");
                                        window.location.reload();
                                    }else{
                                        alert(response.message)
                                        props.history.push("/");
                                        window.location.reload();
                                    }
                                }
                            })
                        }
                    }else if(searchData.indexOf("user_id") !== -1){
                        let user_id = searchData.split('?')[1].split('&')[0].split('=')[1];
                        let assessment_id = searchData.split('?')[1].split('&')[1].split('=')[1];
                        let credit_id = searchData.split('?')[1].split('&')[2].split('=')[1];
                        assessmentService.GetAllAssessmentList(user_id).then((response)=>{
                            let resultData = response.filter(x=>{
                                return x.CreditId == credit_id;
                            })
                            window.sessionStorage.setItem('resultData',JSON.stringify(resultData));
                            if(landingpage&&landingpage!==""&& !login){
                                if(landingpage == 'results'){
                                    sessionStorage.setItem('results','true')
                                }else{
                                    companiesService.GetCompanyPageInformation(landingpage).then(
                                        (response) => {
                                        if(response!=null){
                                            sessionStorage.setItem('CompanyPageInfo',JSON.stringify(response))
                                            AuthService.GetLoginLogoByCompany(landingpage).then(
                                                (response) => {
                                                    sessionStorage.setItem('LadingCompanyLogo',JSON.stringify(response.data))
                                                    sessionStorage.setItem('PartnerId',JSON.stringify(response.data.artnerId))
                                                    props.history.push("landingpage");
                                                    window.location.reload();
                                                }
                                            );
                                        }else{
                                            props.history.push("/");
                                            window.location.reload();
                                        }
                                        }
                                    );
                                }
                              
                            }else if(companyname !== undefined && login ==="login"){
                                AuthService.GetSamlRequest('','').then((response) =>{
                                    if(response.code == "200"){
                                        // SSO login
                                        window.location.href = response.ssoRequest
                                    }else{
                                        if(response.companyId){
                                            props.history.push("/companyLogin");
                                            window.location.reload();
                                        }else if(response.partnerId){
                                            props.history.push("/partnerLogin");
                                            window.location.reload();
                                        }else{
                                            props.history.push("/");
                                            window.location.reload();
                                        }
                                    }
                                    
                                })
                            }
                        })
                        
                    }
                }else{
                     if(landingpage&&landingpage!==""&& landingpage!=='partnerLogin' && !login){
                        if(landingpage == 'results'){
                            sessionStorage.setItem('results','true');
                        }else if(landingpage == 'login'){
                            return
                        }else{
                            companiesService.GetCompanyPageInformation(landingpage).then(
                                (response) => {
                                if(response!=null){
                                    sessionStorage.setItem('CompanyPageInfo',JSON.stringify(response))
                                    AuthService.GetLoginLogoByCompany(landingpage).then(
                                        (response) => {
                                            sessionStorage.setItem('LadingCompanyLogo',JSON.stringify(response.data))
                                            sessionStorage.setItem('PartnerId',JSON.stringify(response.data.artnerId))
                                            props.history.push("landingpage");
                                            window.location.reload();
                                        }
                                    );
                                }else{
                                    props.history.push("/");
                                    window.location.reload();
                                }
                                }
                            );
                        }
                    }else if(companyname !== undefined && login ==="login"){
                        if(results == 'results'){
                            sessionStorage.setItem('results','true')
                        }
                        AuthService.GetSamlRequest('','').then((response) =>{
                            if(response.code == "200"){
                                 // SSO login
                                window.location.href = response.ssoRequest
                            }else{
                                if(response.companyId){
                                    props.history.push("/companyLogin");
                                    window.location.reload();
                                }else if(response.partnerId){
                                    props.history.push("/partnerLogin");
                                    window.location.reload();
                                }else{
                                    props.history.push("/");
                                    window.location.reload();
                                }
                            }
                        })
                    }
                }
                if(companLogoIfo&&companLogoIfo.comingSoon){
                    setInputStatus(3);
                }
            }
        }
        const SSOResolver =()=>{
            let {companyname,login,results} = props.match.params
            let {landingpage} = props.match.params;
            const host = window.location.host;
            const arr = host.split('.')
            let searchData = props.location.search
            if(searchData.indexOf("AuthId") !== -1){
                if(searchData.split('?')[1].split('&')[0].split('=')[0] === 'AuthId'){
                    let AuthId = searchData.split('?')[1].split('&')[0].split('=')[1]
                    AuthService.CheckActiveAccount(AuthId).then((response) =>{
                        if(response.code=200){
                            if(response.message === ''){
                            }else{
                                alert(response.message)
                            }
                        }
                        if(landingpage&&landingpage!==""&& landingpage!=='partnerLogin' && !login){
                            if(landingpage == 'results'){
                                sessionStorage.setItem('results','true');
                            }
                        }else if(companyname !== undefined && login ==="login"){
                            if(results == 'results'){
                                sessionStorage.setItem('results','true')
                            }
                        }
                        AuthService.UserSessionLogin(props.location.search.split('sessionKey=')[1]).then((response)=>{
                            if (response.Message === "") {
                                setLoading(false);
                                if(response.password_reset_mandatory === "True"){
                                    props.history.push("/userProfile");
                                }else{
                                    props.history.push("/dashboard/default");
                                }
                            } else {
                                setLoading(false);
                                setMessage(response.Message);
                            } 
                        })
                    })
                }
            }else if(searchData.indexOf("user_id") !== -1){
                let user_id = searchData.split('?')[1].split('&')[0].split('=')[1];
                let assessment_id = searchData.split('?')[1].split('&')[1].split('=')[1];
                let credit_id = searchData.split('?')[1].split('&')[2].split('=')[1];
                assessmentService.GetAllAssessmentList(user_id).then((response)=>{
                    let resultData = response.filter(x=>{
                        return x.CreditId == credit_id;
                    })
                    window.sessionStorage.setItem('resultData',JSON.stringify(resultData));
                    if(landingpage&&landingpage!==""&& landingpage!=='partnerLogin' && !login){
                        if(landingpage == 'results'){
                            sessionStorage.setItem('results','true');
                        }
                    }else if(companyname !== undefined && login ==="login"){
                        if(results == 'results'){
                            sessionStorage.setItem('results','true')
                        }
                    }
                })
            }else{
                if(landingpage&&landingpage!==""&& landingpage!=='partnerLogin' && !login){
                    if(landingpage == 'results'){
                        sessionStorage.setItem('results','true');
                    }
                }else if(companyname !== undefined && login ==="login"){
                    if(results == 'results'){
                        sessionStorage.setItem('results','true')
                    }
                }
                sessionStorage.removeItem("SSO");
            }
            if(companLogoIfo&&companLogoIfo.comingSoon){
                setInputStatus(3);
            }
        }
        if(props.location.search.indexOf('sessionKey') !==-1){
            if(props.location.search.indexOf("AuthId") !== -1){
                SSOResolver();
            }else{
                AuthService.UserSessionLogin(props.location.search.split('sessionKey=')[1]).then((response)=>{
                    SSOResolver();
                    if (response.Message === "") {
                        setLoading(false);
                        if(response.password_reset_mandatory === "True"){
                            props.history.push("/userProfile");
                        }else{
                            props.history.push("/dashboard/default");
                        }
                    } else {
                        setLoading(false);
                        setMessage(response.Message);
                    } 
                })
            }
        }else{
            generalResolver();
        }
    },[])

    const validatePassword = (e) => {
        const password = e.target.value;
        if (
            validator.isStrongPassword(password, {
                minLength: 8,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 1,
            })
        ) {
            setMessageSignup("");
            setPasswordSignup(password);
        } else {
            setMessageSignup("Is Not Strong Password");
        }
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangePasswordSignup = (e) => {
        let passwordRule1 = /.{8,}/;
        let passwordRule2 = /[A-Z]/;
        let passwordRule3 = /[a-z]/;
        let passwordRule4 = /\d/;
        const password = e.target.value;
        if(passwordRule1.test(password)){
            setPasspasswordRule1(true)
        }else{
            setPasspasswordRule1(false)
        }
        if(passwordRule2.test(password)){
            setPasspasswordRule2(true)
        }else{
            setPasspasswordRule2(false)
        }
        if(passwordRule3.test(password)){
            setPasspasswordRule3(true)
        }else{
            setPasspasswordRule3(false)
        }
        if(passwordRule4.test(password)){
            setPasspasswordRule4(true)
        }else{
            setPasspasswordRule4(false)
        }
        setPasswordSignup(password);
    };

    const onChangeFirstName = (e) => {
        const firstName = e.target.value;
        setFirstNameSignup(firstName);
    };

    const onChangeLastName = (e) => {
        const lastName = e.target.value;
        setLastNameSignup(lastName);
    };

    const onChangeEmailSignup = (e) => {
        const email = e.target.value;
        setEmailSignup(email);
    };
    const onChangeJobRole = (e) => {
        console.log(e.target.value);
        const jobRole = e.target.value;
        setCurrentJobRole(jobRole);
    };
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(false);
    const [visible1, setVisible1] = useState(false);
    const onDismiss1 = () => setVisible1(false);
    if (company) {
        companyProfile = company;
        if (companyProfile) {
            props.history.push("/dashboard/default");
            window.location.reload();
        }
    }
    //const validateUserEmailCompanyLogin = (e) => {

    //    if (companyUserEmail != "") {
    //        companiesService.GetCompanyDetailsByFromUserEmail(companyUserEmail).then(
    //            (response) => {

    //                if (response != "" && response>0) {

    //                    props.history.push("/companyLogin?cid=" + response);
    //                    window.location.reload();
    //                }
    //                else {
    //                    props.history.push("/");
    //                    window.location.reload();

    //                }
    //            }
    //        );
    //    }
    //    else {
    //        props.history.push("/");
    //        window.location.reload();

    //    }
    //}

    // Public login handler
    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        if (loginEmail !== "" && password !== "") {
            AuthService.loginUser(loginEmail, password).then((response) => {
                if(response.password_reset_mandatory === "True"){
                    window.sessionStorage.setItem("password_reset_mandatory",loginEmail);
                    props.history.push("/ChangePassword");
                }else{
                    if (response.Message === "") {
                        setLoading(false);
                        props.history.push("/dashboard/default");
                    } else {
                        setLoading(false);
                        setMessage(response.Message);
                    }
                }
            });
        } else {
            setLoading(false);
            setMessage("Kindly fill all the fields");
        }
    };

    // Public signup handler
    const handleSignup = (e) => {
        console.log(currentJobRole);
        e.preventDefault();
        setMessageSignup("");
        if (
            passwordSignup !== "" &&
            firstNameSignup !== "" &&
            lastNameSignup !== "" &&
            emailSignup !== ""&&
            currentJobRole !== 0
        ) {
            if(!PasspasswordRule1 || !PasspasswordRule2 || !PasspasswordRule3|| !PasspasswordRule4){
                setsignupMsgvisible(true);
                setMessageSignup(
                    "Password is too weak. You must use a stronger password to continue."
                );
            }else{
                AuthService.registerUser(
                    firstNameSignup,
                    lastNameSignup,
                    emailSignup,
                    passwordSignup,
                    currentJobRole
                ).then((response) => {
                    console.log(response);
                    if (response.data.Status === "Success") {
                        props.history.push("/thankyou");
                        window.location.reload();
                    } else {
                        setsignupMsgvisible(true)
                        setMessageSignup(response.data.Message);
                        props.history.push("/");
                    }
                });
            }
        } else {
            setsignupMsgvisible(true);
            setMessageSignup(
                "All the fields are required, kindly fill all the fields"
            );
        }
    };
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email , setEmail ] = useState("");
    const [NatureOfEnquiry , setNatureOfEnquiry ] = useState("");
    const [Comments, setComments] = useState("");
    

    const handleFirstName = (e) => {
        setFirstName(e.target.value);
    };
    const handleLastName = (e) => {
        setLastName(e.target.value);
    };

    const onChangeEmail1 = (e) => {
        setEmail(e.target.value);
    };
    const onChangeNatureOfEnquiry = (e) => {
        setNatureOfEnquiry(e.target.value);
    };
    const onChangeComments = (e) => {
        setComments(e.target.value);
    };

    const SubmitEvent = () => {
        if(FirstName == "" || LastName == "" || Email =="" || NatureOfEnquiry==""){
            toast.error('Kindly fill all the fields!!', {position: toast.POSITION.TOP_CENTER})
        }else{
            AuthService.SendHelpCenterEmail(FirstName,LastName,Email,NatureOfEnquiry,Comments).then((response)=>{
                if(response.data.Status == 'success'){
                    toast.success('Sent successfully', {position: toast.POSITION.TOP_CENTER})
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setNatureOfEnquiry("");
                    setComments("");
                }else{
                    toast.error(response.data.Message, {position: toast.POSITION.TOP_CENTER})
                }
            })
        }
    }
    const handleSignupbyCompanyEmail = (e) =>{
        e.preventDefault();
        setcompanymessage("");
        if(companyUserEmail === ''){
            setVisible1(true)
            setcompanymessage('Please enter the email address.')
        }else{
            let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
            if(!reg.test(companyUserEmail)){
                setVisible1(true)
                setcompanymessage('Invalid email address. Please try again.')
            }else{
                companiesService.GetCompanyPageUrl(companyUserEmail).then((response) => {
                    console.log(response);
                    if(response===null){
                        setVisible(true)
                    }else{
                        props.history.push(`/${response.company_page_url}/login`);
                        window.location.reload();
                    }
                })
            }
        }
    }
    if (1) {
        return (
            <React.Fragment>
                {/* Signup Area */}
                <Col sm="5" md="8" lg="6" className="mx-auto p-0">
                    <Card className="h-100 shadow-lg" style={{overflow: 'hidden'}}>
                        <img src={window.location.pathname === "/companyLogin"? CompanyCoverIMG:CoverIMG} alt="Cover img" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                    </Card>
                </Col>

                {/* Login Area */}
                <Col sm="5" md="8" lg="6" className="mx-auto p-0">
                    {inputStatus == 1 &&<Card className="h-100 shadow-lg">
                        <div className="login-card">
                            <CardBody>
                                <div style={{margin: "0 2rem"}}>
                                    {window.location.pathname === "/companyLogin"?
                                        (<div className={styles.LogoContent} style={{marginTop: "0"}}>
                                        <div className={styles.LogoContent_Top}>
                                            {(((window.location.pathname === "/login"||window.location.pathname === "/results") && window.location.host.split('.').length !==3)||(window.location.pathname === "/partnerLogin"&&partnerLogoUrl))&&<div style={{width: "50%"}}>
                                                <img src={require('../../assets/img/logo/AHRI-Logo.png').default}
                                                alt={"logo"}
                                                style={{width:'80%'}}
                                                    />
                                            </div>}
                                            {(window.location.pathname === "/partnerLogin"&&partnerLogoUrl)&&
                                                <div style={{width: "50%",textAlign: "right"}}>
                                                <img src={companLogoIfo.partnerLogoUrl}
                                                alt={"logo"}
                                                style={{width:'80%'}}
                                                    />
                                                </div>
                                            }
                                            {((window.location.pathname === "/companyLogin"&&companyLogoUrl1) || ((window.location.pathname === "/login"||window.location.pathname === "/results")&&companyLogoUrl1&&window.location.host.split('.').length ==3))&&
                                                <div>
                                                    <img src={companLogoIfo.companyLogoUrl}
                                                    alt={"logo"} width="90%"
                                                        />
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <div style={{fontSize: '40px',textAlign: 'center',color: '#0071B8',fontWeight: 'bold',lineHeight: '1em'}}>
                                            Organisational <br></br> Training Needs Analysis
                                            </div>
                                            <div style={{fontSize: '23px',textAlign: 'center',lineHeight: '27px'}}>
                                            You've arrived here because you've been selected to represent your company in shaping a bespoke HR training program. Your insights will pave the way for targeted development and strategic growth
                                            </div>
                                        </div>
                                       
                                        </div>):
                                        (<div className={styles.LogoContent}>
                                            <div className={styles.LogoContent_Top}>
                                                {(((window.location.pathname === "/login"||window.location.pathname === "/results") && window.location.host.split('.').length !==3)||(window.location.pathname === "/partnerLogin"&&partnerLogoUrl))&&<div style={{width: "50%",textAlign: 'center'}}>
                                                    <img src={require('../../assets/img/logo/AHRI-Logo.png').default}
                                                    alt={"logo"}
                                                    style={{width:'80%'}}
                                                     />
                                                </div>}
                                                {(window.location.pathname === "/partnerLogin"&&partnerLogoUrl)&&
                                                    <div style={{width: "50%",textAlign: "right"}}>
                                                    <img src={companLogoIfo.partnerLogoUrl}
                                                    alt={"logo"}
                                                    style={{width:'80%'}}
                                                     />
                                                    </div>
                                                }
                                                {((window.location.pathname === "/companyLogin"&&companyLogoUrl1) || ((window.location.pathname === "/login"||window.location.pathname === "/results")&&companyLogoUrl1&&window.location.host.split('.').length ==3))&&
                                                    <div>
                                                        <img src={companLogoIfo.companyLogoUrl}
                                                        alt={"logo"} width="90%"
                                                         />
                                                    </div>
                                                }
                                            </div>
                                            <div style={{textAlign:'center',fontSize:'50px',lineHeight:'1em',color: '#0071B8',fontWeight:'bold'}}>
                                            Australian HR Capability Analysis
                                            </div>
                                           
                                        </div>)
                                    }
                                    <hr className={styles.hr}/>
                                    <div>
                                        <p style={{fontSize: "1.6rem",fontWeight:'600'}}>Login</p>
                                        {/* <p>Hello! Welcome to SkillsGap analysis.</p> */}
                                    </div>
                                    <Form onSubmit={handleLogin} ref={formUser}>
                                        <FormGroup>
                                            <Input
                                                bsSize="lg"
                                                type="email"
                                                name="email"
                                                className={styles.commoninput}
                                                placeholder="Email Address"
                                                onChange={onChangeEmail}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                bsSize="lg"
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                className={styles.commoninput}
                                                onChange={onChangePassword}
                                            />
                                        </FormGroup>
                                        <div className="signin-div" style={{display:'flex'}}>
                                            <div className="forgot-div" style={{display: 'inline'}}>
                                                <small>
                                                    <Link
                                                        className="btn  pl-0"
                                                        to="/resetPassword"
                                                    >
                                                        Forgot password?
                                                    </Link>
                                                </small>
                                            </div>
                                            <button
                                                className="btn ml-auto mr-0  font-weight-bold theme_bgcolor1"
                                                style={{color: '#ffffff'}}
                                                disabled={loading}
                                            >
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span>Login</span>
                                            </button>
                                        </div>
                                    </Form> 
                                    <hr className={styles.hr}/>
                                    {window.location.pathname === "/companyLogin"?
                                        (   
                                        <div>
                                            <span style={{color:"#0071B8",fontWeight:"bold"}}>This is only for organisational use</span><br></br>
                                            This assessment is exclusively for organisational enhancement.<br></br>
                                            Personal career development assessments are available to AHRI members. <br></br>
                                            Not a member yet?<br></br>
                                            <div className={styles.CreateBtn} style={{color:"#0071B8",borderBottom: '1px solid #0071B8'}} onClick={()=>{setInputStatus(2)}}>Create account</div> to embark on your personal growth journey with AHRI.<br></br>
                                        </div>
                                           
                                        ):
                                        (<div>
                                            <div className="d-flex" style={{justifyContent:'space-between',alignItems: 'center'}}>
                                                {((window.location.pathname === "/companyLogin"&&companyLogoUrl1) || ((window.location.pathname === "/login"||window.location.pathname === "/results")&&companyLogoUrl1&&window.location.host.split('.').length ==3))? '' :<div>
                                                    <p style={{marginBottom:'0'}}>Don't have an account?</p>
                                                    <div className={styles.CreateBtn} onClick={()=>{setInputStatus(2)}}>Create account</div>
                                                </div>}
                                                {/* {<div className={styles.shadowBox}>
                                                    <img src={require('../../assets/img/logo/AHRI-Logo.png').default}
                                                    alt={"logo"} width="50%"
                                                    height="85%" />
                                                    <div style={{marginLeft:"0.8rem",color:'#00995E',fontWeight:'500'}}>Login&nbsp;with&nbsp;SSO</div>
                                                </div>} */}
                                            </div>
                                            <div className={styles.InterestLinkBox}>
                                                {/* <div className={styles.CreateBtn}>
                                                <Link to="/auth/interest" style={{color: "#495057"}}className={styles.InterestLink}>Register Your Interest</Link>
                                                </div> */}
                                                
                                                <div className={styles.CreateBtn} onClick={()=>{setInputStatus(4)}}>Need Help?</div>
                                                
                                            </div>
                                        </div>
                                        )
                                    }    
                                    <div>
                                        {message && (
                                            <UncontrolledAlert color={colors}>
                                                <div className="alert-message"  style={{padding:'0.95rem 1.2rem'}}>
                                                    <strong>{message}</strong>
                                                </div>
                                            </UncontrolledAlert>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </Card>}
                    {inputStatus == 2 &&<Card className="h-100 shadow-lg">
                        <div className="login-card">
                            <CardBody>
                                <div style={{margin: "0 2rem"}}>
                                    <div className={styles.LogoContent}>
                                        <div className={styles.LogoContent_Top}>
                                            {(((window.location.pathname === "/login"||window.location.pathname === "/results") && window.location.host.split('.').length !==3)||(window.location.pathname === "/partnerLogin"&&partnerLogoUrl))&&<div style={{width: "50%"}}>
                                                <img src={require('../../assets/img/logo/AHRI-Logo.png').default}
                                                alt={"logo"}
                                                style={{width:'80%'}}
                                                 />
                                            </div>}
                                            {(window.location.pathname === "/partnerLogin"&&partnerLogoUrl)&&
                                                <div style={{width: "50%",textAlign: "right"}}>
                                                <img src={companLogoIfo.partnerLogoUrl}
                                                alt={"logo"}
                                                style={{width:'80%'}}
                                                 />
                                                </div>
                                            }
                                            {((window.location.pathname === "/companyLogin"&&companyLogoUrl1) || ((window.location.pathname === "/login"||window.location.pathname === "/results")&&companyLogoUrl1&&window.location.host.split('.').length ==3))&&
                                                <div>
                                                    <img src={companLogoIfo.companyLogoUrl}
                                                    alt={"logo"} width="90%"
                                                     />
                                                </div>
                                            }
                                        </div>
                                        {/* <div style={{textAlign:'center',fontSize:'50px',fontWeight:'bold',lineHeight:'1.2'}}>
                                            Global Academy<br></br> of Procurement
                                        </div> */}
                                       
                                    </div>
                                    <div>
                                        <p style={{fontSize: "1.6rem",fontWeight:'600'}}>SignUp</p>
                                        <p style={{display:'inline-block'}}>Already have an account</p>
                                        <div className={styles.CreateBtn} style={{marginLeft:"1rem"}} onClick={()=>{setInputStatus(1)}}>Login</div>
                                    </div>
                                    <Form onSubmit={handleSignup} ref={formUserSignup}>
                                        <FormGroup>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="firstname"
                                                placeholder="First name"
                                                className={styles.commoninput}
                                                value={firstNameSignup}
                                                onChange={onChangeFirstName}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="lastname"
                                                placeholder="Last name"
                                                className={styles.commoninput}
                                                value={lastNameSignup}
                                                onChange={onChangeLastName}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <select
                                                style={{width:"100%"}}
                                                value={currentJobRole}
                                                className={styles.commoninput}
                                                onChange={onChangeJobRole}
                                            >
                                                <option key={0} value={0} disabled>
                                                    Job Role
                                                </option>
                                                {jobRoles &&
                                                jobRoles.length > 0 &&
                                                jobRoles.map((schema) => {
                                                    return (
                                                        <option key={schema.id} value={schema.id}>
                                                            {schema.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                bsSize="lg"
                                                type="email"
                                                name="email"
                                                placeholder="Email Address"
                                                className={styles.commoninput}
                                                value={emailSignup}
                                                onChange={onChangeEmailSignup}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Popover
                                                content={
                                                    <div>
                                                        <p>{PasspasswordRule1? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}At least 8 characters long.</p>
                                                        <p>{PasspasswordRule2? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one uppercase letter.</p>
                                                        <p>{PasspasswordRule3? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one lowercase letter.</p>
                                                        <p>{PasspasswordRule4? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one number.</p>
                                                    </div>}
                                                placement="right"
                                                title={
                                                    <span>
                                                        Password requirements:
                                                    </span>
                                                }
                                                trigger="focus">
                                                <Input
                                                    type="password"
                                                    bsSize="lg"
                                                    placeholder="Password"
                                                    name="password"
                                                    // maxLength={10}
                                                    className={styles.commoninput}
                                                    value={passwordSignup}
                                                    onChange={onChangePasswordSignup}
                                                    onFocus={onChangePasswordSignup}
                                                />
                                            </Popover>                                     
                                        </FormGroup>
                                        {/* <Link to="/thankyou"> */}
                                        <button className="btn btn-primary ml-auto mr-0 d-block font-weight-bold">
                                            Register
                                        </button>
                                        {/* </Link> */}
                                        <div className={"mt-3"}>
                                            {messageSignup && (
                                                <Alert color={colors} isOpen={signupMsgvisible} toggle={signupMsgvisibleDismiss}>
                                                    <div className="alert-message"  style={{padding:'0.95rem 1.2rem'}}>
                                                        <strong>{messageSignup}</strong>
                                                    </div>
                                                </Alert>
                                            )}
                                        </div>
                                    </Form>
                                   
                                    <div>
                                        {message && (
                                            <UncontrolledAlert color={colors}>
                                                <div className="alert-message"  style={{padding:'0.95rem 1.2rem'}}>
                                                    <strong>{message}</strong>
                                                </div>
                                            </UncontrolledAlert>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </Card>}
                    {inputStatus == 3 &&<Card className="h-100 shadow-lg">
                    <div className="login-card">
                            <CardBody>
                                <div style={{margin: "0 2rem"}}>
                                    <div className={styles.LogoContent}>
                                        <div className={styles.LogoContent_Top}>
                                            {(((window.location.pathname === "/login"||window.location.pathname === "/results") && window.location.host.split('.').length !==3)||(window.location.pathname === "/partnerLogin"&&partnerLogoUrl))&&<div style={{width: "50%"}}>
                                                <img src={require('../../assets/img/logo/AHRI-Logo.png').default}
                                                alt={"logo"}
                                                style={{width:'80%'}}
                                                 />
                                            </div>}
                                            {(window.location.pathname === "/partnerLogin"&&partnerLogoUrl)&&
                                                <div style={{width: "50%",textAlign: "right"}}>
                                                <img src={companLogoIfo.partnerLogoUrl}
                                                alt={"logo"}
                                                style={{width:'80%'}}
                                                 />
                                                </div>
                                            }
                                            {((window.location.pathname === "/companyLogin"&&companyLogoUrl1) || ((window.location.pathname === "/login"||window.location.pathname === "/results")&&companyLogoUrl1&&window.location.host.split('.').length ==3))&&
                                                <div>
                                                    <img src={companLogoIfo.companyLogoUrl}
                                                    alt={"logo"} width="90%"
                                                     />
                                                </div>
                                            }
                                        </div>
                                        <div style={{textAlign:'center',fontSize:'50px',fontWeight:'bold',lineHeight:'1.2'}}>
                                            Global Academy<br></br> of Procurement
                                        </div>
                                       
                                    </div>
                                    <hr className={styles.hr}/>
                                    <div style={{textAlign:'center',fontSize:'42px',color:'#000000'}}>
                                        <img src={pentacle} alt="pentacle" style={{width:'45px'}}/>
                                        <p style={{fontSize:'24px'}}>Bookmark this page</p>
                                        <p style={{fontStyle:'italic'}}>Something<br></br> <span style={{fontWeight:'bold'}}>awesome</span> is<br></br> coming soon!</p>
                                    </div>
                                    
                                    <div>
                                        {message && (
                                            <UncontrolledAlert color={colors}>
                                                <div className="alert-message"  style={{padding:'0.95rem 1.2rem'}}>
                                                    <strong>{message}</strong>
                                                </div>
                                            </UncontrolledAlert>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </Card>}
                    {inputStatus == 4 &&<Card className="h-100 shadow-lg">
                    <div className="login-card">
                            <CardBody>
                            <div className={styles.LogoContent}>
                                <div className={styles.LogoContent_Top} style={{marginBottom:'0'}}>
                                    <div style={{width: "100%",textAlign: "center"}}>
                                        <img src={require('../../assets/img/logo/AHRI-Logo.png').default}
                                        alt={"logo"} width="40%"
                                        height />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p style={{fontSize: "1.6rem",fontWeight:'600'}}>Help Center</p>
                            </div>
                            <div className="">
                                    <input name="inf_form_xid" type="hidden" value="52c9b3d4283f2414b3de30019edc8cf0" />
                                    <input name="inf_form_name" type="hidden" value="Help Center" />
                                    <input name="infusionsoft_version" type="hidden" value="1.70.0.527541" />
                                    <div className="infusion-field">
                                        <label htmlFor="FirstName" className={helpCenterstyles.color_92929D}>First Name*</label>
                                        <input id="FirstName"  value={FirstName} onChange={handleFirstName} className={helpCenterstyles.commoninput} name="FirstName"  placeholder="Enter First Name..." type="text" required/>
                                    </div>
                                    <div className="infusion-field">
                                        <label htmlFor="LastName" className={helpCenterstyles.color_92929D}>Last Name*</label>
                                        <input id="LastName" className={helpCenterstyles.commoninput} name="LastName" value={LastName} onChange={handleLastName} placeholder="Enter Last Name..." type="text" required/>
                                    </div>
                                    <div className="infusion-field">
                                        <label htmlFor="Email" className={helpCenterstyles.color_92929D}>Email Address*</label>
                                        <input id="Email" className={helpCenterstyles.commoninput} name="Email" value={Email} onChange={onChangeEmail1} placeholder="Enter Email Address..." type="text" required/>
                                    </div>
                                    <div className="infusion-field" style={{flexDirection: "column"}} value={NatureOfEnquiry} onChange={onChangeNatureOfEnquiry}>
                                        <label htmlFor="NatureOfEnquiry" className={helpCenterstyles.color_92929D}>What is the primary nature of enquiry?*</label>
                                        <div className="infusion-radio" >
                                            <div className="options-container" >
                                                <span className={helpCenterstyles.commonoption}>
                                                    <input checked={NatureOfEnquiry == 'Navigating or logging into the website'} id="inf_option_Whatistheprimarynatureofenquiry_11626" name="NatureOfEnquiry" className="pt-1" type="radio" value="Navigating or logging into the website" required/>
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11626" style={{lineHeight:"20px",margin:'0'}} className={helpCenterstyles.color_92929D}>Navigating or logging into the
                                                        website</label>
                                                </span>
                                                <span className={helpCenterstyles.commonoption}>
                                                    <input checked={NatureOfEnquiry == 'Taking the assessments and accessing my results'} id="inf_option_Whatistheprimarynatureofenquiry_11628"  name="NatureOfEnquiry" type="radio" value="Taking the assessments and accessing my results" required/>
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11628" style={{lineHeight:"20px",margin:'0'}} className={helpCenterstyles.color_92929D}>Taking the assessments and accessing
                                                        my results</label>
                                                </span>
                                                <span className={helpCenterstyles.commonoption}>
                                                    <input checked={NatureOfEnquiry == 'Other'} id="inf_option_Whatistheprimarynatureofenquiry_11771"
                                                        name="NatureOfEnquiry" type="radio" value="Other" />
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11771" style={{lineHeight:"20px",margin:'0'}} className={helpCenterstyles.color_92929D}>Other</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="infusion-field" style={{flexDirection: "column"}}>
                                <label htmlFor="Comments" style={{textAlign: 'center'}}>Please provide additional supporting comments.</label>
                                <textarea cols="24" id="Comments" name="Comments" className={helpCenterstyles.textareaStyle}
                                    placeholder="Comments..." rows="5" value={Comments} onChange={onChangeComments}></textarea>
                            </div>
                            <div>
                                <div>&nbsp;</div>
                            </div>
                            <div className="infusion-submit" style={{textAlign: 'center'}}>
                                <button  id="recaptcha_52c9b3d4283f2414b3de30019edc8cf0" className={helpCenterstyles.sendBtn +' infusion-recaptcha'} onClick={SubmitEvent}>Send<Send size={15}style={{marginLeft:"0.3rem"}} /></button>
                            </div>
                            </CardBody>
                        </div>
                    </Card>}
                </Col>
            </React.Fragment>
        );
    } else {
        return (
            <div>
                <Redirect to="/dashboard/default"/>
            </div>
        );
    }
};
export default LoginPanel;
