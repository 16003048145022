import React, { useState, useEffect } from "react";

// Components
import { Container } from "reactstrap";
import { Card, CardBody, Media, Table, Progress, Row, Col } from "reactstrap";
import { Pagination } from 'antd';

// Services
import AuthService from "../../../services/auth.service.js";
import learningplanService from "../../../services/learningplans.service.js";
import dayjs from 'dayjs';
// Assets
import dashboard_1 from "../../../assets/img/photos/dashboard_1.png";
import dashboard_2 from "../../../assets/img/photos/dashboard_2.png";
import dashboard_3 from "../../../assets/img/photos/dashboard_3.png";
import resultdashboard_1 from "../../../assets/img/icons/resultsIcon.svg";



const ResultDsahboard = (props) => {
    // Get current user from local storage if exist
    var currentUser = AuthService.getCurrentUser();

    // Get current user company from local storage if exist
    var company = AuthService.getCurrentCompany();

    // Push user out to the login page if login session not found
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    sessionStorage.removeItem('results');
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);
    const [allLearningplans,setAllLearningplans]= useState([]);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    useEffect(() =>{
        const GetUserLearningPlansList = () =>{
            
        }
            learningplanService.GetUserLearningPlansList(currentUser.id).then((response) => {
                return response;
            })
            .then((data) => {
                console.log(data);
                setAllLearningplans(data.AssessmentLearningPlanList);
            })
            .catch((e) => {
                console.log(e);
            });
    },[])
        // Handler for starting an assessment
        const handleStartAssesmentClk = (Schema) => {
            var downloadUrl =  Schema.DownloadUrl;
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
        }

    return (
        <div id="dashboard_div">
            <Container fluid className="p-0">
                <Row className="mb-2 mb-xl-4">
                    <Col xs="auto" className="d-none d-sm-block">
                        <h2>Welcome to your Learning Plans</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} xl={3}>
                        <Card className="flex-fill bg-primary text-white mb-0" style={{ height: '89%', overflow: "hidden" }}>
                            <CardBody>
                                <Media>
                                    <Media body>
                                        <h5 className="mb-2 text-white">Congratulations, </h5>
                                        <h3 className="text-white">{currentUser.FirstName}</h3>
                                        <div className="mb-0">
                                            Here are your learning plans
                                        </div>
                                    </Media>
                                    <img src={resultdashboard_1} width='40%' alt="" className=" " />
                                </Media>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {allLearningplans.slice(indexOfFirstPost, indexOfLastPost).map((item, i) => {
                        return(
                            <Col xs={12} md={6} xl={3} key={i} className='mt-3'>
                                <Card className="flex-fill mb-0" style={{ height: '100%'}}>
                                    <CardBody>
                                        <h2 style={{fontWeight: '800'}}>
                                            {item.TitleText}
                                        </h2>
                                        <div style={{ fontSize: '1.5rem',fontWeight: '700',marginBottom:"10px"}}>
                                            Learning Plan
                                        </div>
                                        <p style={{color:'#93D3A2',fontSize:'16px',fontWeight:'600',cursor:'pointer',marginBottom:"0"}} onClick={()=>{ handleStartAssesmentClk(item)}}>DOWNLOAD LEARNING PLAN</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
            <Pagination 
                defaultPageSize={12}
                showSizeChanger={false}
                onChange={paginate}
                defaultCurrent={1}
                current={currentPage}
                total={allLearningplans.length}
                style={{margin:'1rem 0'}}
            />
        </div>
    );
};
export default ResultDsahboard;
