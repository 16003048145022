import axios from "axios";

const API_URL = process.env.REACT_APP_SHOP_API_URL;


const GetAllCourseDetails = (currentUserId) => {
     
    return axios
        .get(API_URL + "GetAllCourseDetails", {
            params: {
                currentUserId: currentUserId
            }
        })
        .then((response) => {
            return response.data;
        });
};


const BuyCourses = (numberOfAssessmentSignup, assessmentId, currentUserId) => {

    var buyerDetails = new FormData();
    
    buyerDetails.append("numberOfAssessment", numberOfAssessmentSignup)
    buyerDetails.append("assessmentId", assessmentId)
    buyerDetails.append("currentUserId", currentUserId)

    return axios.post(API_URL + "BuyCourses", buyerDetails);
};

const GetImgPathConfig = () => {
    return axios
    .get(API_URL + "GetImgPathConfig")
    .then((response) => {
        return response.data;
    });
};


export default {
    GetAllCourseDetails, BuyCourses,GetImgPathConfig
};
