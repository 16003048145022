import React, {useState, useRef} from "react";

// Services
import AuthService from "../../services/auth.service.js";

// Components
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Alert,
    Input,
    CardHeader,
    Col
} from "reactstrap";
import Count from "../../components/count.js";
import CoverIMG from "../../assets/img/photos/Frame.png"
import styles from "./css/Login.module.css";
import './css/ResetPassword.css'
/*
* Reset Password
*
* The following page will ask for the user email address and the API will send
* an email with the auth code & instructions on how to reset the password
*
* */

const ResetPassword = (props) => {
    
    const [colors, setcolors] = useState("danger");
    const [emailForReset, setEmailForReset] = useState("");
    const [messagePassReset, setMessagePassReset] = useState("");
    const [emailStatus, setemailStatus] = useState(false);
    const [authCode, setauthCode] = useState('');
    const [visible, setVisible] = useState(true);

    const onDismiss = () => setVisible(false);
    // 发送验证码
    const resetPassIfEmailValid = () => {
        let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
        if (emailForReset !== "") {
            if(reg.test(emailForReset)) {
                AuthService.resetPasswordIfEmailValid(emailForReset).then((response) => {
                    if (response.Status === "Success") {
                        setemailStatus(true)
                        setVisible(true)
                        setcolors("success")
                        setMessagePassReset(response.Message);
                    }else{
                        setVisible(true)
                        setcolors('danger')
                        setMessagePassReset(response.Message);
                    }
                });
            }else{
                setVisible(true)
                setcolors('danger')
                setMessagePassReset('The email format is incorrect!');
            }
        }
    }
    // 验证验证码
    const CheckResetCode = () => {
        if (emailForReset !== "") {
            AuthService.checkResetCode(emailForReset,authCode,false).then((response) => {
                if (response.Status === "Success") {
                    sessionStorage.setItem("resetcode", authCode);
                    sessionStorage.setItem("ViewId", response.ViewId);
                    props.history.push("/ChangePassword")
                }else{
                    setVisible(true)
                    setcolors('danger')
                    setMessagePassReset(response.Message);
                }
            });
        }
    }
    //倒计时组件的回调方法，点击再次获取时触发
    const callback = () => {
        AuthService.resetPasswordIfEmailValid(emailForReset).then((response) => {
            if (response.Status === "Success") {
                setVisible(true)
                setcolors("success")
                setMessagePassReset(response.Message);
            }else{
                setVisible(true)
                setcolors('danger')
                setMessagePassReset(response.Message);
            }
        });
    };
    // 邮件输入框赋值
    const onChangeEmailForReset = (e) => {
        const email = e.target.value;
        setEmailForReset(email);
    };
    // 验证码赋值
    const onChangeauthCode = (e) => {
        const authCode = e.target.value;
        setauthCode(authCode);
    };

    return (
        <React.Fragment>
                <Col sm="5" md="8" lg="6" className="mx-auto p-0">
                    <Card className="h-100 shadow-lg" style={{overflow: 'hidden'}}>
                        <img src={CoverIMG} alt="Cover img" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                    </Card>
                </Col>
                <Col sm="5" md="8" lg="6" className="mx-auto p-0">
                <Card className="h-100 shadow-lg">
                        <div className="login-card">
                            <CardBody>
                                <div style={{margin: "0 2rem"}}>
                                    <div className={styles.LogoContent}>
                                        <div className={styles.LogoContent_Top} style={{marginBottom:'0'}}>
                                            <div style={{width: "100%",textAlign: "center"}}>
                                                <img src={require('../../assets/img/logo/AHRI-Logo.png').default}
                                                alt={"logo"} width="40%"
                                                height />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{fontSize: "1.6rem",fontWeight:'600'}}>Reset password</p>
                                    </div>
                                    <Form>
                                        <FormGroup>
                                            <Input
                                                bsSize="lg"
                                                type="email"
                                                name="email"
                                                disabled={emailStatus}
                                                placeholder="EMAIL ADDRESS"
                                                className={styles.commoninput}
                                                value={emailForReset}
                                                onChange={onChangeEmailForReset}
                                            />
                                            {emailStatus && (
                                                <div style={{position: 'relative'}}>
                                                <Input
                                                    bsSize="lg"
                                                    type="text"
                                                    name=""
                                                    placeholder="RESET CODE"
                                                    className={styles.commoninput}
                                                    value={authCode}
                                                    style={{marginTop:"2rem"}}
                                                    onChange={onChangeauthCode} />
                                                    <Count duration={60}
                                                    callback={callback}
                                                    execute={true}
                                                    />
                                                    </div>
                                            )}
                                        </FormGroup>
                                        <div className="mt-3 text-right">
                                            {emailStatus?(
                                            <Button color="spinner-border" className="resetbutton theme_bgcolor1" outline={true} size="lg" 
                                            onClick={CheckResetCode}
                                            >
                                                Verify
                                            </Button>):(
                                            <Button color="spinner-border" className="resetbutton theme_bgcolor1" outline={true} size="lg" 
                                            onClick={resetPassIfEmailValid}
                                            >
                                                Reset
                                            </Button>)}
                                        </div>
                                        <div className={"mt-3"} style={{minHeight:"9rem"}}>
                                            {messagePassReset && (
                                                <Alert color={colors} isOpen={visible} toggle={onDismiss}>
                                                    <div className="alert-message">
                                                        <strong>{messagePassReset}</strong>
                                                    </div>
                                                </Alert>
                                            )}
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </React.Fragment>
    )
}

export default ResetPassword;
