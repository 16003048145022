import React, {useState, useEffect} from "react";

// Services
import TeamsService from "../../../services/teams.service.js";
import AuthService from "../../../services/auth.service.js";
import TeamLeadersService from "../../../services/teamLeader.service.js";

// Component
import {
    FormGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Label,
    Input
} from "reactstrap";
import {Calendar, Filter, RefreshCw, Settings, Plus} from "react-feather";
import {Button, Modal, Alert} from "react-bootstrap";
import DeleteConfirmation from "../../../../src/components/DeleteConfirmation";
import {toast} from 'react-toastify';
import { Avatar,Pagination} from 'antd';
// Assets
import avatar4 from "../../../assets/img/avatars/avatar.png";
import 'react-toastify/dist/ReactToastify.css';
import iconChangeRole from "../../../assets/img/icons/change-role.svg";
import iconDeleteUser from "../../../assets/img/icons/delete.svg";

toast.configure()

var currentUser = AuthService.getCurrentUser();
var currentCompany = AuthService.getCurrentCompany();


{/*...................................................member list code part..............................................................*/
}

/*
* Team User List View
*
* Lists all the users within a team
* */
const UsersList = (props) => {

    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    //if (teamId == 0) {
    var teamId = props.props.history.location.state.data;
    //}
    console.log("got team id in userlist as " + teamId)
    const [usersList, setUsersList] = useState([]);

    //add team modal 
    const [addTeamUsersModalshow, setAddTeamUsersModalshow] = useState(false);

    const addTeamUsersModalClose = () => setAddTeamUsersModalshow(false);

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    //use for users checkbox 
    const [nonUsers, setNonUsers] = useState([]);

    const [userId, setUserId] = useState("");
    const [TeamUsersModalTitle, setTeamUsersModalTitle] = useState("");
    //delete 
    const [id, setId] = useState(null);
    const [type, setType] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState(null);

    //check box 
    const [userNameSignup, setUserNameSignup] = useState("");

    const [arrowPositionFirstName, setArrowPositionFirstName] = useState('arrow down');
    const [arrowPositionLastName, setArrowPositionLastName] = useState('arrow down');
    const [arrowPositionRole, setArrowPositionRole] = useState('arrow down');
    const [state, setState] = useState({
        data: [],
        sort: {
            column: null,
            direction: 'desc',
        },
    });

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {

        //if there is no teamid then set to 0
        if (teamId === null) {
            teamId = 0;
        }

        if (usersList.length === 0) {

            const teamUserData = TeamLeadersService.GetTeamUsersListByTeamId(teamId);

            teamUserData.then((response) => {
                return response;
            })
                .then((data) => {

                    setUsersList(data.teamUsersDetails);
                    setState({data: data.teamUsersDetails});

                })
                .catch(e => {
                    console.log(e);
                })
        }


        if (nonUsers.length === 0) {
            const userData = TeamLeadersService.GetNonTeamMembersList(teamId);

            var details = userData.then((response) => {
                return response;
            })
                .then((data) => {
                    console.log(data);
                    setNonUsers(data.teamUsersDetails);
                })
                .catch(e => {
                    console.log(e);
                })

        }

    }, []);

    const [addUserRoleChangeModalShow, setAddUserRoleChangeModalShow] = useState(false);
    const addUserRoleChangeModalClose = () => setAddUserRoleChangeModalShow(false);
    const addUserRoleChangeModal = () => setAddUserRoleChangeModalShow(true);

    const showDeleteModal = (type, id) => {

        //setting type in case to make this reusable , here setting team user , can be set to user etc
        setType(type);
        //setting the id of the team user
        setId(id);
        var deletionItem = usersList.find((x) => x.id === id);
        var fullName = deletionItem.FirstName + " " + deletionItem.LastName;
        setDeleteMessage(`Are you sure you want to delete the team user  '${fullName}'?`);
        setDisplayConfirmationModal(true);
    };

    const submitDelete = (type, id) => {

        if (type === "Teamusers") {
            TeamLeadersService.ReamoveUserById(id);
            var deletionItem = usersList.find((x) => x.id === id);
            var fullName = deletionItem.FirstName + " " + deletionItem.LastName;
            toast.success(`The Team user '${fullName}' was deleted successfully.`, {position: toast.POSITION.TOP_CENTER})
            setUsersList(usersList.filter((Teamusers) => Teamusers.id !== id));
            setState({data: usersList.filter((Teamusers) => Teamusers.id !== id)});
            //var updatedUsersInAddUserList = nonUsers.push(deletionItem);
            setNonUsers(nonUsers.concat(deletionItem));
        }
        setDisplayConfirmationModal(false);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    //close the add team users modal
    const addTeamUsersDetailsModalClose = () => {
        setAddTeamUsersModalshow(false);
    };

    //add team users model show
    const addTeamUsersDetailsModalShow = () => {
        setAddTeamUsersModalshow(true);
        setNonUsers(nonUsers.map(data => {
            if (data.selecte) {
                data.selecte = false;
            }
            return data;
        }));
        setTeamUsersModalTitle("Add Users")
        setUserId("0");
        setUserNameSignup("");

    };
    const [teamRole,setTeamRole] = useState('Team Leader')
    //role change model
    const ChangeRoleModal = (id,role) => {
        if(role === 'team-member'){
            setTeamRole('Team Leader')
        }else if (role === 'team-leader'){
            setTeamRole('Team Member')
        }
        setUserId(id)
        setAddUserRoleChangeModalShow(true);
    };

    // add the users, if the users id is null/0 then inserts the users
    const addTeamUsers = () => {

        console.log(nonUsers);
        var nonUserId;

        nonUsers.map(d => {
            if (d.selecte === true && d.id !== "") {

                nonUserId = d.id;

                if (userId === "0") {

                    TeamLeadersService.AddNonUserInTeam(nonUserId, teamId).then(
                        (response) => {

                            if (response.data.Status === "Success") {
                                const teamUserData = TeamLeadersService.GetTeamUsersListByTeamId(teamId);

                                teamUserData.then((response) => {
                                    return response;
                                })
                                    .then((data) => {

                                        setUsersList(data.teamUsersDetails);
                                        setState({data: data.teamUsersDetails});

                                    })
                                    .catch(e => {
                                        console.log(e);
                                    })

                                setAddTeamUsersModalshow(false);
                                toast.success("User Added in the team  Successfully", {position: toast.POSITION.TOP_CENTER})
                            } else {
                                toast.error(response.data.Message, {position: toast.POSITION.TOP_CENTER})
                            }
                        }
                    );
                } else {
                    toast.error("Kindly fill all the fields!!", {position: toast.POSITION.TOP_CENTER})
                }
            }
        })
        setNonUsers(nonUsers.filter(d => d.selecte !== true && d.id !== ""));

    };

    const onSort = (column) => {

        const direction = state.sort != undefined && state.sort.column ? (state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const sortedData = state.data.sort((a, b) => {
            if (column === 'FirstName') {
                if (direction === 'desc') {
                    setArrowPositionFirstName('arrow down');
                } else {
                    setArrowPositionFirstName('arrow up');

                }
                const nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === 'LastName') {
                if (direction === 'desc') {
                    setArrowPositionLastName('arrow down');
                } else {
                    setArrowPositionLastName('arrow up');

                }
                const nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === 'Role') {
                if (direction === 'desc') {
                    setArrowPositionRole('arrow down');
                } else {
                    setArrowPositionRole('arrow up');

                }
                const nameA = a.Role.toUpperCase(); // ignore upper and lowercase
                const nameB = b.Role.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

        });

        if (direction === 'desc') {
            setUsersList(sortedData.reverse());

        }
        setState({
            data: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    const btnClick_addUserRoleChange = () => {
        if (userId !== 0) {
            const roleNumber = teamRole ==='Team Leader'?3:2;
            var message = TeamLeadersService.UpdateTeamUserRoleNew(userId, roleNumber).then((response) => {
                return response;
            })
                .then((data) => {
                    if (data.Message !== "success") {
                        toast.error("There is some error saving the role, please try again", {position: toast.POSITION.TOP_CENTER})
                    } else {
                        const teamUserData = TeamLeadersService.GetTeamUsersListByTeamId(teamId);
                        teamUserData.then((response) => {
                            return response;
                        })
                            .then((data) => {
                                setUsersList(data.teamUsersDetails);
                                setState({data: data.teamUsersDetails});
                            })
                            .catch(e => {
                                console.log(e);
                            })
                            setAddUserRoleChangeModalShow(false);
                            toast.success("Role updated successfully", {position: toast.POSITION.TOP_CENTER})
                    }
                })
                .catch(e => {
                    console.log(e);
                })

        } else {
            setAddUserRoleChangeModalShow(false);
            toast.success("Role updated successfully", {position: toast.POSITION.TOP_CENTER})
        }

    }

    {/*...................................................END of member list code part..............................................................*/
    }


    {/*................................. code for team leader part section - left............................................................ */
    }
    const [team, setTeam] = useState("");
    useEffect(() => {
        //if there is no teamid then set to 0
        if (teamId === null) {
            teamId = 0;
        }
        //if (val != null || val != undefined) {
        //    setTeam(val);
        //}
        //else {
        if (team.length === 0) {

            const teamData = TeamsService.GetTeamDetailsById(teamId);

            teamData.then((response) => {
                return response;
            })
                .then((data) => {
                    data.AvatarFont = data.TeamName.slice(0,1).toUpperCase();
                    setTeam(data);
                })
                .catch(e => {
                    console.log(e);
                })
        }
        //}

    });


    {/*...............................filter view  section code........................................................*/
    }
    const [filterViewShow, setFilterViewShow] = useState(false);
    const [filterRoleName, setFilterRoleName] = useState(0);
    const [nameFilterProfile, setNameFilterProfile] = useState(null);
    const onFilterButtonClick = () => {

        let data1 = usersList;
        if (nameFilterProfile != null && nameFilterProfile !== undefined && nameFilterProfile !== "") {
            data1 = data1.filter(x => x.FirstName.toUpperCase().indexOf(nameFilterProfile.toUpperCase()) > -1 || x.LastName.toUpperCase().indexOf(nameFilterProfile.toUpperCase()) > -1)
        }
        if (filterRoleName != 0) {
            data1 = data1.filter(x => x.Role == filterRoleName)
        }
        setState({data: data1});
    }

    const onChangefilterRoleName = (e) => {
        setFilterRoleName(e.target.value);
    }

    const onChangeNameProfile = (e) => {
        const name = e.target.value;
        setNameFilterProfile(name);
    }

    const filterView = () => {
        setFilterViewShow(s => !s);
    };

    {/*...............................filter view  section........................................................*/
    }
    if (currentUser || currentCompany) {

        return (

            <>
                <Row className="mb-2 mb-xl-4">
                    <Col xs="auto" className="ml-auto text-right mt-n1">

                        <Button color="primary" className="shadow-sm mr-1" onClick={filterView}>
                            <Filter className="feather"/>
                        </Button>

                        <Button color="primary" className="shadow-sm">
                            <Settings className="feather"/>
                        </Button>

                    </Col>
                </Row>


                <Row>
                    <Col md={filterViewShow ?
                        "2" : "4"}>
                        <Card>
                            <CardHeader>
                                <h1 className="mb-0 h3">
                                    Teams
                                </h1>
                            </CardHeader>
                            <CardBody className="text-center">
                                <Avatar
                                    style={{
                                    backgroundColor: '#007bff',
                                    verticalAlign: 'middle',
                                    fontSize:"42px"
                                    }}
                                    size={{
                                        xs: 120,
                                        sm: 120,
                                        md: 120,
                                        lg: 120,
                                        xl: 120,
                                        xxl: 120,
                                    }}
                                >
                                { team.AvatarFont}
                                </Avatar>
                            </CardBody><br/>
                            <CardBody>
                                <CardTitle tag="h6">Team Name</CardTitle>
                                <h4 className="mb-0">{team.TeamName}</h4>
                            </CardBody>
                            <hr className="my-0"/>
                            <CardBody>
                                {team.TeamDescription}
                            </CardBody>
                        </Card>
                    </Col>


                    <Col md={filterViewShow ?
                        "6" : "8"}>
                        <Card>

                            <CardHeader>
                                <CardTitle tag="h5">Users  &nbsp;
                                    <button
                                        className="p-2 text-white bg-primary rounded-circle"
                                        onClick={addTeamUsersDetailsModalShow}
                                    >
                                        <Plus size={28}/>
                                    </button>
                                </CardTitle>

                            </CardHeader>
                            <CardBody>

                                {/*model for add team */}
                                <Modal show={addTeamUsersModalshow} onHide={addTeamUsersDetailsModalClose}
                                       backdrop="static">
                                    <Modal.Header closeButton>
                                        <Modal.Title>{TeamUsersModalTitle}</Modal.Title>{"\n"}
                                    </Modal.Header>
                                    <Modal.Body>

                                        <Container>
                                            <Row>

                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                        {nonUsers &&
                                                        nonUsers.length > 0 &&
                                                        nonUsers.map(user => {

                                                            return (<div key={user.id}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`custom-checkbox-${user.id}`}
                                                                    checked={user.selecte}
                                                                    onChange={(event) => {
                                                                        let checked = event.target.checked;
                                                                        setNonUsers(nonUsers.map(data => {
                                                                            if (user.id === data.id) {
                                                                                data.selecte = checked;
                                                                            }
                                                                            console.log(data.selecte);
                                                                            return data;

                                                                        }));
                                                                    }}
                                                                /> &nbsp;
                                                                <label
                                                                    htmlFor={`custom-checkbox-${user.id}`}>{user.FirstName} {user.LastName}</label>
                                                            </div>)
                                                        })}


                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        </Container>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" onClick={addTeamUsers}>
                                            Add
                                        </Button>
                                        <Button variant="secondary" onClick={addTeamUsersDetailsModalClose}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/*model for add team */}

                                {/*model for role change */}

                                <Modal show={addUserRoleChangeModalShow} onHide={addUserRoleChangeModalClose}
                                       backdrop="static">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Change Role</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <Container>
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    Are you sure!! You want to make this user as a {teamRole} !
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" onClick={btnClick_addUserRoleChange}>
                                            Confirm
                                        </Button>
                                        <Button variant="secondary" onClick={addUserRoleChangeModalClose}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <div className="scrollable scrollbar1">
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className="SortButton" onClick={() => onSort('FirstName')}>
                                                    First Name <i className={arrowPositionFirstName}></i>

                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className="SortButton" onClick={() => onSort('LastName')}>
                                                    Last Name <i className={arrowPositionLastName}></i>
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className="SortButton" onClick={() => onSort('Role')}>
                                                    Role <i className={arrowPositionRole}></i>
                                                </button>
                                            </th>
                                            <th>Change Role</th>
                                            <th>Remove</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {state.data.slice(indexOfFirstPost, indexOfLastPost).map(tu => {
                                            return <tr key={tu.id}>
                                                <td>
                                                <Avatar
                                                    style={{
                                                    backgroundColor: '#007bff',
                                                    }}
                                                    size='34'
                                                >
                                                { tu.FirstName.slice(0,1).toUpperCase()}
                                                </Avatar>
                                                </td>
                                                <td>{tu.FirstName}</td>
                                                <td>{tu.LastName}</td>
                                                <td>{tu.Role}</td>
                                                <td>
                                                    <button
                                                        className="btn mx-auto d-block"
                                                        onClick={() => ChangeRoleModal(tu.id,tu.Role)}
                                                    >
                                                        <img src={iconChangeRole} alt="ChangeRoleIcon"/>
                                                    </button>
                                                    
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn mx-auto d-block"
                                                        onClick={() => showDeleteModal("Teamusers", tu.id)}
                                                    >
                                                        <img src={iconDeleteUser} alt="RemoveIcon"/>
                                                    </button>
                                                </td>
                                            </tr>;
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                               
                                {/*Table for user list*/}
                                <Pagination 
                                    defaultPageSize={6}
                                    showSizeChanger={false}
                                    onChange={paginate}
                                    defaultCurrent={1}
                                    current={currentPage}
                                    total={state.data.length == 0 ? 1 : state.data.length}
                                    style={{margin:'1rem 0'}}
                                />
                            </CardBody>
                            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete}
                                                hideModal={hideConfirmationModal} type={type} id={id}
                                                message={deleteMessage}/>
                        </Card>

                    </Col>

                    {/*...............................filter view ........................................................*/}
                    {filterViewShow ?
                        <Col md={4}>
                            <Card>
                                <CardHeader>

                                    <div style={{float: "right"}}>
                                        <Button onClick={() => setFilterViewShow(false)}>close</Button>
                                    </div>
                                    <h1 className="mb-0 h3  text-center">
                                        Filter
                                    </h1>
                                </CardHeader>
                                <CardBody>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label> Name</Label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="name"
                                                placeholder="Name" value={nameFilterProfile}
                                                onChange={onChangeNameProfile}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Role</Label>
                                            <select className="custom-select"
                                                    value={filterRoleName}
                                                    onChange={onChangefilterRoleName}
                                            >
                                                <option value="0">Select Role</option>
                                                {usersList.filter((v, i, a) => a.findIndex(t => (t.Role === v.Role)) === i).map(schema => {
                                                    return <option key={schema.Role}
                                                                   value={schema.Role}>{schema.Role}</option>;
                                                })}


                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <div className="text-center">
                                            <Button variant="primary" onClick={onFilterButtonClick}>
                                                Filter
                                            </Button>
                                        </div>
                                    </Col>

                                </CardBody>


                            </Card>
                        </Col>
                        : <div></div>}
                </Row>
            </>
        );
    } else {
        return (<Card></Card>)
    }
}

/*
* Team Profile view
*
* This view is responsible of displaying all users within
* a Team.
*
* */
const TeamLeaderView = (props) => (
    <div id="team_leader_view_div">

        <UsersList props={props}/>
    </div>
);

export default TeamLeaderView;
