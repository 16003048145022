import React, { useState ,useEffect} from 'react';
import { Input, message } from 'antd';

const WordCountInput = ({maxWords, onChange,value}) => {
  const [Value, setValue] = useState(value);
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    if(value) {
      const inputValue = value;
      const Count = inputValue.trim().split(/\s+/).length;

      if (Count <= maxWords) {
        setValue(inputValue);
        setWordCount(Count);
        onChange(inputValue);
      } else {
        message.warning(`Maximum word count of ${maxWords} exceeded`);
      }
    }
    
  },[])

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const Count = inputValue.trim().split(/\s+/).length;

    if (Count <= maxWords) {
      setValue(inputValue);
      setWordCount(Count);
      onChange(inputValue);
    } else {
      message.warning(`Maximum word count of ${maxWords} exceeded`);
    }
  };

  return (
    <>
    <Input.TextArea
      value={Value}
      onChange={handleChange}
      style={{
        height: 120,
        resize: 'none',
      }}
      variant="filled"
      placeholder={`Enter text (max ${maxWords} words)`}
      rows={4}
    />
    <div>
        {wordCount}/{maxWords}
    </div>
    </>
    
    
  );
};

export default WordCountInput;