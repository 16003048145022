import React, { useEffect, useState } from "react";

// Services
import assessmentService from "../../../services/assessment.service.js";
import AuthService from "../../../services/auth.service.js";
import userService from "../../../services/user.service.js";
import partnerService from "../../../services/partners.service.js";
import companyService from "../../../services/companies.service.js";

// Components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Table,
    Row,
    PopoverBody,
    PopoverHeader,
    UncontrolledPopover,
    FormGroup,
    Label,
    Input,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { Button ,Modal} from "react-bootstrap";
import Select from 'react-select';
import { Pagination } from 'antd';

// Icons
import { Filter, Settings, X, Search,Unlock } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortNumericDown,
    faSortNumericUp,
    faSort,
    faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import iconExport from "../../../assets/img/icons/export.png";
import iconAssign from "../../../assets/img/icons/assignIcon.png";
import iconGenerate from "../../../assets/img/icons/Generateicon.png"
import iconGenerate1 from "../../../assets/img/icons/Generateicon1.png"
import styles from "../style/AssessmentStyle/AssessmentListStyle.module.css";
import { Search as SearchIcon } from "react-feather";


toast.configure()

/*
* Displaying assessments
*
* This page view displays all the assessments for a given user
* TODO: Separate assessments to different views depending on the conditions
* */
const AssessmentsTypes = (props) => {

    // Get current user from local storage
    var currentUser = AuthService.getCurrentUser();


    // If current user doesn't exist push user out to login screen
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    // Initial state
    const [state, setState] = useState({
        data: [],
        sort: {
            column: null,
            direction: "desc",
        },
    });
    //for tab view
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const [allAssessments, setAllAssessments] = useState([]);
    const [assessmentDetails, setAssessmentDetails] = useState([]);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(1);

    // assign assessment
    const [assignModel, setAssignModel] = useState(false);
    const assignToggle = () => {
        setAssignModel(!assignModel);
        setCompanyOptions('');
        setUserOptions('');
        setPartnerOptionsisDisabled(true);
        setCompanyOptionsDataisDisabled(true);
        setUserOptionsDataisDisabled(true);
    };

    //Export model
    const [exportModel, setExportModel] = useState(false);
    const exportToggle = () => {
        setExportModel(!exportModel);
    };
    const [exportStatus, setExportStatus] = useState(false);
    const [exportOption, setExportOption] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState('');

    // column sorting UseStates
    const [arrowPositionAssessment, setArrowPositionAssessment] =
        useState(faSort);
    const [arrowPositionScore, setArrowPositionScore] = useState(faSort);
    const [arrowPositionTime, setArrowPositionTime] = useState(faSort);
    const [arrowDate, setArrowDate] = useState(faSort);
    
    const [arrowPositionStatus, setArrowPositionStatus] = useState(faSort);

    let count = 0;

    //for popover
    const directions = [
        {
            name: "Bottom",
            value: "bottom",
        },
    ];
    if (props.history.action == "POP") {
        var isKeyPresent = JSON.parse(sessionStorage.getItem("ReviewAssessment"));
        if (isKeyPresent == undefined || isKeyPresent == null) {
            props.history.push({
                pathname: "/Assessments",
            });
        }
    }
    //assign select data
    const [partnerOptionsData,setPartnerOptionsData] = useState([]);
    const [partnerOptions,setPartnerOptions] = useState('');
    const [partnerCheckedData,setPartnerCheckedData] = useState('');
    const [partnerOptionsisDisabled,setPartnerOptionsisDisabled] = useState(true);
    
    const [companyOptionsData,setCompanyOptionsData] = useState([{value: "0",label: "Client"}]);
    const [companyOptions,setCompanyOptions] = useState('');
    const [companyCheckedData,setcompanyCheckedData] = useState('');
    const [companyOptionsDataisDisabled,setCompanyOptionsDataisDisabled] = useState(true);

    const [userOptionsData,setUserOptionsData] = useState([]);
    const [userOptions,setUserOptions] = useState('');
    const [userCheckedData,setUserCheckedData] = useState('');
    const [userOptionsDataisDisabled,setUserOptionsDataisDisabled] = useState(true);

    const [showSearchIcon,setShowSearchIcon] = useState(true);
    const [showClearIcon,setShowClearIcon] = useState(false);
    const [searchInputVal,setSearchInputVal] = useState('');
    // 弹窗展示数据
    const [modalDatas,setModalDatas] = useState([]);

    // 分页信息
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    // 过滤数据
    const [UserFilterDatas, setUserFilterDatas] = useState([{value: '0', label: 'Select User Name'}]);
    // 多选数据、方法设置
    const [chooseAssessment, setChooseAssessment] = useState([]);

    const ChooseUser = (data,UserId,AssessmentId,CreditId) =>{
        var ChooseData = state.data
        if(data.target.checked){
            ChooseData.map((item) => {
                if(item.CreditId == CreditId){
                    item.checked = true
                }
            })
            chooseAssessment.push({userId:UserId,assessmentId:AssessmentId,creditId:CreditId})
            setChooseAssessment(chooseAssessment)
        }else{
            ChooseData.map((item) => {
                if(item.CreditId == CreditId){
                    item.checked = false
                }
            })
            chooseAssessment.map((item,index) => {
                if(item.creditId == CreditId){
                    chooseAssessment.splice(index,1)
                }
            })
            setChooseAssessment(chooseAssessment)
        }
        console.log(chooseAssessment);
        setState({data: ChooseData});
    }
    const ClearAll = () =>{
        var ChooseData = state.data;
        ChooseData.map((item) => {
            item.checked = false;
        })
        setChooseAssessment([]);
        setState({data: ChooseData});
    }
    const ReGenerateReport = (type,UserId,AssessmentId,CreditId) =>{
        // type = 1 时批量调用 2时单个调用
        if(type == 1){
            assessmentService.ReGenerateReport(chooseAssessment).then((response)=>{
                if(response.Status == 'Success'){
                    toast.success('New report will be ready shortly, please wait...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    var assessmentList = assessmentService.GetAllAssessmentList(AuthService.getCurrentUser().id);
                    var details = assessmentList
                        .then((response) => {
                            return response;
                        })
                        .then((data) => {
                            setAllAssessments(data);
                            var UserFilterData = [{value: '0', label: 'Select User Name'}];
                            data.filter(
                                (v, i, a) =>
                                    a.findIndex((t) => t.UserName === v.UserName) === i
                            ).map(x =>{
                                UserFilterData.push({value: x.UserName, label: x.UserName})
                            })
                            setUserFilterDatas(UserFilterData);
                            onFilterButtonClick(data);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }else{
                    toast.error("Report couldn't be generated, please try again later", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
        }else{
            assessmentService.ReGenerateReport([{userId:UserId,assessmentId:AssessmentId,creditId:CreditId}]).then((response)=>{
                if(response.Status == 'Success'){
                    toast.success('New report will be ready shortly, please wait...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    var assessmentList = assessmentService.GetAllAssessmentList(AuthService.getCurrentUser().id);
                    var details = assessmentList
                        .then((response) => {
                            return response;
                        })
                        .then((data) => {
                            setAllAssessments(data);
                            var UserFilterData = [{value: '0', label: 'Select User Name'}];
                            data.filter(
                                (v, i, a) =>
                                    a.findIndex((t) => t.UserName === v.UserName) === i
                            ).map(x =>{
                                UserFilterData.push({value: x.UserName, label: x.UserName})
                            })
                            setUserFilterDatas(UserFilterData);
                            onFilterButtonClick(data);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }else{
                    toast.error("Report couldn't be generated, please try again later", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
        }
    }

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //assign select function
    const filterOptions = (
        candidate,
        input
      ) => {
        if (input) {
          return candidate.value.toLowerCase().indexOf(input.toLowerCase())!=-1;
        }
        return true;
      };
    //assign user select function
    const filterUserOptions = (
        candidate,
        input
      ) => {
        if (input) {
          return candidate.data.userName.toLowerCase().indexOf(input.toLowerCase())!=-1;
        }
        return true;
      };
    //assign modal show function
    const assignmodalShow = () => {
        assignToggle();
        partnerService.GetPartnerDetailByUserId(currentUser.id).then((response) => {
            var partnerDatas = [];
            response.partnerDetails.map(x=>{
                partnerDatas.push({value: x.Id, label: x.PartnerName})
            });
            setPartnerOptionsisDisabled(false);
            setPartnerOptionsData(partnerDatas)
        })
        assessmentService.GetAssessmentNameListByUserId(currentUser.id).then((response) => {
            let data = response.map(x => {
                x.checked = false;
                x.show = true;
                return x;
            });
            setModalDatas(data);
        })
    }

    //assign modal partner select change function
    const partnerChange = (e) => {
        setPartnerCheckedData(e.value);
        setUserOptions('')
        setCompanyOptions('')
        setCompanyOptionsDataisDisabled(true);
        setCompanyOptionsData([{value: "0",label: "Client"}]);
        setUserOptionsDataisDisabled(true);
        setUserOptionsData([]);
        companyService.GetCompanyListByPartnerId(e.value).then((response) => {
            if(response.companyDetails == [] || response.companyDetails == null){
            }else{
                var companyDatas = [{value: "0",label: "Client"}];
                if(currentUser.roleName !== 'company' ){
                    response.companyDetails.map(x=>{
                        companyDatas.push({value: x.Id, label: x.CompanyName})
                    });
                }else{
                    response.companyDetails.map(x=>{
                        if(x.Id == currentUser.company_id){
                            companyDatas.push({value: x.Id, label: x.CompanyName})
                        }
                    });
                }

                
                setCompanyOptionsDataisDisabled(false);
                setCompanyOptionsData(companyDatas)
            }
            
        })
        userService.GetUserListByPartnerCompany("",e.value).then((response) => {
            setCompanyOptionsDataisDisabled(false);
            var userDatas = [];
            response.map(x=>{
                userDatas.push({value: x.id, label: `${x.firstName} (${x.email})`,userName: `${x.firstName} ${x.lastName}`})
            });
            setUserOptionsDataisDisabled(false);
            setUserOptionsData(userDatas);
        })
    }
     //assign modal company select change function
     const companyChange = (e) => {
        if(e.value === '0'){
            setUserOptionsDataisDisabled(true);
            setUserOptionsData([]);
            setcompanyCheckedData(e.value);
            setCompanyOptions(e);
            setUserOptions('');
            userService.GetUserListByPartnerCompany("",partnerCheckedData).then((response) => {
                setCompanyOptionsDataisDisabled(false);
                var userDatas = [];
                response.map(x=>{
                    userDatas.push({value: x.id, label: `${x.firstName} (${x.email})`,userName: `${x.firstName} ${x.lastName}`})
                });
                setUserOptionsDataisDisabled(false);
                setUserOptionsData(userDatas);
            })
        }else{
            setUserOptionsDataisDisabled(true);
            setUserOptionsData([]);
            setcompanyCheckedData(e.value);
            setCompanyOptions(e);
            setUserOptions('');
            userService.GetUserListByPartnerCompany(e.value,"").then((response) => {
                var userDatas = [];
                response.map(x=>{
                    userDatas.push({value: x.id, label: `${x.firstName} (${x.email})`,userName: `${x.firstName} ${x.lastName}`})
                });
                setUserOptionsDataisDisabled(false);
                setUserOptionsData(userDatas);
            })
        }
    }
    //assign modal user select change function
    const userChange = (e) =>{
        setUserOptions(e);
        if(e == null){
            setUserCheckedData('');
        }else{
            setUserCheckedData(e.value);
        }
    }

    const SearchChange = (e) => {
        setSearchInputVal(e.target.value)
        if(e.target.value!=''){
            setShowSearchIcon(false);
            setShowClearIcon(true)
        }else{
            setShowSearchIcon(true);
            setShowClearIcon(false)
        }
        let datas = modalDatas;
        setModalDatas(datas.map((i)=>{
            if(i.assessment_name.toLowerCase().indexOf(e.target.value.toLowerCase()) != -1){
                i.show = true;
                return i
            }else{
                i.show = false;
                return i
            }
        }))

    }
    const ClearSearchContent = (e) => {
        setModalDatas(modalDatas.map((item) => {
                item.show = true;
                return item;
        }));
        setSearchInputVal('');
        setShowSearchIcon(true);
        setShowClearIcon(false);
    };

    const selectAssessment = (e) => {
        if(e.target.checked === true){
            setModalDatas(modalDatas.map((item) => {
                if(item.id == e.target.value){
                    item.checked = true;
                    return item;
                }else{
                    return item;
                }
            }));
        }else{ 
            setModalDatas(modalDatas.map((item) => {
                if(item.id == e.target.value){
                    item.checked = false;
                    return item;
                }else{
                    return item;
                }
            }));
        }
    }

    const AssignTheAssessmentToUser = (e) => {
        let chooseAssessment = []
        modalDatas.forEach((item) => {
            if(item.checked == true){
                chooseAssessment.push(item.id);
            }
        })

        if(userCheckedData == '' || chooseAssessment.length == 0){
            toast.error('Please select the mandatory items', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }else{
            assessmentService.AssignAssessment(currentUser.id,userCheckedData,chooseAssessment).then((response)=>{
                if(response.Status == "Success"){
                    toast.success('Assignment Allocated', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }else if(response.Status == "Failed"){
                    assignToggle();
                    toast.error('Failed to assign assessment, please try again later', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
        }
    }

    // removing of any assessment information stored on local storage
    useEffect(() => {
        sessionStorage.removeItem("hourAssessment");
        sessionStorage.removeItem('questionCounter');
        sessionStorage.removeItem('state');
        sessionStorage.removeItem('Title');
        sessionStorage.removeItem('AssessmentName');
        sessionStorage.removeItem('currentQuestionIndex');
        sessionStorage.removeItem('currentTitleIndex');
        sessionStorage.removeItem('checkedRadio');
        sessionStorage.removeItem('ReviewTimer');
        sessionStorage.removeItem('AssessmentQuiz');
        sessionStorage.removeItem('DateTimeServer');
        sessionStorage.removeItem('ReviewAssessmentKey');


        var currentUser = AuthService.getCurrentUser();
        var userId = currentUser.id;

        const assessmentList = () => {
            var assessmentList = assessmentService.GetAllAssessmentList(userId);

            var details = assessmentList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    setAllAssessments(data);
                    var UserFilterData = [{value: '0', label: 'Select User Name'}];
                    data.filter(
                        (v, i, a) =>
                            a.findIndex((t) => t.UserName === v.UserName) === i
                    ).map(x =>{
                        UserFilterData.push({value: x.UserName, label: x.UserName})
                    })
                    setUserFilterDatas(UserFilterData);
                    onFilterButtonClick(data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        const userData = () => {
            var userDataList = userService.GetUserList();

            var details = userDataList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    if (currentUser.roleName === "partner") {
                        setUsers(
                            data.listOfUsers.filter(
                                (x) =>
                                    x.partner_id === currentUser.partner_id &&
                                    x.roleName === "company"
                            )
                        );
                    } else if (currentUser.roleName === "company") {
                        setUsers(
                            data.listOfUsers.filter(
                                (x) =>
                                    x.company_id === currentUser.company_id &&
                                    x.roleName === "team-member"
                            )
                        );
                    } else {
                        setUsers([]);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        const assessmentDetail = () => {
            var assessmentDetailList = assessmentService.GetAllAssessmentDetails();

            var details = assessmentDetailList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    setAssessmentDetails(data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        assessmentList();
        // assessmentDetail();
        userData();
    }, []);

    // Sort function
    const onSort = (column) => {
        const direction =
            state.sort != undefined && state.sort.column
                ? state.sort.direction === "asc"
                    ? "desc"
                    : "asc"
                : "desc";
        const sortedData = state.data.sort((a, b) => {
            if (column === "Assessment") {
                if (direction === "desc") {
                    setArrowPositionAssessment(faSortAlphaUp);
                } else {
                    setArrowPositionAssessment(faSortAlphaDown);
                }
                const nameA = a.AssessmentName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.AssessmentName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === "Status") {
                if (direction === "desc") {
                    setArrowPositionStatus(faSortAlphaUp);
                } else {
                    setArrowPositionStatus(faSortAlphaDown);
                }
                const nameA = a.Status.toUpperCase(); // ignore upper and lowercase
                const nameB = b.Status.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === "Score") {
                if (direction === "desc") {
                    setArrowPositionScore(faSortNumericUp);
                } else {
                    setArrowPositionScore(faSortNumericDown);
                }
                const nameA = a.Score; // ignore upper and lowercase
                const nameB = b.Score; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === "TimeSpent") {
                if (direction === "desc") {
                    setArrowPositionTime(faSortNumericUp);
                } else {
                    setArrowPositionTime(faSortNumericDown);
                }
                const nameA = formatToSpentTime(a.TimeSpent); // ignore upper and lowercase
                const nameB = formatToSpentTime(b.TimeSpent); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if(column === "DateofAssignment"){
                if (direction === "desc") {
                    setArrowDate(faSortNumericUp);
                } else {
                    setArrowDate(faSortNumericDown);
                }
                var timeDiff = formatToDate(a.Date,b.Date)
                if (timeDiff > 0) {
                    return -1;
                }
                if (timeDiff < 0) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
        });

        if (direction === "desc") {
            setAllAssessments(sortedData.reverse());
        }
        setState({
            data: sortedData,
            sort: {
                column,
                direction,
            },
        });
    };
    function formatToDate(startDateStr, endDateStr) {
        // 将日期字符串拆分为天、月、年
        const startDateParts = startDateStr.split('/');
        const endDateParts = endDateStr.split('/');
    
        // 转换为数字
        const startDateDay = parseInt(startDateParts[0], 10);
        const startDateMonth = parseInt(startDateParts[1], 10) - 1; // 月份在Date对象中是0-11
        const startDateYear = parseInt(startDateParts[2], 10); // 假设是两位年份，可能需要额外处理
    
        const endDateDay = parseInt(endDateParts[0], 10);
        const endDateMonth = parseInt(endDateParts[1], 10) - 1;
        const endDateYear = parseInt(endDateParts[2], 10); // 同样假设是两位年份
    
        // 创建Date对象
        const startDate = new Date(startDateYear, startDateMonth, startDateDay);
        const endDate = new Date(endDateYear, endDateMonth, endDateDay);
    
        // 计算时间差（毫秒）并转换为天数
        const timeDiff = endDate - startDate;
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
        return timeDiff;
    }
    const formatToSpentTime = (time) => {
        let h = 0, m = 0, s= 0;
        time.split(" ").slice(0,3).map((x,i) => {
            if(i===0){
                h = x.slice(0,2) * 3600;
            }else if(i===1){
                m = x.slice(0,2) *60;
            }else{
                s = x.slice(0,2);
            }
        });
        return parseInt(h)  + parseInt(m) + parseInt(s);
    }
    // Assessment transfer function
    const handleUserChange = (e, guid) => {
        setUserId(e.target.value);
        //setCourseId(id);

        var assessmentNum = [];

        var result = [...allAssessments]; //<- copy course into result
        result = result.map((x) => {
            //<- use map on result to find element to update using id
            if (x.AssessGuid === guid) x.AssignedUserId = e.target.value;
            return x;
        });
        assessmentNum = result;

        setAllAssessments(assessmentNum); //<- update ass  with value edited
        setState({ data: assessmentNum });
    };

    // Checking if the assessment is already completed to release the button
    const GetButtonStatus = (Status) => {
        return ((Status.toUpperCase() == "INCOMPLETE") ?
            true :
            false
        )
    }

    const UnlockAssignment = (UserId,AssessmentId,CreditId) =>{
        assessmentService.UnblockAssessment(UserId,AssessmentId,CreditId).then((res) => {
            if(res.Status == 'Success'){
                toast.success("Assessment unlocked!", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                assessmentService.GetAllAssessmentList(AuthService.getCurrentUser().id)
                    .then((response) => {
                        return response;
                    })
                    .then((data) => {
                        setAllAssessments(data);
                        onFilterButtonClick(data);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }else{
                 toast.error("Failed to unlock the assessment, please try again later", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch((res)=>{
            console.log(res);
        })
    }

    // Download assessment report once the assessment is completed
    // TODO: Needs to be implemented & tested
    const handleDownloadReport = (assessmentId, userId, guid) => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = "Assessment Result Report"
        //axios
        //    .get(url, {
        //        responseType: "blob",
        //    })
        assessmentService.GetAssessmentResultReport(assessmentId, userId, guid)
            .then((res) => {
                link.href = URL.createObjectURL(
                    new Blob([res], { type: 'application/pdf' })
                );
                link.click();
            });
    };

    // Getting the class for the view results button
    const GetClassName = (Status) => {

        return (Status.toUpperCase() === "COMPLETED" ? "btn btn-pill me-1 mb-1 btn-normal btn-success col-12" : Status.toUpperCase() == "IN PROGRESS" ? "btn btn-pill me-1 mb-1 btn-normal btn-info col-12" : Status.toUpperCase() == "OPEN" ? "btn btn-pill me-1 mb-1 btn-normal btn-secondary col-12" : Status.toUpperCase() == "INCOMPLETE" ? "btn btn-pill me-1 mb-1 btn-normal btn-secondary col-12" : "btn btn-pill me-1 mb-1 btn-normal btn-info col-12")
    }

    // Getting the color class for the status
    const GetIconCircleColor = (Status) => {
        return (Status.toUpperCase() === "COMPLETED" ? "text-success" : Status.toUpperCase() == "IN PROGRESS" ? "text-info" : Status.toUpperCase() == "OPEN" ? "text-secondary" : Status.toUpperCase() === "INCOMPLETE" ? "text-secondary" : "text-success")
    }

    // Handler to assign assessments to another user (used by Partners & Company Manager roles)
    const handleAssignment = (id, userId, creditId) => {
        if (userId != 0) {
            var currentUser = AuthService.getCurrentUser();
            var details = assessmentService
                .AssignTheAssessmentToUser(currentUser.id, userId, id, creditId)
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    window.location.reload();
                });
        }
    };
    //Export function
    const ExportUser = () =>{
        exportToggle();
        setExportStatus(false);
        setExportOption(true);
        setDownloadUrl('');
    }
    const exportChoice = (type) =>{
        setExportOption(false);
        var data = [];
        state.data.map(item =>{
            data.push({"userId":`${item.UserId}`,"assessmentId":`${item.AssessmentId}`,"creditId":`${item.CreditId}`,"assessmentStatus":`${item.Status}`}) 
        })
        if(type == 'Simple'){
            assessmentService.CreateSimpleAssessmentFile(data).then((response) => {
                if(response.Status == "Success"){
                    setExportStatus(true)
                    setDownloadUrl(response.Message)
                }else{
                    setExportModel(false);
                    toast.error(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
        }else{
            assessmentService.CreateAssessmentFile(data).then((response) => {
                if(response.Status == "Success"){
                    setExportStatus(true)
                    setDownloadUrl(response.Message)
                }else{
                    setExportModel(false);
                    toast.error(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
        }
        
    }
    const downloadfile = () =>{
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    }

    // Handler for starting an assessment
    const handleStartAssesmentClk = (
        id,
        Is_Time_Based,
        QuizStatus,
        Score,
        Schema,
        creditId,
        ButtonText
    ) => {
        sessionStorage.removeItem("questionCounter");
        sessionStorage.removeItem("state");
        sessionStorage.removeItem("Title");
        sessionStorage.removeItem("AssessmentName");
        sessionStorage.removeItem("currentQuestionIndex");
        sessionStorage.removeItem("currentTitleIndex");
        sessionStorage.removeItem("checkedRadio");
        sessionStorage.removeItem("hourAssessment");
        var currentUser = AuthService.getCurrentUser();
        if (QuizStatus.toUpperCase() == "COMPLETED") {
            if(currentUser.roleName !== "team-member"&&currentUser.roleName !== "team-leader"){
                assessmentService.GetAllAssessmentDetailsByCreditID(currentUser.id, id, creditId).then((response) => {
                    if(response !=null){
                        props.history.push({
                            pathname: "/AssessmentResult",
                            state: { Score: Score, Data: Schema, questionsList: response[0]},
                        });
                    }
                })
            }else{
                props.history.push({pathname: "/ResultDashboard"});
            }
        } else {
            // if(!questions && Schema.AiAssessmentType != 2) {
            //     toast.error("The selected data is not correct, please select another assessment",{
            //         position: "top-center",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "colored",
            //         })
            //     return;
            // }
            if (Is_Time_Based) {
                assessmentService.GetAllAssessmentDetailsByCreditID(currentUser.id, id, creditId).then((response) => {
                    if(ButtonText.toUpperCase() == "START"){
                        props.history.push({
                            pathname: '/StartassessmentQuiz',
                            // pathname: '/Assessments/CaseStudyQuiz',
                            state: { assessmentId: id, questionsList: response[0], creditId: creditId,AssessmentsDetails:Schema}
    
                        })
                    }else{
                        var currentUser = AuthService.getCurrentUser();
    
                        var details = assessmentService.AddEntryInUserTestTable(currentUser.id, id, creditId).then((response) => {
                            return response;
                        })
                            .then((data) => {
                            
                            })
                            .catch(e => {
                                console.log(e);
                            })
                        props.history.push({
                            pathname: '/AssessmentQuiz',
                            state: { assessmentId: id, questionsList: response[0], creditId: creditId,AssessmentsDetails:Schema}
    
                        })
                    }
                })
                
                sessionStorage.removeItem('AssessmentQuiz');
                sessionStorage.setItem("AssessmentQuiz", "true");
                sessionStorage.removeItem('ReviewTimer');
                sessionStorage.removeItem('DateTimeServer');
                sessionStorage.removeItem('ReviewAssessmentKey');

            } else {
                assessmentService.GetAllAssessmentDetailsByCreditID(currentUser.id, id, creditId).then((response) => {
                    if(response !=null){
                        props.history.push({
                            pathname: '/NonTimeBasedQuiz',
                            state: { assessmentId: id, questionsList: response[0], creditId: creditId }
                        })
                    }
                })
            }
        }
    }
    {/*...................................................End Assessment list code part..............................................................*/ }


    {
        /*...............................filter view  section code........................................................*/
    }
    const [filterViewShow, setFilterViewShow] = useState(false);
    const [filterClientName, setFilterClientName] = useState(0);
    const [filterPartnerName, setFilterPartnerName] = useState(0);
    const [filterTeamName, setFilterTeamName] = useState(0);
    const [filterUserName, setFilterUserName] = useState(0);
    const [filterStatus, setFilterStatus] = useState(0);
    const [assessmentNameFilter, setAssessmentNameFilter] = useState(null);
    const [scoreFilter, setScoreFilter] = useState(0);
    const onFilterButtonClick = (data) => {
        setCurrentPage(1)
        let data1 = data ? data: allAssessments;
        if (
            assessmentNameFilter != null &&
            assessmentNameFilter !== undefined &&
            assessmentNameFilter !== ""
        ) {
            data1 = data1.filter(
                (x) =>
                    x.AssessmentName.toUpperCase().indexOf(
                        assessmentNameFilter.toUpperCase()
                    ) > -1
            );
        }
        if (filterPartnerName != 0) {
            data1 = data1.filter((x) => x.PartnerName == filterPartnerName);
        }
        if (filterClientName != 0) {
            data1 = data1.filter((x) => x.CompanyName == filterClientName);
        }
        if (filterTeamName != 0) {
            data1 = data1.filter((x) => x.TeamName == filterTeamName);
        }
        if (filterUserName.value != 0 && filterUserName.value != undefined) {
            data1 = data1.filter((x) => x.UserName == filterUserName.value);
        }
        if (filterStatus != 0) {
            data1 = data1.filter((x) => x.Status == filterStatus);
        }
        data1 = data1.filter((x) => !((x.AiAssessmentType == 2 ||x.AiAssessmentType == 3)&& x.Status == 'COMPLETED'));
        setCurrentPage(1);
        setState({ data: data1 });
    };
    const onChangefilterPartnerName = (e) => {
        setFilterPartnerName(e.target.value);
    };
    const onChangefilterStatus = (e) => {
        setFilterStatus(e.target.value);
    };

    const onChangefilterTeamName = (e) => {
        setFilterTeamName(e.target.value);
    };

    const onChangefilterClientName = (e) => {
        setFilterClientName(e.target.value);
    };
    const onChangefilterUserName = (e) => {
        setFilterUserName(e);
    };
    const onChangeAssessmentNameFilter = (e) => {
        setAssessmentNameFilter(e.target.value);
    };
    const onHandleScoreChange = (e) => {
        setScoreFilter(e.target.value);
    };
    const filterView = () => {
        setFilterViewShow((s) => !s);
    };
    const ClearAllFilterinfo =() =>{
        setFilterClientName(0);
        setFilterPartnerName(0);
        setFilterTeamName(0);
        setFilterUserName({value: '0', label: 'Select User Name'});
        setFilterStatus(0);
        setAssessmentNameFilter(null);
    }

    {
        /*............................... End filter view code  section........................................................*/
    }

    return (
        <>
            <Row className="mb-2 mb-xl-4">
                <Col xs="auto" className="ml-auto text-right mt-n1">
                    <Button
                        color="primary"
                        className="shadow-sm mr-1"
                        onClick={filterView}
                    >
                        <Filter className="feather" />
                    </Button>
                    {/* 暂时隐藏（设置按钮）
                    <Button color="primary" className="shadow-sm">
                        <Settings className="feather" />
                    </Button> */}
                </Col>
            </Row>

            <Row>
                <Col md={filterViewShow ? "9" : "12"}>
                    <div className="bloc-Mytabs mb-3">
                        <button
                            className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(1)}
                        >
                            All Assessments
                        </button>
                        {/* <button
                            className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(2)}
                        >
                            Team Assessments
                        </button>
                        <button
                            className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(3)}
                        >
                            Organization Assessments
                        </button>
                        <button
                            className={toggleState === 4 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(4)}
                        >
                            Partner Assessments
                        </button> */}
                    </div>
                    <Card>
                        <CardBody>
                             {/*model for export*/}
                            <Modal
                                show={exportModel}
                                onHide={exportToggle}
                                backdrop="static"
                                centered
                                size="sm"
                            >
                                <ModalHeader toggle={exportToggle} style={{border: 'none',paddingLeft:"1.7rem",marginTop:"0.7rem",paddingRight:"1.7rem"}}>
                                    <div style={{color:"#000000",fontSize:"1.3rem",fontWeight:"bold",marginBottom:"0"}}>Exporting Assessment Results</div>
                                </ModalHeader>
                                <hr className="mx-auto"
                                    style={{display: 'block',width: '86%',margin:'0'}}
                                />
                                <ModalBody style={{padding:"1rem 1.8rem 0 1.8rem",fontSize:"11px"}}>
                                    {!exportStatus && !exportOption &&<div style={{textAlign:"center"}}>
                                        <div className="spinner-border text-primary" style={{width: '1.5rem', height: '1.5rem'}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <p style={{color:"#000000",margin:'0.5rem 0',fontSize:' 1.1rem',fontWeight: 'bold',marginBottom: '0'}}>Exporting users, please wait...</p>
                                        <p style={{color:"rgb(255 0 0)",margin:'0',fontWeight: '600'}}>Please don't close the window untill the task is completed.</p>
                                    </div>}
                                    {exportOption &&<div style={{textAlign:"left",padding:'0 5px'}}>
                                       <p style={{color:'#333333',marginBottom:'1.5rem',fontSize:'12px',fontWeight:'100',transform:'scale(0.95)',transformOrigin:'left',whiteSpace:'nowrap'}}>Please choose the type of export you would like to receive.</p>
                                       <p style={{color:'#333333',fontSize:'12px',fontWeight:'100',transform:'scale(0.95)',transformOrigin:'left',whiteSpace:'nowrap'}}>
                                            <span style={{fontSize:'13px',fontWeight:'800'}}>Simple</span>: The following contain a summary of each assessment status.
                                       </p>
                                       <p style={{color:'#333333',fontSize:'12px',fontWeight:'100',transform:'scale(0.95)',transformOrigin:'left'}}>
                                            <span style={{fontSize:'13px',fontWeight:'800'}}>Detailed</span>
                                            : The following drills down to each of the questions & answers within an assessment.
                                       </p>
                                    </div>}
                                    {exportStatus &&<div style={{textAlign:"center"}}>
                                        <div className="iconCheck"></div>
                                        <p style={{color:"#000000",margin:'0.5rem 0',fontSize:' 1.1rem',fontWeight: 'bold',marginBottom: '0'}}>Exporting Complete!</p>
                                    </div>}
                                </ModalBody>
                                <ModalFooter style={{borderTop: 'none',padding: '0.75rem 1.5rem',marginBottom:'0.8rem'}}>
                                    {!exportStatus && !exportOption &&<button onClick={exportToggle} style={{width:"auto",flex:'none',backgroundColor:'#ffffff',borderColor:'#000000',color:"#000000",borderRadius:"1rem",fontWeight:"600",border:"1px solid",height:"2rem",padding: '0 0.7rem'}}>
                                        Cancel
                                    </button>}
                                    {exportOption &&
                                    <div style={{width:'45%'}}>
                                        <button onClick={() =>{exportChoice('Simple')}} style={{width:"9.5rem",flex:'none',color: '#ffffff',background: '#00995E',borderRadius:"1rem",fontWeight:"300",border:"1px solid",height:"2rem",padding: '0 0.7rem'}}>
                                            Simple
                                        </button> 
                                    </div>
                                    }
                                     {exportOption &&
                                    <div style={{width:'45%'}}>
                                        <button onClick={() =>{exportChoice('Detail')}} style={{width:"9.5rem",flex:'none',color: '#ffffff',background: '#00995E',borderRadius:"1rem",fontWeight:"300",border:"1px solid",height:"2rem",padding: '0 0.7rem'}}>
                                            Detailed
                                        </button>
                                    </div>
                                        
                                    }
                                    {exportStatus &&
                                    <button variant="primary" onClick={downloadfile} style={{width:"auto",flex:'none',borderRadius:"1rem",fontWeight:"600",height:"2rem",padding: '0 0.7rem',color: '#ffffff',background: '#00995E',margin:'0 auto'}}>
                                        Download File
                                    </button>}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                show={assignModel}
                                onHide={assignToggle}
                                backdrop="static"
                                centered
                                size="sm"
                            >
                                <ModalHeader toggle={assignToggle} style={{border: 'none',paddingLeft:"1.7rem",marginTop:"0.7rem",paddingRight:"1.7rem"}}>
                                    <div style={{color:"#000000",fontSize:"1.3rem",color:"#00995E",marginBottom:"0"}}>Assign New Assessment</div>
                                </ModalHeader>
                                <hr className="mx-auto"
                                    style={{display: 'block',width: '86%',margin:'0'}}
                                />
                                <ModalBody style={{padding:"1rem 1.8rem 0 1.8rem",fontSize:"11px"}}>
                                    <Container>
                                        <Row>
                                            <Col xs={6} md={6}>
                                                <Select
                                                    className="partner-select"
                                                    classNamePrefix="select"
                                                    defaultValue="Partner"
                                                    filterOption={filterOptions}
                                                    isDisabled={partnerOptionsisDisabled}
                                                    // isLoading={isLoading}
                                                    isClearable={false}
                                                    placeholder='Partner'
                                                    // isRtl={isRtl}
                                                    isSearchable={false}
                                                    name="color"
                                                    options={partnerOptionsData}
                                                    onChange={(e)=>{partnerChange(e);}}
                                                />
                                            </Col>
                                            <Col xs={6} md={6}>
                                                <Select
                                                    className="company-select"
                                                    classNamePrefix="select"
                                                    defaultValue="Client"
                                                    filterOption={filterOptions}
                                                    isDisabled={companyOptionsDataisDisabled}
                                                    // isLoading={isLoading}
                                                    isClearable={false}
                                                    placeholder='Client'
                                                    value={companyOptions}
                                                    // isRtl={isRtl}
                                                    isSearchable={false}
                                                    name="color"
                                                    options={companyOptionsData}
                                                    onChange={(e)=>{companyChange(e);}}
                                                />
                                            </Col>
                                            <Col xs={12} md={12} className="pt-3">
                                                <Select
                                                    className="user-select"
                                                    classNamePrefix="select"
                                                    defaultValue="User"
                                                    filterOption={filterUserOptions}
                                                    isDisabled={userOptionsDataisDisabled}
                                                    placeholder='User'
                                                    value={userOptions}
                                                    // isLoading={isLoading}
                                                    isClearable={true}
                                                    // isRtl={isRtl}
                                                    isSearchable={true}
                                                    name="color"
                                                    options={userOptionsData}
                                                    onChange={(e)=>{userChange(e);}}
                                                />
                                            </Col>
                                            <hr className="mx-auto mt-3"
                                                style={{display: 'block',width: '100%',margin:'0'}}
                                            />
                                            <Col xs={6} md={6} className="pt-3" style={{display:'flex',alignItems:'center',fontWeight:'bold',color:'#000000',fontSize: '1rem',whiteSpace: 'nowrap'}}>
                                                Assessment Availables
                                            </Col>
                                            <Col xs={6} md={6} className="pt-3">
                                                <input id="inf_field_FirstName" value={searchInputVal} onChange={(e)=>{SearchChange(e)}} className={styles.modalinput}  placeholder="Search Assessment " type="text"/>
                                                {showSearchIcon &&<SearchIcon size={15} style={{position: 'absolute',top: '1.6rem',right: '1.4rem'}}/>}
                                                {showClearIcon && <div className={styles.close} onClick={(e)=>{ClearSearchContent()}}></div>}
                                            </Col>
                                            <Col xs={12} md={12} className="pt-3" style={{color:'#A9A9A9'}}>
                                                Global Assessment
                                            </Col>
                                            <div className={styles.scrollbar}>
                                                {modalDatas.map((item, i)=>{
                                                    return (item.show ?
                                                        <Col key={i} xs={12} md={12} className="pt-1" style={{fontSize: '0.9rem',color:'#212529'}}>
                                                            <input type="checkbox" checked={item.checked} id={item.id} name="assessment" value={item.id} onChange={(e)=>{selectAssessment(e)}} />
                                                            <label style={{margin:'0'}} htmlFor={item.id}>{item.assessment_name}</label>
                                                        </Col>:''
                                                    )
                                                })}
                                            </div>
                                            <hr className="mx-auto mt-3"
                                                style={{display: 'block',width: '100%',margin:'0'}}
                                            />
                                        </Row>
                                    </Container>
                                </ModalBody>
                                <ModalFooter style={{borderTop: 'none',padding: '0.75rem 1.5rem',marginBottom:'0.8rem'}}>
                                    <button onClick={assignToggle} style={{width:"auto",flex:'none',backgroundColor:'#ffffff',borderColor:'#000000',color:"#000000",borderRadius:"5px",fontWeight:"600",border:"1px solid #D5D5D5",height:"2rem",padding: '0 0.7rem'}}>
                                        Cancel
                                    </button>
                                    <button variant="primary" onClick={AssignTheAssessmentToUser} style={{width:"auto",flex:'none',borderRadius:"5px",fontWeight:"600",height:"2rem",padding: '0 0.7rem',color: '#ffffff',background: '#00995E'}}>
                                        Assign Assessment
                                    </button>
                                </ModalFooter>
                            </Modal>
                            <Row>
                                <Col md="12">
                                    <div className="content-Mytabs">
                                        {/* User Assessments - Include all assessments assigned to the user */}
                                        <div
                                            className={
                                                toggleState === 1
                                                    ? "content-t  active-content-t"
                                                    : "content-t"
                                            }
                                        >
                                            <CardTitle className="d-flex align-items-center" style={{justifyContent: 'space-between'}}>
                                            <h2 style={{display: "inline-block",margin:'0',padding: '0'}}>All Assessments</h2>
                                            <div style={{display:'flex'}}>
                                            {(currentUser.roleName === "partner"||currentUser.roleName === "super-admin")&&
                                                <>
                                                {chooseAssessment.length > 0 &&
                                                    <div style={{marginRight:"0.4rem",paddingTop:'0.5rem'}}>
                                                        <div className="closeicon" onClick={ClearAll}></div>
                                                        {chooseAssessment.length} Selected
                                                    </div>
                                                }
                                                <div >
                                                    <button
                                                        className="shadow-sm mr-1 btn btn-primary disabledcolor"
                                                        onClick={() =>ReGenerateReport(1)}
                                                        disabled={chooseAssessment.length > 0 ? false : true}
                                                    >
                                                        Generate Report
                                                        <img src={iconGenerate1}  height="17" style={{margin:'0 0 0.2rem 0.5rem'}} alt=""/>
                                                    </button>
                                                </div>
                                                </>
                                            }
                                            {(currentUser.roleName === "company" || currentUser.roleName === "partner"||currentUser.roleName === "super-admin")&&
                                                <>
                                                    <div>
                                                        <button
                                                            className="shadow-sm mr-1 btn btn-primary disabledcolor"
                                                            onClick={assignmodalShow}
                                                        >
                                                            Assign Assessment
                                                            <img src={iconAssign} width="17" height="17" style={{margin:'0 0 0.2rem 0.5rem'}} alt=""/>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="shadow-sm mr-1 btn btn-primary disabledcolor"
                                                            onClick={ExportUser}
                                                            // disabled={chooseAssessment.length > 0 ? false : true}
                                                        >
                                                            Export
                                                            <img src={iconExport} width="16" height="16" style={{margin:'0 0 0.2rem 0.5rem'}} alt=""/>
                                                        </button>
                                                    </div>
                                                </>   
                                            }    
                                            
                                            </div>
                                            </CardTitle>
                                           
                                           
                                            
                                            {/*......................................All Assessment Table.......................................*/}
                                            <div className="scrollable">
                                                <Table striped hover className="table-sm">
                                                    <tbody>
                                                        <tr>
                                                            {(currentUser.roleName === "partner"||currentUser.roleName === "super-admin")&&<th></th>}
                                                            {/*<th>Role</th>*/}
                                                            <th>
                                                                <button
                                                                    type="button"
                                                                    className="SortButton"
                                                                    onClick={() => onSort("Assessment")}
                                                                >
                                                                    Assessment{""}
                                                                    <FontAwesomeIcon
                                                                        icon={arrowPositionAssessment}
                                                                    />
                                                                </button>
                                                            </th>
                                                            <th>
                                                                <button
                                                                    type="button"
                                                                    className="SortButton"
                                                                    onClick={() => onSort("DateofAssignment")}
                                                                >
                                                                    Date of Assignment{" "}
                                                                    <FontAwesomeIcon icon={arrowDate} />
                                                                </button>
                                                                </th>
                                                            <th>
                                                                <button
                                                                    type="button"
                                                                    className="SortButton"
                                                                    onClick={() => onSort("Status")}
                                                                >
                                                                    Status
                                                                    <FontAwesomeIcon icon={arrowPositionStatus} />
                                                                </button>
                                                            </th>
                                                            {/* <th>
                                                                <button
                                                                    type="button"
                                                                    className="SortButton"
                                                                    onClick={() => onSort("TimeSpent")}
                                                                >
                                                                    Time Spent{" "}
                                                                    <FontAwesomeIcon icon={arrowPositionTime} />
                                                                </button>
                                                            </th>
                                                            <th>
                                                                <button
                                                                    type="button"
                                                                    className="SortButton"
                                                                    onClick={() => onSort("Score")}
                                                                >
                                                                    Score
                                                                    <FontAwesomeIcon icon={arrowPositionScore} />
                                                                </button>
                                                            </th> */}
                                                            <th>User</th>
                                                            {/* <th>Team</th> */}
                                                            
                                                            <th>Company</th>
                                                            {/* <th>Partner</th> */}
                                                            {/* Assign to 功能暂时隐藏 */}
                                                            {/* {(currentUser.roleName === "partner" &&
                                                                users.length > 0) ||
                                                                (currentUser.roleName === "company" &&
                                                                    users.length > 0) ? (
                                                                <th>Assign to </th>
                                                            ) : (
                                                                ""
                                                            )} */}
                                                            {currentUser.roleName === "partner" ? (
                                                                <th></th>
                                                            ) : (
                                                                <th></th>
                                                            )}
                                                            <th style={{textAlign: "center"}}>ACTIONS</th>
                                                            {(currentUser.roleName === "partner" &&
                                                                users.length > 0) ||
                                                                (currentUser.roleName === "company" &&
                                                                    users.length > 0) ? (
                                                                <th></th>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        {state.data.slice(indexOfFirstPost, indexOfLastPost).map((schema) => {
                                                            return (
                                                                ((schema.AiAssessmentType == 2 || schema.AiAssessmentType == 3) && schema.Status == 'COMPLETED')?'':
                                                                <tr key={schema.AssessGuid}>
                                                                    {/*<td>{schema.Role}</td>*/}
                                                                    {(currentUser.roleName === "partner"||currentUser.roleName === "super-admin")&& <td style={{paddingTop: '13px'}}>
                                                                       {schema.Status == 'COMPLETED'&& schema.ReportLink!=''? <label className="checkbox-container">
                                                                            <input type="checkbox" readOnly  checked={schema.checked ? true : false} onClick={(e) =>{ChooseUser(e,schema.UserId,schema.AssessmentId,schema.CreditId)}}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>:
                                                                        <label className="checkbox-container">
                                                                            <input type="checkbox" readOnly  checked={schema.checked ? true : false} disabled />
                                                                            <span className="checkmark disableCard"></span>
                                                                        </label>}
                                                                    </td>}
                                                                    <td>{schema.AssessmentName}</td>
                                                                    <td>{schema.Date}</td>
                                                                    <td>
                                                                        <div className="small">
                                                                            <FontAwesomeIcon
                                                                                icon={faCircle}
                                                                                className={GetIconCircleColor(
                                                                                    schema.Status
                                                                                )}
                                                                            />{" "}
                                                                            {schema.Status}
                                                                        </div>
                                                                    </td>
                                                                    {/* <td>{schema.TimeSpent}</td>
                                                                    <td
                                                                        className="my-pointer"
                                                                        id={"UncontrolledPopover" + "bottom" + schema.AssessGuid}
                                                                    >
                                                                        {schema.Score}%
                                                                    </td> */}
                                                                    {/* <UncontrolledPopover
                                                                        trigger="legacy"
                                                                        placement={"bottom"}
                                                                        target={"UncontrolledPopover" + "bottom" + schema.AssessGuid}
                                                                    >
                                                                        <PopoverHeader>Breakdown</PopoverHeader>
                                                                        {schema.TopicWisePercentage.map(
                                                                            (direction, index) => (
                                                                                <PopoverBody key={index}>
                                                                                    <div className="small">
                                                                                        <FontAwesomeIcon
                                                                                            icon={faCircle}
                                                                                            className="text-success"
                                                                                        />{" "}
                                                                                        {direction.Percentage}% -{" "}
                                                                                        {direction.TopicName}
                                                                                    </div>
                                                                                </PopoverBody>
                                                                            )
                                                                        )}
                                                                    </UncontrolledPopover> */}
                                                                    <td>{schema.UserName}</td>
                                                                    {/* <td>{schema.TeamName}</td> */}
                                                                    <td>{schema.CompanyName}</td>
                                                                    {/* <td>{schema.PartnerName}</td> */}
                                                                    {/* {((currentUser.roleName === "partner" &&
                                                                        users.length > 0) ||
                                                                        (currentUser.roleName === "company" &&
                                                                            users.length > 0)) &&
                                                                        schema.UserId == currentUser.id &&
                                                                        currentUser.roleName !== "super-admin" ? (
                                                                        <td>
                                                                            
                                                                            <select
                                                                                className="custom-select"
                                                                                disabled={
                                                                                    schema.ButtonText == "Start"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                value={schema.AssignedUserId}
                                                                                onChange={(e) =>
                                                                                    handleUserChange(e, schema.AssessGuid)
                                                                                }
                                                                                id={`custom-checkbox-${schema.AssessGuid}`}
                                                                            >
                                                                                <option key="0">Select user</option>
                                                                                {users &&
                                                                                    users.length > 0 &&
                                                                                    users.map((a) => {
                                                                                        return (
                                                                                            <option key={a.id} value={a.id}>
                                                                                                {a.FirstName}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                            </select>
                                                                            
                                                                        </td>
                                                                    ) : (
                                                                        ""
                                                                    )} */}
                                                                    {/* {((currentUser.roleName === "partner" &&
                                                                        users.length > 0) ||
                                                                        (currentUser.roleName === "company" &&
                                                                            users.length > 0)) &&
                                                                        schema.UserId == currentUser.id &&
                                                                        currentUser.roleName !== "super-admin" ? (
                                                                        <td>
                                                                            <button
                                                                                className={
                                                                                    schema.ButtonText == "Start"
                                                                                        ? "btn btn-pill me-1 mb-1 btn-normal btn-info"
                                                                                        : "btn btn-pill me-1 mb-1 btn-normal btn-secondary "
                                                                                }
                                                                                disabled={
                                                                                    schema.ButtonText == "Start"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                onClick={() =>
                                                                                    handleAssignment(
                                                                                        schema.AssessmentId,
                                                                                        schema.AssignedUserId,
                                                                                        schema.CreditId
                                                                                    )
                                                                                }
                                                                            >
                                                                                Assign
                                                                            </button>
                                                                        </td>
                                                                    ) : (
                                                                        ""
                                                                    )} */}
                                                                    {(currentUser.roleName == "partner" && schema.AssessmentLock == "True" && schema.Status == "INCOMPLETE")?
                                                                        (
                                                                        <td>
                                                                            <button className={styles.unlockBtn}
                                                                                onClick={() =>
                                                                                    UnlockAssignment(
                                                                                        schema.UserId,
                                                                                        schema.AssessmentId,
                                                                                        schema.CreditId
                                                                                    )}
                                                                            >
                                                                                <Unlock size={13}></Unlock>
                                                                                Unlock
                                                                            </button>
                                                                        </td>
                                                                        ) : ((schema.Status == 'COMPLETED'&&(currentUser.roleName == "partner" ||currentUser.roleName === "super-admin"))?
                                                                        (
                                                                            schema.ReportLink!=''?
                                                                            <td style={{textAlign: 'center'}}>
                                                                                <img src={iconGenerate} width="27" height="27" style={{cursor: 'pointer'}}
                                                                                    onClick={() =>ReGenerateReport(2,schema.UserId,schema.AssessmentId,schema.CreditId)}
                                                                                />
                                                                            </td>:
                                                                            <td style={{textAlign: 'center'}}>
                                                                            <img src={iconGenerate} width="27" height="27" className="disableCard"/>
                                                                        </td>
                                                                        ):(<td></td>))
                                                                    }
                                                                    {((currentUser.roleName !== "super-admin" && schema.UserId == currentUser.id &&currentUser.roleName !== "team-member"&&currentUser.roleName !== "team-leader"&&currentUser.roleName !== "user") || 
                                                                    ((currentUser.roleName === "partner"||currentUser.roleName === "company")&&schema.ButtonText == "View Results" &&schema.UserId !== currentUser.id))||
                                                                     ((currentUser.roleName === "team-leader" ||currentUser.roleName === "user" || currentUser.roleName === "team-member")&&schema.UserId == currentUser.id&&(schema.ButtonText !== "View Results"||(currentUser.report_access==true &&schema.ShowResults=="True")))
                                                                     ||(currentUser.roleName === "user"&&currentUser.report_access==true &&schema.ButtonText == "View Results"&&schema.ShowResults=="True") ? (
                                                                        <td>
                                                                            <button
                                                                                className={GetClassName(schema.Status)}
                                                                                disabled={GetButtonStatus(
                                                                                    schema.Status
                                                                                )}
                                                                                onClick={() =>
                                                                                    handleStartAssesmentClk(
                                                                                        schema.AssessmentId,
                                                                                        schema.Is_Time_Based,
                                                                                        schema.Status,
                                                                                        schema.Score,
                                                                                        schema,
                                                                                        schema.CreditId,
                                                                                        schema.ButtonText
                                                                                    )
                                                                                }
                                                                            >
                                                                                {schema.ButtonText}
                                                                            </button>
                                                                        </td>
                                                                    ) : (
                                                                        <td></td>
                                                                    )}
                                                                     
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                                    <Pagination 
                                                        defaultPageSize={10}
                                                        showSizeChanger={false}
                                                        onChange={paginate}
                                                        defaultCurrent={1}
                                                        current={currentPage}
                                                        total={state.data.length == 0 ? 1 : state.data.length}
                                                        style={{margin:'1rem 0'}}
                                                    />
                                            </div>
                                        </div>

                                        {/* Team Assessments - Include only assessments assigned to a user of a team - (DISPLAY ONLY to Company Manager role) */}
                                        <div
                                            className={
                                                toggleState === 2
                                                    ? "content-t  active-content-t"
                                                    : "content-t"
                                            }
                                        >
                                            <h2>Team Assessments</h2>
                                            <hr />
                                        </div>

                                        {/* Organization Assessments - Include all the assessments of the organization (DISPLAY ONLY to Company Managers, Partner & Super Admin) */}
                                        <div
                                            className={
                                                toggleState === 3
                                                    ? "content-t  active-content-t"
                                                    : "content-t"
                                            }
                                        >
                                            <h2>Organization Assessments</h2>
                                            <hr />
                                        </div>

                                        {/* Partner Assessments - Include all the assessments of the partner (DISPLAY ONLY to Partner & Super Admin) */}
                                        <div
                                            className={
                                                toggleState === 4
                                                    ? "content-t  active-content-t"
                                                    : "content-t"
                                            }
                                        >
                                            <h2>Partner Assessments</h2>
                                            <hr />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                {/*...............................filter view ........................................................*/}
                {filterViewShow ? (
                    <Col md={3}>
                        <Card className="app-filters">
                            <CardHeader className="px-4 border-bottom">
                                <div style={{ float: "right" }}>
                                    <button
                                        className="btn text-dark rounded-pill"
                                        onClick={() => setFilterViewShow(false)}
                                    >
                                        <X size={35}></X>
                                    </button>
                                </div>
                                <p className="mb-0 h1  text-left text-primary font-weight-bold">
                                    <Filter className="" size={40}></Filter> Filter
                                </p>
                                <div style={{position: 'relative',height: '2rem'}}>
                                    <Button style={{background: '#ffffff',color: '#00995E',position: 'absolute',right: '0',top: '0.1rem',height: '2rem'}}onClick={ClearAllFilterinfo}>Clear all</Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Col xs={12} md={12}>
                                    <FormGroup>
                                        <Label>Assessment Name</Label>
                                        <Input
                                            bsSize="lg"
                                            type="text"
                                            name="assessment-name"
                                            placeholder="Enter assessment name"
                                            value={assessmentNameFilter}
                                            onChange={onChangeAssessmentNameFilter}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={12}>
                                    <FormGroup>
                                        <Label>Status</Label>
                                        <select
                                            className="custom-select"
                                            value={filterStatus}
                                            onChange={onChangefilterStatus}
                                        >
                                            <option value="0">Select Status</option>
                                            {allAssessments
                                                .filter(
                                                    (v, i, a) =>
                                                        a.findIndex((t) => t.Status === v.Status) === i
                                                )
                                                .map((schema) => {
                                                    return (
                                                        <option key={schema.Status} value={schema.Status}>
                                                            {schema.Status}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col xs={12} md={12}>
                                    <FormGroup>
                                        <Label>Select Score</Label>
                                        {/* 暂时隐藏（进度条筛选项）
                                        <input
                                            className="form-control"
                                            type="range"
                                            //id={id}
                                            min={5}
                                            max={50}
                                            step={0.5}
                                            value={scoreFilter}
                                            onChange={onHandleScoreChange}
                                        // value={state} // don't set value from state
                                        //defaultValue={state} // but instead pass state value as default value
                                        //onChange={e => console.log(e.target.value)} // don't set state on all change as react will re-render
                                        //onMouseUp={handleChange} // only set state when handle is released
                                        /> */}
                                    </FormGroup>
                                </Col>

                                <Col xs={12} md={12}>
                                    <FormGroup>
                                        <Label>Users</Label>
                                        <Select
                                            className="user-select"
                                            classNamePrefix="select"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                  ...baseStyles,
                                                  border: state.isSelected ? '2px solid #e50d30': state.isFocused? '2px solid #00995E':'2px solid #00995E',
                                                  borderColor:state.isFocused? '#00995E':'#00995E',
                                                  height:'calc(2rem)',
                                                  placeholderColor:'#495057'
                                                }),
                                              }}
                                            defaultValue="Select User Name"
                                            // filterOption={filterUserOptions}
                                            isDisabled={false}
                                            placeholder='Select User Name'
                                            value={filterUserName}
                                            // isLoading={isLoading}
                                            isClearable={false}
                                            // isRtl={isRtl}
                                            isSearchable={true}
                                            name="color"
                                            options={UserFilterDatas}
                                            onChange={(e) =>{onChangefilterUserName(e)}}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs={12} md={12}>
                                    <FormGroup>
                                        <Label>Team</Label>
                                        <select
                                            className="custom-select"
                                            value={filterTeamName}
                                            onChange={onChangefilterTeamName}
                                        >
                                            <option value="0">Select Team Name</option>
                                            {allAssessments
                                                .filter(
                                                    (v, i, a) =>
                                                        a.findIndex((t) => t.TeamName === v.TeamName) === i
                                                )
                                                .map((schema) => {
                                                    return (
                                                        <option
                                                            key={schema.TeamName}
                                                            value={schema.TeamName}
                                                        >
                                                            {schema.TeamName}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col xs={12} md={12}>
                                    <FormGroup>
                                        <Label>Client</Label>
                                        <select
                                            className="custom-select"
                                            value={filterClientName}
                                            onChange={onChangefilterClientName}
                                        >
                                            <option value="0">Select Client</option>
                                            {allAssessments
                                                .filter(
                                                    (v, i, a) =>
                                                        a.findIndex(
                                                            (t) => t.CompanyName === v.CompanyName
                                                        ) === i
                                                )
                                                .map((schema) => {
                                                    return (
                                                        <option
                                                            key={schema.CompanyName}
                                                            value={schema.CompanyName}
                                                        >
                                                            {schema.CompanyName}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col xs={12} md={12}>
                                    <FormGroup>
                                        <Label>Partner</Label>
                                        <select
                                            className="custom-select"
                                            value={filterPartnerName}
                                            onChange={onChangefilterPartnerName}
                                        >
                                            <option value="0">Select Partner</option>
                                            {allAssessments
                                                .filter(
                                                    (v, i, a) =>
                                                        a.findIndex(
                                                            (t) => t.PartnerName === v.PartnerName
                                                        ) === i
                                                )
                                                .map((schema) => {
                                                    return (
                                                        <option
                                                            key={schema.PartnerName}
                                                            value={schema.PartnerName}
                                                        >
                                                            {schema.PartnerName}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col xs={12} md={12}>
                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary d-block col-12"
                                            onClick={()=>{onFilterButtonClick()} }
                                        >
                                            <Search></Search> Search
                                        </button>
                                    </div>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                ) : (
                    <div></div>
                )}
            </Row>
        </>
    );
};

const SgaAssessments = (props) => <AssessmentsTypes {...props} />;

export default SgaAssessments;
