import React from "react";

import Navbar from "../components/Navbar";

const LandingPage = ({ children }) => (
    <React.Fragment>
        <Navbar />
        <div>
            {children}
        </div>
    </React.Fragment>
);

export default LandingPage;