/*2022-9-30-2022-10-7*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import companiesService from "../../services/companies.service.js";
import './css/LandingPage.css'
import step1 from "../../assets/img/landingPageImg/step1.jpg";
import step2 from "../../assets/img/landingPageImg/memphis-market-segmentation-and-targeted-advertising.png";
import step3 from "../../assets/img/landingPageImg/memphis-time-to-learn-with-blackboard-and-school-supplies.png";
import step4 from "../../assets/img/landingPageImg/step4.jpg";
import step5 from "../../assets/img/landingPageImg/step5.jpg";
import step6 from "../../assets/img/landingPageImg/test.png";
import footerImg from "../../assets/img/landingPageImg/footerImg.png";

const LandingPage = (props) => {
  let CompanyPageInfo = companiesService.getCompanyPageInfo();
  const companyPrimaryColor = CompanyPageInfo.colour_1 ?? '#000';

  const buttonColor = {
    backgroundColor: companyPrimaryColor,
    color: '#fff',
    width: '236px',
    height: '48px',
    'font-size': '19px',
    padding: '0',
    'line-height': '48px'
  }
  const BannerBackground = {
    background: `url(${CompanyPageInfo.banner_image}) no-repeat`,
    backgroundSize: 'cover',
    position: 'relative',
    minHeight: "595px"
  }
  const textColor = {
    color: companyPrimaryColor,
    'margin-bottom': '25px',
    'font-weight': 'bold',
 }
  const backgroundColor = {
    backgroundColor: companyPrimaryColor,
  }

  return (
    <React.Fragment>
      <div className="col-12 px-0">
        <div className="bannerBody d-flex align-items-center w-100" style={BannerBackground}>
          <div className="container" style={{marginLeft:"5%"}}>
            <div className="bannerContent mx-5">
              <p style={{color:"#ffffff"}}>
                <span style={{fontSize:"50px",display:'inline-block',lineHeight: '50px',marginBottom: '10px',fontWeight:"bold"}}>
                AUSTRALIAN HR CAPABILITY ANALYSIS
                </span>
                <span style={{fontSize: '29px',fontWeight: '400',lineHeight: '50px'}}>
                  {CompanyPageInfo.banner_text}
                </span>
              </p>
              <Link to={'/' + CompanyPageInfo.company_page_url + '/login'} style={buttonColor} className="btn">
              START ASSESSMENTS
              </Link>
            </div>
          </div>
        </div>
        <div className="container mt-6 mb-6">
          <h1 className="text-center display-5" style={textColor}>{CompanyPageInfo.section_1_title}</h1>
          <p className="w-50 text-center mx-auto">
            {CompanyPageInfo.section_1_body}
          </p>
        </div>
        {/* <div className="row align-items-center justify-content-center mx-0">
          <div className="col-auto">
            <img src={CompanyPageInfo.logo} className="mr-5" alt="" style={{ height: '100px' }} />
          </div>
          <div className="col-auto">
            <img src={CompanyPageInfo.client_additional_logo} alt="" className="mr-5" style={{ height: '100px' }} />
          </div>
        </div> */}

        <div className="stepBody flexRow mt-4 py-6" style={backgroundColor}>
          <div className="container" style={{maxWidth:'1950px'}}>
            <div className="row">
              <div className="col" style={{paddingLeft: '30px',paddingRight: '30px'}}>
                <div className="step bg-white" style={{padding:"2rem 1rem"}}>
                  <h1 style={{fontWeight:'400'}}>AUSTRALIAN HR CAPABILITY ANALYSIS</h1>
                  <p style={{fontSize:'14px',marginTop:"20px",padding:"0 1rem"}}>Own and progress your career by taking AHRI’s free Australian HR Capability Analysis for HR professionals.</p>
                  <p style={{padding:"0 3.5rem",fontSize:'14px',textAlign:'left',lineHeight:"18px",marginBottom:"30px"}}>
                    Discover the skills you need to progress your career as itrelates to the Australian HR Capability Framework.<br></br><p style={{marginBottom:"9px"}}></p>
                    Gain insights into your career journey and expertise.<br></br><p style={{marginBottom:"9px"}}></p>
                    Receive a personalised report on your resuits, includinglearning and development recommendations.<br></br><p style={{marginBottom:"9px"}}></p>
                    Benchmark your knowledge against the rest of the HRprofession.<br></br>
                  </p>
                  <Link to={'/'} style={buttonColor} className="btn">
                    CONTINUE
                  </Link>
                </div>
              </div>
              <div className="col" style={{paddingLeft: '30px',paddingRight: '30px'}}>
                <div className="step bg-white" style={{padding:"2rem 1rem"}}>
                  <h1 style={{fontWeight:'400'}}>YOUR PERSONALISED <br></br> REPORT</h1>
                  <p style={{fontSize:'14px',marginTop:"20px",padding:"0 1rem"}}>On completion of the Australian HR Capability Analysis you will receive:</p>
                  <p style={{padding:"0 3.5rem",fontSize:'14px',textAlign:'left',lineHeight:"18px"}}>
                    A personalised PDF report of your results together withrecommendations for learning and development.<br></br><p style={{marginBottom:"9px"}}></p>
                    Access to a set of dynamic graphics in which you canbenchmark your results to the rest of the HR profession.<br></br><p style={{marginBottom:"9px"}}></p>
                  </p>
                  <img src={step2} alt="" />
                </div>
              </div>
              <div className="col" style={{paddingLeft: '30px',paddingRight: '30px'}}>
                <div className="step bg-white" style={{padding:"2rem 1rem"}}>
                  <h1 style={{fontWeight:'400'}}>ABOUT THE AUSTRALIAN HR CAPABILITY FRAMEWORK</h1>
                  <p style={{fontSize:'14px',marginTop:"20px",padding:"0 1rem"}}>Your personalised report will provide insight into your expertise across the six capabilities featured in the Australian HR Capability Framework.</p>
                  <p style={{padding:"0 3.5rem",fontSize:'14px',textAlign:'left',lineHeight:"18px"}}>
                  The questions in the Australian HR Capability Analysis are underpinned by Australian HR Capability Framework, which is a graphic representation of the required capabilities, knowledge, and behaviours for HR professionals in Australia.
                    <br></br><p style={{marginBottom:"9px"}}></p>
                  </p>
                  <img src={step3} alt="" />
                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="body_end_text">
          <p className="h1 text-center mt-5 w-75 mx-auto" style={textColor}>
            {CompanyPageInfo.body_end_text}
          </p>
          <div className="CONTACTUSBTN">
            <a href="https://www.ahri.com.au/contact-us" target="_blank" style={{color: '#ffffff'}} rel="noopener noreferrer">CONTACT US</a><br />
          </div>       
        </div>
        <div className="Footer p-5">
          <div className="container" style={{maxWidth:"1500px"}}>
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <div className="footerHrefBody">
                  <a href="https://Ahriassessments.app" target="_blank" rel="noopener noreferrer">Home</a><br />
                  <a href="https://www.ahri.com.au/about-us" target="_blank" rel="noopener noreferrer">About AHRI</a><br />
                  <a href="hhttps://www.ahri.com.au/about-us/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms of Use</a><br />
                  <a href="https://www.ahri.com.au/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a><br/>
                  <a href="https://skillsgapanalysis.com" target="_blank" rel="noopener noreferrer">Powered by Skillsgapanalysis</a><br />
                </div>
              </div>
              <div className="col-auto">
                <img className="footerImg mx-auto" alt="" src={footerImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default LandingPage;
