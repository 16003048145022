import React, { useState, useRef, useEffect } from "react";

// Services
import AuthService from "../../../services/auth.service.js";
import assessmentService from "../../../services/assessment.service.js";


// Components
import { Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {  Modal } from "react-bootstrap";
import { Radio } from 'antd';
// Assets
import Watch from "../../../assets/img/icons/watch.png";
import { toast } from "react-toastify";
// import right from "../../../assets/img/icons/right.png";

/*
* Time Based assessment view
*
* This view is responsible for displaying assessment questions & recording the answers
*
* TODO: Combine non time based & time based to a single component
*
* */
const QuizView = (props) => {
    var currentUser = AuthService.getCurrentUser();
    let AssessmentsDetails = props.location.state.AssessmentsDetails;
    const [isOwnCaseStudy,setIsOwnCaseStudy] = useState(false);
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: "/Assessments",
        });
    }
    if (props.history.action == "POP") {
        var isKeyPresent = JSON.parse(sessionStorage.getItem("AssessmentQuizKey"));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: "/Assessments",
            });
        }
    }
    console.log();
    
    const onChangeisOwnCaseStudy = (e) => {
        setIsOwnCaseStudy(e.target.value);
    };

    const Start = () => {
        var currentUser = AuthService.getCurrentUser();
        console.log(AssessmentsDetails);
        
        assessmentService.UpdateCaseStudy(currentUser.id,AssessmentsDetails.AssessmentId, props.location.state.LinkedAssessmentId, props.location.state.LinkedAssessmentCreditId,isOwnCaseStudy).then((res) => {
            if(res.code == '200') {
                assessmentService.GetAllAssessmentListByCreditId(currentUser.id,res.LinkedAssessmentId,res.LinkedAssessmentCreditId).then((response) => {
                    if (response !== null) {
                        props.history.push({
                            pathname: '/StartassessmentQuiz',
                            state: { assessmentId: res.LinkedAssessmentId, questionsList: null, creditId: res.LinkedAssessmentCreditId,AssessmentsDetails:response[0]}
                        })
                    }
                })
            }else{
                toast.error(res.errMessage,{position: toast.POSITION.TOP_CENTER});
            }
        })

    }
    return (
        <>
            <Row>
                <div className="col-lg-12 col-md-12" style={{color:"#131523"}}>
                    <Card className="my-card assessment-wrapper-home" style={{minHeight:'75vh',padding:'20px 40px'}}>
                        <div className="pb-0" style={{display: 'flex', flexDirection:'column-reverse',alignItems:'center',fontSize:"35px",fontWeight:'bold',marginBottom:'0.3rem',marginTop:'8rem'}}>
                            <span style={{color:"var(--theme_color2)"}}>{AssessmentsDetails.AssessmentName}</span>
                            <span>
                                You have successfully completed
                            </span>
                        </div>
                        <hr className="my-hr" />
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <p style={{fontSize:'35px',fontWeight:'bold',margin:"0"}}>{AssessmentsDetails.AssessmentDescriptionDetailList.find(element => element.TypeId == 5)?.AhriDescription}</p>
                                    <p dangerouslySetInnerHTML={{__html:AssessmentsDetails.AssessmentDescriptionDetailList.find(element => element.TypeId == 6)?.AhriDescription}}></p>
                                </div>
                            </Row>
                            {(AssessmentsDetails.AiAssessmentType != 2 && AssessmentsDetails.AiAssessmentType != 3)&& <div style={{fontWeight: 'bold',textAlign: 'center'}}>
                            <p style={{margin:'1rem 0',fontSize: '20px'}}>Please select your preferred option.</p>
                            <Radio.Group onChange={onChangeisOwnCaseStudy} value={isOwnCaseStudy}  >
                                <Radio value={false} >
                                    <span style={{}}>Hypothetical Scenario</span>
                                </Radio>
                                <Radio value={true}>
                                    <span style={{}}>Workplace Project</span>
                                </Radio>
                            </Radio.Group>
                        </div>}
                        <div className="col-lg-12 col-md-12 d-flex" style={{justifyContent:"center",marginBottom: '2rem',marginTop:'2rem'}}>
                           { (AssessmentsDetails.AiAssessmentType == 2 || AssessmentsDetails.AiAssessmentType == 3)? 
                           <button
                                id="submit-button"
                                className="quiz-submit-btn btn btn-primary"
                                style={{margin:'0 1rem',background:"var(--theme_color1)",borderColor:"var(--theme_color1)"}}
                                onClick={()=>{
                                    props.history.push({
                                    pathname: '/dashboard/default',
                                })}}
                            >
                                Back to Assessment Dashboard
                            </button> 
                            :
                            // <button
                            //     id="submit-button"
                            //     className="quiz-submit-btn btn btn-primary"
                            //     style={{margin:'0 1rem',background:"var(--theme_color1)",borderColor:"var(--theme_color1)"}}
                            //     onClick={()=>{
                            //         props.history.push({
                            //         pathname: '/Assessments',
                            //     })}}
                            // >
                            //     Back to Assessment
                            // </button>
                            <></>
                            }
                           {props.location.state.GoOnInfo&& <button
                                id="submit-button"
                                className="quiz-submit-btn btn btn-primary"
                                style={{margin:'0 1rem'}}
                                onClick={Start}
                            >
                                Start Case Study
                            </button>}
                            {(AssessmentsDetails.AiAssessmentType == 2 ||AssessmentsDetails.AiAssessmentType == 3) && <button
                                id="submit-button"
                                className="quiz-submit-btn btn btn-primary"
                                style={{margin:'0 1rem',background:"var(--theme_color2)",borderColor:"var(--theme_color2)"}}
                                onClick={(e)=>{
                                    window.open('https://www.ahri.com.au/wp-content/uploads/Fellow-Application-Guide_v12.pdf', "_blank");
                                }}
                            >
                                Fellowship Application Form
                            </button>}
                            
                        </div>
                    </Card>
                </div>
            </Row>
        </>
    );
};

const AssessmentCompleted = (props) => (
    <>
        <QuizView {...props} />
    </>
);

export default AssessmentCompleted;
