import React, { useEffect,useState } from 'react';
import {Button, message} from 'antd';
import { Viewer , Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import styles from './PdfViewer.module.css'

const PdfViewer = ({data}) => {
  // 你可以在这里添加加载状态或错误处理逻辑
  const [pdfFile, setPdfFile] = useState(null);
  const [viewPdf, setViewPdf] = useState(data);

  // 加载 PDF 时更新页面数量
//   const handleChange = (e) => {
//     let selected = e.target.files[0];
//     if(selected && selected.type == 'application/pdf'){
//         let reader = new FileReader();
//         reader.readAsDataURL(selected);
//         reader.onload = (e) => {
//             setPdfFile(e.target.result);
//             console.log(e.target.result);
//         }
//     }else{
//         setPdfFile(null);
//         if(selected.type == 'application/pdf'){
//             message.warning('Please select a PDF file!')
//         }
//     }
//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if(pdfFile !== null){
//         setViewPdf(pdfFile)
//     }else{
//         setViewPdf(null);
//         message.warning('Please select a file!')
//     }
//   }
  const newplugin = defaultLayoutPlugin();
  return (
    <div>
        {/* <form onSubmit={handleSubmit}>
            <input type='file' onChange={handleChange}></input>
            <button type='submit'>VIEW PDF</button>
        </form> */}
        <div className={styles.PdfViewer_content}  >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.1.266/build/pdf.worker.min.js">
                {data && 
                    <>
                        <Viewer fileUrl={data} plugins={[newplugin]} />
                    </>
                }
                {!data && <>
                    No PDF
                </>}
                
            </Worker>
        </div>
    </div>
  );
};

export default PdfViewer;