import async from "../components/Async";

import {
    Layout as LayoutIcon,
    Sliders as SlidersIcon,
    HelpCircle as HelpCircleIcon,
    User as UserIcon,
    Users as UsersIcon,
    Settings as SettingsIcon,
    Grid as GridIcon,
    ShoppingCart as CartIcon,
    Flag as FlagIcon,
    Mail as MailIcon,
    PieChart,
    Calendar
} from "react-feather";

// Landing
import LoginPanel from "../pages/auth/LoginPanel";
import LandingPage from "../pages/auth/LandingPage"

// Auth
import ResetPassword from "../pages/auth/ResetPassword";
import ChangePassword from "../pages/auth/ChangePassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

import CompanyLoginPanel from "../pages/auth/CompanyLoginPanel";
import PartnerLoginPanel from "../pages/auth/PartnerLoginPanel";
import ThankYou from "../pages/auth/ThankYou";
import CompanyThankYou from "../pages/auth/CompanyThankYou";
import PartnerView from "../pages/sgacontainer/partnerdata/PartnerView";




// Pages sga container

import SgaUsers from "../pages/sgacontainer/usersdata/SgaUsers";
import SgaPartners from "../pages/sgacontainer/partnerdata/SgaPartners";
import SgaTeams from "../pages/sgacontainer/teamdata/SgaTeams";
import SgaCompanies from "../pages/sgacontainer/companydata/SgaCompanies";

import TeamLeaderView from "../pages/sgacontainer/teamdata/TeamLeaderView";


import UserProfile from "../pages/sgacontainer/usersdata/UserProfile";

import Settings from "../pages/sgacontainer/settings/Settings";

import SgaShop from "../pages/sgacontainer/shopdata/SgaShop";

import SgaAssessments from "../pages/sgacontainer/Assessmentdata/SgaAssessments";
import SgaAssessmentsResult from "../pages/sgacontainer/Assessmentdata/SgaAssessmentsResult";

import AssessmentQuiz from "../pages/sgacontainer/Assessmentdata/AssessmentQuiz";
import StartassessmentQuiz from "../pages/sgacontainer/Assessmentdata/StartAssessmentQuiz";
import ReviewAssessment from "../pages/sgacontainer/Assessmentdata/ReviewAssessment";

import NonTimeBasedQuiz from "../pages/sgacontainer/Assessmentdata/NonTimeBasedQuiz";

import ReSubmitQuestionOption from "../pages/sgacontainer/Assessmentdata/ReSubmitQuestionOption";

import ReviewAssessmentNonTimer from "../pages/sgacontainer/Assessmentdata/ReviewAssessmentNonTimer";

import ReSubmitQuestionOptionNonTimer from "../pages/sgacontainer/Assessmentdata/ReSubmitQuestionOptionNonTimer";

import LearningPlans from "../pages/sgacontainer/LearningPlans/LearningPlans"

import Interest from "../pages/auth/Interest";
import HelpCenter from "../pages/sgacontainer/HelpCenter/Helpcenter";
import ResultDashboard from "../pages/sgacontainer/Assessmentdata/ResultDashboard";
import AssessmentCompleted from "../pages/sgacontainer/Assessmentdata/AssessmentCompleted";
import CaseStudyQuiz from "../pages/sgacontainer/Assessmentdata/CaseStudyQuiz";

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));



// Routes
const loginPanelRoutes = {
    path: "/",
    name: "Landing Page",
    component: LoginPanel,
    children: null
};
const loginBycompanynameRoutes = {
    path: "/:companyname/:login/:results",
    name: "Landing Page",
    component: LoginPanel,
    children: null
};
const loginBycompanynameRoutes1 = {
    path: "/:landingpage",
    name: "Landing Page",
    component: LoginPanel,
    children: null
};
const loginBycompanynameRoutes2 = {
    path: "/:companyname/:login",
    name: "Landing Page",
    component: LoginPanel,
    children: null
};

const companyLoginPanelRoutes = {
    path: "/companyLogin",
    name: "company login panel",
    component: LoginPanel,
    children: null
};
const partnerLoginPanelRoutes = {
    path: "/partnerLogin",
    name: "partner login panel",
    component: LoginPanel,
    children: null
};
const ResetPasswordRoutes = {
    path: "/resetPassword",
    name: "Reset Password",
    component: ResetPassword,
    children: null
};

const ChangePasswordRoutes = {
    path: "/ChangePassword",
    name: "Change Password",
    component: ChangePassword,
    children: null
};

//thank you page
const thankYouRoutes = {
    path: "/thankyou",
    name: "Thank you",
    component: ThankYou,
    children: null
};
const resultDashboardRoutes = {
    path: "/ResultDashboard",
    name: "Results",
    component: ResultDashboard,
    children: null,
    icon: PieChart,
};
const LearningPlansRoutes = {
    path: "/LearningPlans",
    name: "Learning Plan",
    component: LearningPlans,
    children: null,
    icon: Calendar,
};

//thank you page company
const companyThankYouRoutes = {
    path: "/companyThankYou",
    name: "Thank you from company",
    component: CompanyThankYou,
    children: null
};
//users page sga container
const sgaUsersRoutes = {
    path: "/users",
    name: "Users",
    icon: UserIcon,
    component: SgaUsers,
    children: null
};
//user profile page sga container
const sgaUserProfileRoutes = {
    path: "/userProfile",
    name: "User Profile",
    icon: LayoutIcon,
    component: UserProfile,
    children: null
};
//partners page sga container
const sgaPartnersRoutes = {
    path: "/partners",
    name: "Partners",
    icon: UsersIcon,
    component: SgaPartners,
    children: null
};
//Teams page sga container
const sgaTeamsRoutes = {
    path: "/teams",
    name: "Teams",
    icon: GridIcon,
    component: SgaTeams,
    children: null
};
//Companies page sga container
const sgaCompaniesRoutes = {
    path: "/companies",
    name: "Clients",
    icon: MailIcon,
    component: SgaCompanies,
    children: null
};

//Team Leader View
const teamLeaderViewRoutes = {
    path: "/teamLeaderView",
    name: "Team Leader View",
    icon: LayoutIcon,
    component: TeamLeaderView,
    children: null
}
const partnerViewRoutes = {
    path: "/partnerView",
    name: "Partner View",
    icon: LayoutIcon,
    component: PartnerView,
    children: null
}

//Assessment View
const sgaAssessmentListRoutes = {
    path: "/Assessments",
    name: "Assessments",
    badgeColor: "#ffffff",
    // badgeText: "16",
    icon: FlagIcon,
    component: SgaAssessments,
    children: null
}

const sgaAssessmentResultRoutes = {
    path: "/AssessmentResult",
    name: "AssessmentResult",
    icon: FlagIcon,
    component: SgaAssessmentsResult,
    children: null
}

//Shop View
const sgaShopViewRoutes = {
    path: "/sgaShop",
    name: "Shop",
    icon: CartIcon,
    component: SgaShop,
    children: null
}


//Assessment View
const sgaAssessmentQuizRoutes = {
    path: "/AssessmentQuiz",
    name: "Assessments Quiz",
    component: AssessmentQuiz,
    children: null
}
const StartassessmentQuizRoutes = {
    path: "/StartassessmentQuiz",
    name: "Start Assessment Quiz",
    component: StartassessmentQuiz,
    children: null
}
const sgaReSubmitQuestionQuizRoutes = {
    path: "/ReSubmitQuestionOption",
    name: "ResubmitQuestion Quiz",
    component: ReSubmitQuestionOption,
    children: null
}
const sgaReviewAssessmentRoutes = {
    path: "/ReviewAssessment",
    name: "Assessments Quiz",
    component: ReviewAssessment,
    children: null
}
const sgaNonTimerBasedQuizRoutes = {
    path: "/NonTimeBasedQuiz",
    name: "Non-Time Based Quiz",
    component: NonTimeBasedQuiz,
    children: null
}
const sgaReviewAssessmentNonTimerRoutes = {
    path: "/ReviewAssessmentNonTimer",
    name: "ReviewAssessmentNonTimer",
    component: ReviewAssessmentNonTimer,
    children: null
}

const sgaReSubmitQuestionOptionNonTimerRoutes = {
    path: "/ReSubmitQuestionOptionNonTimer",
    name: "ReSubmitQuestionOptionNonTimer",
    component: ReSubmitQuestionOptionNonTimer,
    children: null
}

//settings 

const settingsRoutes = {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: Settings,
    children: null
}


const dashboardRoutes = {
    path: "/dashboard/default",
    name: "Dashboard",
    header: "Pages",
    icon: SlidersIcon,
    containsHome: true,
    component: Default,
    children: null
};

const assessmentCompleted = {
    path: "/Assessments/Completed",
    name: "Completed",
    icon: SlidersIcon,
    containsHome: true,
    component: AssessmentCompleted,
    children: null
};

const caseStudyQuiz= {
    path: "/Assessments/CaseStudyQuiz",
    name: "Completed",
    icon: SlidersIcon,
    containsHome: true,
    component: CaseStudyQuiz ,
    children: null
};

const helpCenterRoutes = {
    path: "/helpCenter",
    name: "Help Center",
    icon: HelpCircleIcon,
    component: HelpCenter,
    children: null
};

const authRoutes = {
    path: "/auth",
    name: "Auth",
    icon: UsersIcon,
    badgeColor: "secondary",
    badgeText: "Special",
    children: [
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500
        },
        {
            path: "/auth/interest",
            name: "interest",
            component: Interest,
        }
    ]
};

const landingPageRoutes = {
    path: "/landingpage",
    name: "Company Page",
    component: LandingPage,
    children: null
}

// Dashboard specific routes
export const dashboard = [
    dashboardRoutes,
    assessmentCompleted,
    caseStudyQuiz,
    helpCenterRoutes,
    resultDashboardRoutes,
    LearningPlansRoutes,
    sgaAssessmentListRoutes,
    sgaShopViewRoutes,
    sgaUsersRoutes,
    sgaPartnersRoutes,
    sgaTeamsRoutes,
    sgaCompaniesRoutes,
    teamLeaderViewRoutes,
    partnerViewRoutes,
    settingsRoutes,
    sgaUserProfileRoutes,
    sgaAssessmentQuizRoutes,
    StartassessmentQuizRoutes,
    sgaNonTimerBasedQuizRoutes,
    sgaReviewAssessmentRoutes,
    sgaReSubmitQuestionQuizRoutes,
    sgaReSubmitQuestionOptionNonTimerRoutes,
    sgaReviewAssessmentNonTimerRoutes,
    sgaAssessmentResultRoutes
];

// Landing specific routes
export const loginPanel = [loginPanelRoutes];

export const landingPage = [landingPageRoutes];

export const loginBycompanyname = [loginBycompanynameRoutes];
export const loginBycompanyname1 = [loginBycompanynameRoutes1];
export const loginBycompanyname2 = [loginBycompanynameRoutes2];

// company login routes
export const companyLoginPanel = [companyLoginPanelRoutes];
// partner login routes
export const partnerLoginPanel = [partnerLoginPanelRoutes];

// thank you routes
export const thankYou = [thankYouRoutes];

// ResultDashboard routes
// export const resultDashboard = [ResultDashboardRoutes];

// resetPassword routes
export const resetPassword = [ResetPasswordRoutes];
// resetPassword routes
export const changePassword = [ChangePasswordRoutes];

// thank you routes
export const companyThankYou = [companyThankYouRoutes];

// Auth specific routes 
export const page = [authRoutes];

// All routes
export default [
    dashboardRoutes,
    sgaAssessmentListRoutes,
    sgaShopViewRoutes,
    sgaUsersRoutes,
    sgaTeamsRoutes,
    sgaCompaniesRoutes,
    sgaPartnersRoutes,
    settingsRoutes,
    resultDashboardRoutes,
    LearningPlansRoutes,
    helpCenterRoutes,
];
