import React, { useState, useEffect, useRef} from "react";

// Services
import AuthService from "../../../services/auth.service.js";
import userService from "../../../services/user.service";
import CompanyService from "../../../services/companies.service";
import assessmentService from "../../../services/assessment.service";
import userServices from "../../../services/user.service";

// Components
import Autocomplete from "../../../components/Autocomplete";
import { Hint } from 'react-autocomplete-hint';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Row,
    FormGroup,
    Label,
    Input, Alert,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import DeleteConfirmation from "../../../../src/components/DeleteConfirmation";
import {Modal} from "react-bootstrap";
import { Popover,Tooltip } from 'antd';
import dayjs from 'dayjs';
import validator from 'validator'
import { Plus, ArrowRight, Check, X, Edit2} from "react-feather";
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';
// Assets
import "react-datepicker/dist/react-datepicker.css";
import avatar4 from "../../../assets/img/avatars/avatar.png";
import styles from "../style/Userdata/Profilestyle.module.css"
import iconDeleteUser from "../../../assets/img/icons/delete.svg";

toast.configure()
/*
* Assessment Details
*
* This loads a summary of assessments
* progress for a given user
*
* */
const AssessmentDetail = (props) => {
    var currentUser = AuthService.getCurrentUser();
    let userId = props.history.location.state !== undefined ? props.history.location.state.data : currentUser.id;
    const [assessmentList, setAssessmentList] = useState([])
    const [AssessmentDetail, setAssessmentDetail] = useState([])

    useEffect(() => {
        assessmentService.GetAllAssessmentList(userId).then((data) => {
            return data.filter((assessment) => {
                return assessment.UserId === userId
            })
        }).then((data) => {
            setAssessmentList(data);
        })
    }, [userId])
    useEffect(() => {
        const assessmentDetail = () => {
            var assessmentDetailList = assessmentService.GetAllAssessmentDetails();

            var details = assessmentDetailList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    setAssessmentDetail(data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        assessmentDetail();
    }, [])
    const viewAssessment = (assessment) => {
        if(currentUser.IsCompleteProfile !== false){
            assessmentService.GetAllAssessmentDetailsByCreditID(currentUser.id, assessment.AssessmentId, assessment.CreditId).then((response) => {
                if (response !== null) {
                    props.history.push({
                        pathname: '/StartassessmentQuiz',
                        state: { assessmentId: assessment.AssessmentId, questionsList: response[0], creditId: assessment.CreditId,AssessmentsDetails:assessment }
                    })
                }
            })
        }
    }
    const handleviewAssesmentResults = (Score, Schema) => {
        if(currentUser.IsCompleteProfile !== false){
            if(currentUser.roleName == "team-member" ||currentUser.roleName === "team-leader"){
                if(currentUser.report_access == true){
                    props.history.push({pathname: "/ResultDashboard"});
                }else{
                    props.history.push({pathname: "/Assessments"});
                }
            }else{
                props.history.push({
                    pathname: "/AssessmentResult",
                    state: { Score: Score, Data: Schema, questionsList: JSON.parse(sessionStorage.getItem('assessment')).filter(x => x.AssessmentId == Schema.AssessmentId)[0] },
                });
            }
        }
    }
    return (
        <Card>
            <CardBody className="pb-0">
                <div className="bg-light p-3 rounded mb-3">
                    <Row className="align-items-center ">
                        <Col xs="8">
                            <h4 className="theme_color1 mb-0">
                                Open Assessments
                            </h4>
                        </Col>
                        <Col xs="4">
                            <p className="display-4 mb-0 text-right theme_color1 font-weight-bold">
                                {
                                    assessmentList.filter((assessment) => {
                                        return assessment.Status === 'OPEN'
                                    }).length
                                }
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="assessmentWrapper" style={{ height: '170px', 'overflow': 'hidden auto' }}>
                    {assessmentList.length > 0 && assessmentList.map((assessment, i) => {
                        return (
                            assessment.Status === 'OPEN' &&
                            <div className="assessmentDetailsCard px-2" key={i}>
                                <Row className="align-items-center">
                                    <Col>
                                        <p className="small">{assessment.AssessmentName}</p>
                                    </Col>
                                    <Col>
                                        <p className="text-right small">
                                          {(userId? currentUser.id == userId:true)  &&<button className="btn-sm btn-link theme_color1"
                                                onClick={() => {
                                                    viewAssessment(assessment);
                                                }}>
                                                View <ArrowRight />
                                            </button>}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                </div>

            </CardBody>
            <CardBody className="pb-0">
                <div className="bg-light p-3 rounded mb-3">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h4 className="text-success mb-0">
                                Complete Assessments
                            </h4>
                        </Col>
                        <Col xs="4">
                            <p className="display-4 mb-0 text-right text-success font-weight-bold">
                                {
                                    assessmentList.filter((assessment) => {
                                        return assessment.Status === 'COMPLETED'
                                    }).length
                                }
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="assessmentWrapper" style={{ height: '170px', 'overflow': 'hidden auto' }}>
                    {assessmentList.length > 0 && assessmentList.map((assessment, i) => {
                        return (
                            assessment.Status === 'COMPLETED' &&
                            <div className="assessmentDetailsCard px-2" key={i}>
                                <Row className="align-items-center">
                                    <Col>
                                        <p className="small">{assessment.AssessmentName}</p>
                                    </Col>
                                    <Col>
                                        <p className="text-right small">
                                            {(userId? currentUser.id == userId:true)&&<button className="btn-sm btn-link" onClick={() => {
                                                handleviewAssesmentResults(assessment.score, assessment)
                                            }}>
                                                Results <ArrowRight />
                                            </button>}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                </div>
            </CardBody>
            <CardBody>
                <div className="bg-light p-3 rounded mb-3">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h4 className="text-dark mb-0">
                                In-Complete Assessments
                            </h4>
                        </Col>
                        <Col xs="4">
                            <p className="display-4 mb-0 text-right text-dark font-weight-bold">
                                {
                                    assessmentList.filter((assessment) => {
                                        return assessment.Status === 'IN PROGRESS'
                                    }).length
                                }
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="assessmentWrapper" style={{ height: '170px', 'overflow': 'hidden auto' }}>
                    {assessmentList.length > 0 && assessmentList.map((assessment, i) => {
                        return (
                            assessment.Status === 'IN PROGRESS' && assessment.Status === 'INCOMPLETE'&&
                            <div className="assessmentDetailsCard px-2" key={i}>
                                <Row className="align-items-center">
                                    <Col>
                                        <p className="small">{assessment.AssessmentName}</p>
                                    </Col>
                                    <Col>
                                        <p className="text-right small">
                                            {(userId? currentUser.id == userId:true)&&<button className="btn-sm btn-link disabled">
                                                View <ArrowRight />
                                            </button>}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                </div>
            </CardBody>
        </Card>
    );
}

/*
* Credit Details
*
* This displays the credit balance
* for a particular Company/Partner
*
* TODO: Should only display for Partners, Company Managers, Team Leaders
* TODO: Partners should see the credits allocated to the Partner Entity
* TODO: Company Managers, Team Leaders, Team Members should see the allocated credits for the Company Entity
* */
const CreditDetail = () => {
    var currentUser = AuthService.getCurrentUser();
    const [credits, setCredits] = useState("");


    useEffect(() => {

        var currentUserId = currentUser.id;

        const creditDetail = () => {
            var creditBalance = userService.GetCreditDetail(currentUserId);

            var balance = creditBalance.then((response) => {
                return response;
            })
                .then((data) => {

                    setCredits(data);
                })
                .catch(e => {
                    console.log(e);
                })
        };

        creditDetail();
    }, []);

    if (currentUser.roleName === "partner" || currentUser.roleName === "company") {
        return (

            <Card>
                <CardHeader>
                    <h1 className="mb-0 h3">
                        Credit Balance
                    </h1>
                </CardHeader>

                <CardBody className="text-center">
                    <div>
                        <h4>Balance : {credits} </h4>
                    </div>
                </CardBody>
                <hr className="my-0" />
            </Card>
        )
    } else {
        return (<div></div>)
    }


};


/*
* Profile Summary
*
* This displays the profile Username & Image for
* an individual user
* */
const Profile = (props) => {
    var currentUser = AuthService.getCurrentUser();
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    var currentCompany = AuthService.getCurrentCompany();
    const [userName, setUserName] = useState(true);
    if (props.history.location.state != undefined) {
        currentUser.FirstName = props.history.location.state.firstName;
        currentUser.LastName = props.history.location.state.lastName;

    }
    useEffect(() => {
        if (currentUser !== null) {
            setUserName(currentUser.FirstName + " " + currentUser.LastName)

        }
    });
    return (
        <Card>
            <CardBody>

                <Row className="align-items-center">
                    <Col md="2">
                        <div className="position-relative text-center">
                            <img
                                alt="Chris Wood"
                                src={avatar4}
                                className="rounded-circle img-responsive mt-2"
                                width="80"
                                height="80"
                            />
                            <div>
                                {/* <Button color="primary" className="position-absolute disabled" style={{
                                    bottom: 0,
                                    right: 0,
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    opacity: 1
                                }}>
                                    <Plus size={18} className="align-middle" />
                                </Button> */}
                            </div>
                        </div>
                    </Col>

                    <Col md="10">
                        <h2>{userName}</h2>
                        <hr className="d-none" />
                    </Col>

                </Row>
            </CardBody>
        </Card>
    );
};

/*
* Profile Details
*
* This displays the profile information for
* an individual user
* */
const Details = (props) => {
    var currentUser = AuthService.getCurrentUser();

    let userId = props.history.location.state !== undefined ? props.history.location.state.data : currentUser.id;

    if (props.history.location.state != undefined) {
        currentUser.id = userId

    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }


    // const [startDate, setStartDate] = useState(new Date());
    const [disabledFields, setDisabledFields] = useState(true);
    const [userProfileObj, setUserProfileObj] = useState('');
    const [firstNameProfile, setFirstNameProfile] = useState('');
    const [lastNameProfile, setLastNameProfile] = useState('');
    const [emailProfile, setEmailProfile] = useState('');
    const [registrationDateProfile, setRegistrationDateProfile] = useState('');
    const [experienceUserProfile, setExperienceUserProfile] = useState("0");
    const [industryUserProfile, setIndustryUserProfile] = useState("0");
    const [passwordUserProfile, setPasswordUserProfile] = useState('');
    const [educationUserProfile, setEducationUserProfile] = useState("0");
    const [qualificationProfile, setQualificationProfile] = useState("0");
    const [spendUnderProfile, setSpendUnderProfile] = useState("0");
    const [partnerProfile, setPartnerProfile] = useState("");
    const [companyProfile, setCompanyProfile] = useState("");
    const [reportProfile, setReportProfile] = useState("0");
    const [reportaccess, setReportaccess] = useState(true);


    const [phoneUserProfile, setPhoneUserProfile] = useState('');
    const [proLevelUserProfile, setProLevelUserProfile] = useState("0");
    const [stateUserProfile, setStateUserProfile] = useState('');
    const [positionUserProfile, setPositionUserProfile] = useState("0");
    const [geoInfluenceUserProfile, setGeoInfluenceUserProfile] = useState("0");
    const [postCodeUserProfile, setPostCodeUserProfile] = useState('');
    const [roleUserProfile, setRoleUserProfile] = useState("0");
    const [sectorUserProfile, setSectorUserProfile] = useState("0");
    const [countryUserProfile, setCountryUserProfile] = useState('');
    const [jobLevelUserProfile, setJobLevelUserProfile] = useState('');
    const [countries, setCountry] = useState([]);
    const [jobRoles, setJobRoles] = useState("");
    const [jobRoleUserProfile, setJobRoleUserProfile] = useState("0");
    const [editButtonText, seteditButtonText] = useState(<Edit2 size='30' />);
    const [hintData, setHintData] = useState([])
    const [suggestions, setSuggestions] = useState([])

    const [experiences, setExperience] = useState("");

    const [industries, setIndustries] = useState("");

    const [educations, setEducation] = useState("");

    const [specialists, setSpecialists] = useState("");


    const [sectors, setSectors] = useState("");

    const [geoInfluences, setGeoInfluences] = useState("");

    const [ProgramName, setProgramName] = useState("");

    const [positions, setPositions] = useState("");

    const [proLevels, setProlevels] = useState("");

    const [messageUserProfile, setMessageUserProfile] = useState('');

    const [procurement, setProcurement] = useState([]);

    const [spendUnder, setSpendUnder] = useState([]);

    const [reportTo, setReportTo] = useState([]);


    const [stateList, setStateList] = useState('');
    const [text, setText] = useState('');
    const [variant, setVariant] = useState("success");

    //password rule
    const [PasspasswordRule1, setPasspasswordRule1] = useState(false);
    const [PasspasswordRule2, setPasspasswordRule2] = useState(false);
    const [PasspasswordRule3, setPasspasswordRule3] = useState(false);
    const [PasspasswordRule4, setPasspasswordRule4] = useState(false);

    // learning plans list
    const [LearningPlansData, setLearningPlansData] = useState([]);

    // Deletemodel
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [LearningPlanId, setLearningPlanId] = useState(null);
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    // Model
    const [TitleTextVal, setTitleTextVal] = useState('');
    const [LogoSignup, setLogoSignup] = useState("Choose file");
    const [learningPlansModel, setLearningPlansModel] = useState(false);
    const [flieList, setFlieList] = useState('');
    const assignToggle = () => {
        setLearningPlansModel(!learningPlansModel);
    };
    const onChangeTitleTextVal = (e) => {
        setTitleTextVal(e.target.value);
    };
    const onChangeFile = (e) => {
        const file = e.target.files[0];
        console.log(file);
        if(e.target.files[0]){
            if(file.type === "application/pdf"){
                setLogoSignup(e.target.files[0].name)
                setFlieList(file);
            }else{
                toast.error('Only supports PDF format files.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }else{
            setLogoSignup('Choose file');
            setFlieList('');
        }
    }
    const addLearningPlanFun = (data) => {
        if(flieList==""){
            toast.error('Kindly fill all the fields!! ', {position: toast.POSITION.TOP_CENTER})
        }else{
            var TitleText = TitleTextVal ==""?dayjs().year():TitleTextVal
            CompanyService.UploadFilePost(7,TitleText, flieList, '',currentUser.id).then((response) => {
                if (response.data.Status !== "Success") {
                    setLearningPlansModel(false);
                    toast.error(response.data.Message, { position: toast.POSITION.TOP_CENTER })
                }else{
                    setLearningPlansModel(false);
                    userService.GetUserLearningPlansList(currentUser.id).then((response) => {
                        if(response.Status === "Success"){
                            setLearningPlansData(response.AssessmentLearningPlanList);
                        }else{
                            toast.error(response.Message, { position: toast.POSITION.TOP_CENTER })
                        }
                    })
                    toast.success("Learning plan upload successfully.", { position: toast.POSITION.TOP_CENTER })
                }
            })
        }
    }

    useEffect(() => {
        userService.GetUserProfiledetailsByUserId(currentUser.id).then(
            (response) => {
                if (response !== null) {
                    setUserProfileObj(response);
                    setFirstNameProfile(response.FirstName)
                    setLastNameProfile(response.LastName)
                    setEmailProfile(response.Email)
                    setRegistrationDateProfile(response.registration_date)
                    setExperienceUserProfile(response.experience)
                    setIndustryUserProfile(response.industry)
                    setPasswordUserProfile(response.password)
                    setEducationUserProfile(response.education)
                    // setSpecialistUserProfile(response.specialist)
                    setPhoneUserProfile(response.phone_number)
                    setJobRoleUserProfile(response.job_role)
                    setProLevelUserProfile(response.pro_level)
                    if(response.state == 0 || response.state =='0'){
                        setText('');
                    }else{
                        setText(response.state)
                    }
                    setPositionUserProfile(response.position)
                    setGeoInfluenceUserProfile(response.geo_influence)
                    if(response.postcode == 0 || response.postcode =='0'){
                        setPostCodeUserProfile('');
                    }else{
                        setPostCodeUserProfile(response.postcode);
                    }
                    setRoleUserProfile(response.RoleId)
                    setSectorUserProfile(response.sector)
                    setCountryUserProfile(response.country)
                    setSpendUnderProfile(response.spend_under)
                    setQualificationProfile(response.ps_qualification)
                    setPartnerProfile(response.partnerName)
                    setCompanyProfile(response.CompanyName)
                    setReportProfile(response.report_to)
                    setReportaccess(response.report_access)
                    setProgramName(response.program_name)

                    if (specialists.length === 0) {
                        const dropdownCompany = userService.GetSpecialist().then(
                            (response1) => {

                                if (response1 !== null) {
                                    let data = response1.map(x => {
                                        if (response.specialist.includes(x.id)) {
                                            x.checked = true;
                                            return x;
                                        } else {
                                            x.checked = false;
                                            return x;
                                        }
                                    });
                                    setSpecialists(data)
                                }

                            }
                        );
                    }
                    // userService.GetStateList(274).then(
                    //     (response1) => {
                    //         if (response1 !== null) {

                    //             setStateList(response1);
                    //             var hintArray = []
                    //             response1.map(a => hintArray.push(a.name))
                    //             setHintData(hintArray)

                    //         }
                    //     });
                    setJobLevelUserProfile(response.job_level)
                }
            });
        userService.GetUserLearningPlansList(currentUser.id).then((response) => {
            if(response.Status === "Success"){
                setLearningPlansData(response.AssessmentLearningPlanList);
            }else{
                toast.error(response.Message, { position: toast.POSITION.TOP_CENTER })
            }
        })
    }, [userId])
    useEffect(() => {

        if (userProfileObj === null) {
            userService.GetUserProfiledetailsByUserId(currentUser.id).then(
                (response) => {
                    if (response !== null) {
                        setUserProfileObj(response);
                        setFirstNameProfile(response.FirstName)
                        setLastNameProfile(response.LastName)
                        setEmailProfile(response.Email)
                        setRegistrationDateProfile(response.registration_date)
                        setExperienceUserProfile(response.experience)
                        setIndustryUserProfile(response.industry)
                        setPasswordUserProfile(response.password)
                        setEducationUserProfile(response.education)
                        // setSpecialistUserProfile()
                        setPhoneUserProfile(response.phone_number)
                        setJobRoleUserProfile(response.job_role)
                        setProLevelUserProfile(response.pro_level)
                        if(response.state == 0 || response.state =='0'){
                            setText('');
                        }else{
                            setText(response.state)
                        }
                        setPositionUserProfile(response.position)
                        setGeoInfluenceUserProfile(response.geo_influence)
                        if(response.postcode == 0 || response.postcode =='0'){
                            setPostCodeUserProfile('');
                        }else{
                            setPostCodeUserProfile(response.postcode);
                        }
                        setRoleUserProfile(response.RoleId)
                        setSectorUserProfile(response.sector)
                        setCountryUserProfile(response.country)
                        setSpendUnderProfile(response.spend_under)
                        setQualificationProfile(response.ps_qualification)
                        setPartnerProfile(response.partnerName)
                        setCompanyProfile(response.CompanyName)
                        setReportProfile(response.report_to)
                        setReportaccess(response.report_access)
                        setProgramName(response.program_name)
                        if (specialists.length === 0) {
                            const dropdownCompany = userService.GetSpecialist().then(
                                (response1) => {

                                    if (response1 !== null) {
                                        let data = response1.map(x => {
                                            if (response.specialist.includes(x.id)) {
                                                x.checked = true;
                                                return x;
                                            } else {
                                                x.checked = false;
                                                return x;
                                            }
                                        });
                                        console.log(data);
                                        setSpecialists(data)
                                    }

                                }
                            );
                        }
                        let passwordRule1 = /.{8,}/;
                        let passwordRule2 = /[A-Z]/;
                        let passwordRule3 = /[a-z]/;
                        let passwordRule4 = /\d/;
                        if(passwordRule1.test(response.password)){
                            setPasspasswordRule1(true)
                        }else{
                            setPasspasswordRule1(false)
                        }
                        if(passwordRule2.test(response.password)){
                            setPasspasswordRule2(true)
                        }else{
                            setPasspasswordRule2(false)
                        }
                        if(passwordRule3.test(response.password)){
                            setPasspasswordRule3(true)
                        }else{
                            setPasspasswordRule3(false)
                        }
                        if(passwordRule4.test(response.password)){
                            setPasspasswordRule4(true)
                        }else{
                            setPasspasswordRule4(false)
                        }
                        // userService.GetStateList(274).then(
                        //     (response1) => {
                        //         if (response1 !== null) {

                        //             setStateList(response1);
                        //             var hintArray = []
                        //             response1.map(a => hintArray.push(a.name))
                        //             setHintData(hintArray)

                        //         }
                        //     });
                        setJobLevelUserProfile(response.job_level)
                    }
                });
        }
        if (jobRoles.length === 0) {
            const dropdownCompany = userService.GetJobRoles().then(
                (response) => {

                    if (response !== null) {
                        setJobRoles(response)
                    }

                }
            );
        }
        if (positions.length === 0) {
            const dropdownCompany = userService.GetPositionList().then(
                (response) => {

                    if (response !== null) {
                        setPositions(response)
                    }

                }
            );
        }


        if (proLevels.length === 0) {
            const dropdownCompany = userService.GetProLevelList().then(
                (response) => {

                    if (response !== null) {
                        setProlevels(response)
                    }

                }
            );
        }

        if (geoInfluences.length === 0) {
            const dropdownCompany = userService.GetGeoInfluenceList().then(
                (response) => {

                    if (response !== null) {
                        setGeoInfluences(response)
                    }

                }
            );
        }
   
        if (sectors.length === 0) {
            const dropdownCompany = userService.GetSectorList().then(
                (response) => {

                    if (response !== null) {
                        setSectors(response)
                    }

                }
            );
        }
        if (educations.length === 0) {
            const dropdownCompany = userService.GetEducation().then(
                (response) => {

                    if (response !== null) {
                        setEducation(response)
                    }

                }
            );
        }
        if (industries.length === 0) {
            const dropdownCompany = userService.GetIndustries().then(
                (response) => {

                    if (response !== null) {
                        setIndustries(response)
                    }

                }
            );
        }

        if (experiences.length === 0) {
            const dropdownCompany = userService.GetExperience().then(
                (response) => {

                    if (response !== null) {
                        setExperience(response)
                    }

                }
            );
        }
        if (countries.length === 0) {
            const countryData = CompanyService.GetCountryList();

            var details = countryData.then((response) => {
                return response;
            })
                .then((data) => {
                    setCountry(data);
                })
                .catch(e => {
                    console.log(e);
                })
        }
        if (procurement.length === 0) {
            const Qualification = userService.GetQualificationList();

            var details = Qualification.then((response) => {
                return response;
            })
                .then((data) => {
                    setProcurement(data);
                })
                .catch(e => {
                    console.log(e);
                })
        }
        if (spendUnder.length === 0) {
            const SpendUnder = userService.GetSpendUnderList();

            var details = SpendUnder.then((response) => {
                return response;
            })
                .then((data) => {
                    setSpendUnder(data);
                })
                .catch(e => {
                    console.log(e);
                })
        }
        if (reportTo.length === 0) {
            const ReportTo = userService.GetReportToList();

            var details = ReportTo.then((response) => {
                return response;
            })
                .then((data) => {
                    setReportTo(data);
                })
                .catch(e => {
                    console.log(e);
                })
        }
    },[]);
    const onChangeFirstNameProfile = (e) => {
        const firstName = e.target.value;
        setFirstNameProfile(firstName);
    }
    const onChangeLastNameProfile = (e) => {
        const lastName = e.target.value;
        setLastNameProfile(lastName);
    }
    const onChangeExperience = (e) => {
        const experience = e.target.value;
        setExperienceUserProfile(experience);
    };
    const onChangeQualification = (e) => {
        const qualification = e.target.value;
        setQualificationProfile(qualification);
    };
    const onChangespendUnder = (e) => {
        const spendUnder = e.target.value;
        setSpendUnderProfile(spendUnder);
    }

    const onChangeReportProfile = (e) => {
        const ReportTo = e.target.value;
        setReportProfile(ReportTo);
    }
    const onChangeReportaccess = (e) => {
        const Reportaccess = e.target.checked;
        setReportaccess(Reportaccess);
    }

    const onChangeIndustry = (e) => {
        const industry = e.target.value;
        setIndustryUserProfile(industry);
    };
    const onChangeEducation = (e) => {
        const education = e.target.value;
        setEducationUserProfile(education);
    };
    const onChangeSpecialist = (e) => {
        if (e.target.checked) {
            setSpecialists(specialists.map((item) => {
                if (item.id == e.target.value) {
                    item.checked = true;
                    return item;
                } else {
                    return item;
                }
            }));
        } else {
            setSpecialists(specialists.map((item) => {
                if (item.id == e.target.value) {
                    item.checked = false;
                    return item;
                } else {
                    return item; 
                }
            }));
        }
    };
    const onSuggestionHandler = (text) => {
        setText(text);
        setSuggestions([]);
    };
    const onChangeState = (text) => {
        // let matches = [];
        // if (text.length > 0) {
        //     matches = stateList.filter(x => {
        //         const regex = new RegExp(`${text}`, "gi")
        //         return x.name.match(regex)
        //     })
        // }
        // setSuggestions(matches);
        setText(text);
    };
    const onChangeCompanyCountrySignup = (e) => {
        const companyCountry = e.target.value;
        setCountryUserProfile(companyCountry);
    };
    const handleUserRoleChange = (e) => {
        const role = e.target.value;
        setRoleUserProfile(role);
    }
    const onChangeRegisterationDate = (e) => {
        const registerationDate = e.target.value;
        setRegistrationDateProfile(registerationDate);
    }
    const onChangeProLevelUserProfile = (e) => {
        const proLevel = e.target.value;
        setProLevelUserProfile(proLevel);
    };
    const onChangePositionUserProfile = (e) => {
        const position = e.target.value;
        setPositionUserProfile(position);
    };
    const onChangeGeoInfluenceUserProfile = (e) => {
        const geoInfluence = e.target.value;
        setGeoInfluenceUserProfile(geoInfluence);
    };
    const onChangeSectorUserProfile = (e) => {
        const sector = e.target.value;
        setSectorUserProfile(sector);
    };
    const onChangePhoneNumberProfile = (e) => {
        const phone = e.target.value;
        setPhoneUserProfile(phone);
    }
    const onChangeJobRole = (e) => {
        const jobRole = e.target.value;
        setJobRoleUserProfile(jobRole);
    };

    const onChangeJobLevel = (e) => {

        const jobLevel = e.target.value;
        setJobLevelUserProfile(jobLevel);
    };
    const onChangePassword = (e) => {
        let passwordRule1 = /.{8,}/;
        let passwordRule2 = /[A-Z]/;
        let passwordRule3 = /[a-z]/;
        let passwordRule4 = /\d/;
        const password = e.target.value;
        if(passwordRule1.test(password)){
            setPasspasswordRule1(true)
        }else{
            setPasspasswordRule1(false)
        }
        if(passwordRule2.test(password)){
            setPasspasswordRule2(true)
        }else{
            setPasspasswordRule2(false)
        }
        if(passwordRule3.test(password)){
            setPasspasswordRule3(true)
        }else{
            setPasspasswordRule3(false)
        }
        if(passwordRule4.test(password)){
            setPasspasswordRule4(true)
        }else{
            setPasspasswordRule4(false)
        }
        setPasswordUserProfile(password);
    }
    const onChangePostCode = (e) => {
        const postCode = e.target.value;
        setPostCodeUserProfile(postCode);
    }
    const onChangeProgramName = (e) => {
        const postCode = e.target.value;
        setProgramName(postCode);
    }
    
    // const validatePassword = (e) => {

    //     const password = e.target.value;

    //     if (validator.isStrongPassword(password, {
    //         minLength: 8, minLowercase: 1,
    //         minUppercase: 1, minNumbers: 1, minSymbols: 1
    //     })) {
    //         setMessageUserProfile('')
    //         setPasswordUserProfile(password);
    //     } else {
    //         setVariant("danger");
    //         setMessageUserProfile('Please enter a  strong password(minLength: 8, minLowercase: 1,minUppercase: 1, minNumbers: 1, minSymbols: 1)')
    //     }
    // }
     
    const [showTooltip, setshowTooltip] = useState(true);
    const onClickBtnEdit = () => {
        setshowTooltip(false)
        let specialistUserProfile = [];
        specialists.map(special => {
            if (special.checked) {
                specialistUserProfile.push(special.id)
            }
        });
        if (specialistUserProfile.length == 0) {
            specialistUserProfile.push(0);
        }
        let passwordRule1 = /.{8,}/;
        let passwordRule2 = /[A-Z]/;
        let passwordRule3 = /[a-z]/;
        let passwordRule4 = /\d/;
        if(passwordRule1.test(passwordUserProfile)){
            setPasspasswordRule1(true)
        }else{
            setPasspasswordRule1(false)
        }
        if(passwordRule2.test(passwordUserProfile)){
            setPasspasswordRule2(true)
        }else{
            setPasspasswordRule2(false)
        }
        if(passwordRule3.test(passwordUserProfile)){
            setPasspasswordRule3(true)
        }else{
            setPasspasswordRule3(false)
        }
        if(passwordRule4.test(passwordUserProfile)){
            setPasspasswordRule4(true)
        }else{
            setPasspasswordRule4(false)
        }
        if (disabledFields) {
            setDisabledFields(false);
            seteditButtonText(<Check size='30' color="green" />);
        } else {
            if (!PasspasswordRule1 || !PasspasswordRule2 || !PasspasswordRule3|| !PasspasswordRule4) {
                toast.error('Password is too weak. You must use a stronger password to continue.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                if (firstNameProfile != "" && lastNameProfile != "" &&  industryUserProfile != "0" && sectorUserProfile != "0" && countryUserProfile != "0"&&experienceUserProfile != '0'&&educationUserProfile!='0'&&jobLevelUserProfile!="") {
                    userService.SaveUserProfileFields(firstNameProfile, lastNameProfile, registrationDateProfile, experienceUserProfile, industryUserProfile, passwordUserProfile, educationUserProfile, specialistUserProfile, phoneUserProfile, jobRoleUserProfile, proLevelUserProfile, text, positionUserProfile, geoInfluenceUserProfile, jobLevelUserProfile, postCodeUserProfile, roleUserProfile, sectorUserProfile, countryUserProfile, reportaccess, reportProfile, qualificationProfile, spendUnderProfile,ProgramName, currentUser.id, (props.history.location.state != undefined && props.history.location.state.data != undefined ? false : true))
                        .then(
                            (response) => {
                                if (response !== null) {
                                    toast.success('Details updated successfully', {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                    if(currentUser.IsCompleteProfile == false){
                                        var ProfileInfo = currentUser;
                                        ProfileInfo.IsCompleteProfile = true;
                                        sessionStorage.setItem("user", JSON.stringify(ProfileInfo));
                                    }
                                    window.location.reload();
                                } else {
                                    toast.error('There is some error saving the details!! Please try again', {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                }
                                setDisabledFields(true);
                                seteditButtonText("Edit");
                            }
                        )

                } else {
                    toast.error('Kindly fill all the fields!!', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
    };

    const onClickBtnCancel = () => {
        // seteditButtonText(<Edit2 size='30' />);
        // setDisabledFields(true);
        // setMessageUserProfile("")
        // setSuggestions([]);
        window.location.reload();
    };

    const addLearningPlan = () =>{
        setLearningPlansModel(true);
        setTitleTextVal('');
        setFlieList("");
        setLogoSignup("Choose file")
    }
    const DeletePlan = (TitleText, LearningPlanId) => {
        setLearningPlanId(LearningPlanId)
        setDeleteMessage(
            `Are you sure you want to delete the learning plan '${TitleText}'?`
        );
        setDisplayConfirmationModal(true);
    };
    const submitDelete = (user,id) => {
        userService.DeleteAssessmentLearningPlan(currentUser.id,id).then(response=>{
            setDisplayConfirmationModal(false);
            if(response.Status === 'Success'){
                toast.success("Learning plan delete successfully.", { position: toast.POSITION.TOP_CENTER })
                userService.GetUserLearningPlansList(currentUser.id).then((response) => {
                    if(response.Status === "Success"){
                        setLearningPlansData(response.AssessmentLearningPlanList);
                    }else{
                        toast.error(response.Message, { position: toast.POSITION.TOP_CENTER })
                    }
                })
            }else{
                toast.error(response.Message, { position: toast.POSITION.TOP_CENTER })
            }
        })
    }
    return (
        <>
        <Card>
            <div>
                {messageUserProfile && <Alert color={variant} style={{ "width": "400px" }}>{messageUserProfile}</Alert>}
                {!disabledFields &&
                    <Button color="dark" className="float-right" style={{ margin: '10px' }} onClick={onClickBtnCancel}>
                        <X size='30' />
                    </Button>}
                {specialists && specialists.length > 0 &&
                <Tooltip title="Click here to Edit Profile" placement="left" trigger="click" defaultOpen open={showTooltip}>
                 <Button color="link" id='editButton' className="float-right" style={{ margin: '10px' }} onClick={onClickBtnEdit}>{editButtonText}
                    </Button>
                </Tooltip>
                   
                }
            
            </div>

            <CardBody>

                <Row>
                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>First Name<span style={{ color: "red" }}>*</span></Label>
                            <Input
                                className={styles.profile_input}
                                bsSize="lg"
                                type="text"
                                name="first-name"
                                placeholder="Enter First Name..." value={firstNameProfile}
                                onChange={onChangeFirstNameProfile}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Registration Date</Label>
                            <Input
                                bsSize="lg"
                                className={styles.profile_input}
                                type="date"
                                name="date"
                                placeholder="Registration Date"
                                value={registrationDateProfile}
                                // pattern="\d{4}-\d{2}-\d{2}"
                                onChange={onChangeRegisterationDate} disabled={disabledFields}
                                readOnly="readonly"
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Job Role best described as</Label>
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={jobRoleUserProfile} onChange={onChangeJobRole}
                            >
                                <option value="0">Select Job Role...</option>
                                {jobRoles &&
                                    jobRoles.length > 0 &&
                                    jobRoles.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>


                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Last Name<span style={{ color: "red" }}>*</span></Label>
                            <Input
                                className={styles.profile_input}
                                bsSize="lg"
                                type="text"
                                name="last-name"
                                placeholder="Enter Last Name..." value={lastNameProfile}
                                onChange={onChangeLastNameProfile}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Sector<span style={{ color: "red" }}>*</span></Label>
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={sectorUserProfile} onChange={onChangeSectorUserProfile}
                            >
                                <option value="0">Select sector</option>
                                {sectors &&
                                    sectors.length > 0 &&
                                    sectors.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Education Discipline<span style={{ color: "red" }}>*</span></Label>
                            {/* <Input
                                bsSize="lg"
                                type="text"
                                className={styles.profile_input}
                                name="Education Discipline"
                                placeholder="Experience" value={experienceUserProfile}
                                onChange={onChangeExperience}
                                disabled={disabledFields} /> */}
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={experienceUserProfile} onChange={onChangeExperience}
                            >
                                <option value="0">Select Discipline</option>
                                {experiences &&
                                    experiences.length > 0 &&
                                    experiences.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Email<span style={{ color: "red" }}>*</span></Label>
                            <Input
                                className={styles.profile_input}
                                bsSize="lg"
                                type="text"
                                name="email"
                                placeholder="Email..." value={emailProfile}
                                disabled={true} />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup} style={{ marginBottom: '0' }}>
                            <Label className={styles.formLabel}>Password</Label>
                            <Popover
                                content={
                                    <div>
                                        <p>{PasspasswordRule1? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}At least 8 characters long.</p>
                                        <p>{PasspasswordRule2? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one uppercase letter.</p>
                                        <p>{PasspasswordRule3? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one lowercase letter.</p>
                                        <p>{PasspasswordRule4? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one number.</p>
                                    </div>}
                                placement="right"
                                title={
                                    <span>
                                        Password requirements:
                                    </span>
                                }
                                trigger="focus">
                                <Input
                                    bsSize="lg"
                                    className={styles.profile_input}
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={passwordUserProfile} onChange={onChangePassword}
                                    disabled={disabledFields}
                                    onFocus={onChangePassword}
                                    />
                            </Popover>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Industry<span style={{ color: "red" }}>*</span></Label>
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={industryUserProfile} onChange={onChangeIndustry}>
                                <option value="0">Select Industry</option>
                                {industries &&
                                    industries.length > 0 &&
                                    industries.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Number of employees in your organisation</Label>
                            <select disabled={disabledFields} name="Procurement"
                                className={styles.profile_input + ' form-control'}
                                value={qualificationProfile} onChange={onChangeQualification}
                            >
                                <option value="0">Select Value</option>
                                {procurement &&
                                    procurement.length > 0 &&
                                    procurement.map(schema => {
                                        return <option key={schema._id} value={schema._id}>{schema._name}</option>;
                                    })}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Phone Number</Label>
                            <Input
                                bsSize="lg"
                                type="text"
                                className={styles.profile_input}
                                name="phone"
                                placeholder="Phone Number" value={phoneUserProfile}
                                onChange={onChangePhoneNumberProfile}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Country<span style={{ color: "red" }}>*</span></Label>
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={countryUserProfile} onChange={onChangeCompanyCountrySignup}
                            >
                                <option value="0">Select Country</option>
                                {countries &&
                                    countries.length > 0 &&
                                    countries.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.country_name}</option>;
                                    })}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Number of employees in HR team</Label>
                            <select disabled={disabledFields} name="influence"
                                className={styles.profile_input + ' form-control'}
                                value={spendUnderProfile} onChange={onChangespendUnder}
                            >
                                <option value="0">Select Value</option>
                                {spendUnder &&
                                    spendUnder.length > 0 &&
                                    spendUnder.map(schema => {
                                        return <option key={schema._id} value={schema._id}>{schema._name}</option>;
                                    })}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Job Title<span style={{ color: "red" }}>*</span></Label>
                            <Input
                                bsSize="lg"
                                className={styles.profile_input}
                                type="text"
                                name="job-Title"
                                placeholder="Job Title"
                                value={jobLevelUserProfile} onChange={onChangeJobLevel}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>

                    {/* <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>State</Label>
                            <Input
                                className={styles.profile_input}
                                bsSize="lg"
                                type="text"
                                name="state"
                                placeholder="Enter State..." value={text} onChange={e => onChangeState(e.target.value)}
                                disabled={disabledFields} />
                            {suggestions && suggestions.map((suggestion, i) => <div style={{
                                cursor: "pointer",
                                borderRight: "1px solid black",
                                borderLeft: "1px solid black",
                                borderBottom: "1px solid black"
                            }} key={i} onClick={() => onSuggestionHandler(suggestion.name)}>{suggestion.name}</div>)}
                        </FormGroup>
                    </Col> */}

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Level of Education<span style={{ color: "red" }}>*</span></Label>
                            {/* <Input
                                bsSize="lg"
                                className={styles.profile_input}
                                type="text"
                                name="Level of Education"
                                placeholder="Secondary school"
                                value={educationUserProfile}
                                onChange={onChangeEducation}
                                disabled={disabledFields}
                            /> */}
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={educationUserProfile} onChange={onChangeEducation}
                            >
                                <option value="0">Select Qualification</option>
                                {educations &&
                                    educations.length > 0 &&
                                    educations.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Partner</Label>
                            <Input
                                bsSize="lg"
                                className={styles.profile_input}
                                type="text"
                                name="Partner"
                                placeholder="Partner"
                                value={partnerProfile}
                                disabled={true} />
                        </FormGroup>
                    </Col>

                    {/* <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Postcode</Label>
                            <Input
                                bsSize="lg"
                                className={styles.profile_input}
                                type="text"
                                name="post-code"
                                placeholder="Postcode"
                                value={postCodeUserProfile} onChange={onChangePostCode}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col> */}

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Level of role you report into</Label>
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={geoInfluenceUserProfile} onChange={onChangeGeoInfluenceUserProfile}
                            >
                                <option value="0">Select Role</option>
                                {geoInfluences &&
                                    geoInfluences.length > 0 &&
                                    geoInfluences.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Client</Label>
                            <Input
                                bsSize="lg"
                                className={styles.profile_input}
                                type="text"
                                name="Company"
                                placeholder="Client"
                                value={companyProfile}
                                disabled={true} />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>What function does HR report into</Label>
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={reportProfile} onChange={onChangeReportProfile}
                            >
                                <option value="0">Select function</option>
                                {reportTo &&
                                    reportTo.length > 0 &&
                                    reportTo.map(schema => {
                                        return <option key={schema._id} value={schema._id}>{schema._name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>


                    {/* <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Role</Label>

                            <select className={styles.profile_input +' form-control'} value={roleUserProfile} onChange={handleUserRoleChange}
                                disabled={currentUser.roleName == "super-admin" ? disabledFields : "disabled"}>
                                <option value="0">Select Role</option>
                                <option value="1">user</option>
                                <option value="2">team-member</option>
                                <option value="3">team-leader</option>
                                <option value="4">company</option>
                                <option value="5">partner</option>
                                <option value="6">super-admin</option>
                            </select>
                        </FormGroup>
                    </Col> */}

                    {<Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Program Name</Label>
                            <Input
                                bsSize="lg"
                                className={styles.profile_input}
                                type="text"
                                name="Program-Name"
                                placeholder="ProgramName"
                                value={ProgramName} onChange={onChangeProgramName}
                                disabled={((currentUser.roleName !== 'super-admin' && currentUser.roleName !== 'company'&& currentUser.roleName !== 'partner'&&!disabledFields)||disabledFields) } />
                        </FormGroup>
                    </Col>}

                    <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup}>
                            <Label className={styles.formLabel}>Preferred method of study</Label>
                            <select disabled={disabledFields} name="expirience"
                                className={styles.profile_input + ' form-control'}
                                value={proLevelUserProfile} onChange={onChangeProLevelUserProfile}
                            >
                                <option value="0">Select method</option>
                                {proLevels &&
                                    proLevels.length > 0 &&
                                    proLevels.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    {/* currentUser.roleName == 'team-member' && */}
                    {(currentUser.roleName != 'team-member' &&currentUser.roleName != 'team-leader' && currentUser.roleName != 'user') && <Col xs={4} md={4} className={styles.col_pl10}>
                        <FormGroup className={styles.formgroup_1} style={{ marginTop: '1.2rem' }}>
                            <Label className={styles.formLabel} style={{ marginRight: '1rem' }}>Report access</Label>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitch1"
                                    disabled={disabledFields} checked={reportaccess} onChange={e => {
                                        onChangeReportaccess(e)
                                    }}></input>
                                <label className="custom-control-label" htmlFor="customSwitch1"></label>
                            </div>
                        </FormGroup>
                    </Col>}
                    
                </Row>


                {/* <div style={{ display: 'flex', alignItems: 'flex-start' }} className="mt-3">
                    <Label className={styles.formLabel} style={{ whiteSpace: 'nowrap' }}>Your previous category
                        experience</Label>
                    <div className="ml-3 row">
                        {specialists &&
                            specialists.length > 0 &&
                            specialists.map(schema => {
                                return <div className="form-check" key={schema.id}
                                    style={{ display: 'inline-block', margin: "0 0.5rem", width: "190px" }}>
                                    <input className="form-check-input" type="checkbox" disabled={disabledFields}
                                        value={schema.id} id={'specia' + schema.id} checked={schema.checked}
                                        onChange={e => {
                                            onChangeSpecialist(e)
                                        }}></input>
                                    <label className="form-check-label" htmlFor={'specia' + schema.id}>
                                        {schema.name}
                                    </label>
                                </div>
                            })}
                    </div>

                </div> */}


            </CardBody>
        </Card>
        {currentUser.roleName === "partner" && <Card>
            <Modal
                show={learningPlansModel}
                onHide={assignToggle}
                backdrop="static"
                centered
                size="md"
            >
                <ModalHeader toggle={assignToggle} style={{border: 'none',paddingLeft:"1.7rem",marginTop:"0.7rem",paddingRight:"1.7rem"}}>
                    <div style={{color:"#000000",fontSize:"1.3rem",color:"#007BFF",marginBottom:"0"}}>Add Learning Plan</div>
                </ModalHeader>
                <hr className="mx-auto"
                    style={{display: 'block',width: '86%',margin:'0'}}
                />
                <ModalBody style={{padding:"1rem 1.8rem 0 1.8rem",fontSize:"11px"}}>
                    <Container>
                        <Row>
                            <Col xs={6} md={6}>
                                <Input
                                    className="AddCompany_input"
                                    bsSize="md"
                                    type="text"
                                    name=""
                                    placeholder="Title Text" onChange={onChangeTitleTextVal}
                                    value={TitleTextVal}
                                />   
                            </Col>
                            <Col xs={6} md={6}>
                                <div className="input-group">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" onChange={onChangeFile}></input>
                                        <label className="custom-file-label" style={{overflow: 'hidden',fontSize:"1rem",lineHeight: '1.8rem',whiteSpace:'nowrap',textOverflow: 'ellipsis',paddingRight: '5rem'}}htmlFor="inputGroupFile04">{LogoSignup}</label>
                                    </div>   
                                </div>
                            </Col>
                            <hr className="mx-auto"
                                style={{display: 'block',width: '100%',margin:'0',marginTop: '70px'}}
                            />
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter style={{borderTop: 'none',padding: '0.75rem 1.5rem',marginBottom:'0.8rem'}}>
                    <button onClick={assignToggle} style={{width:"auto",flex:'none',backgroundColor:'#ffffff',borderColor:'#000000',color:"#000000",borderRadius:"5px",fontWeight:"600",border:"1px solid #D5D5D5",height:"2rem",padding: '0 0.7rem',background: '#e9ecef'}}>
                        Cancel
                    </button>
                    <button variant="primary" onClick={addLearningPlanFun}  style={{width:"82px",flex:'none',borderRadius:"5px",fontWeight:"600",height:"2rem",padding: '0 0.7rem',color: '#ffffff',background: '#007bff'}}>
                        Add
                    </button>
                </ModalFooter>
            </Modal>
            <CardHeader style={{paddingBottom:"0"}}>
                <CardTitle className="mb-0 d-flex align-items-center">
                    <h2 className="d-inline-block mr-3 mb-0">Learning Plans</h2>
                    <button
                        className="p-2 text-white bg-primary rounded-circle"
                       onClick={addLearningPlan}
                    >
                        <Plus size={28}/>
                    </button>      
                </CardTitle>
            </CardHeader>
            <CardBody>
                <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    type='user'
                    id={LearningPlanId}
                    message={deleteMessage}
                />
            {LearningPlansData.map((item,index) =>{
                return (
                    <div key={index} style={{display: 'flex',alignItems: 'center',justifyContent: 'space-between',marginBottom:"10px"}}>
                        <div style={{fontSize:"22px"}}>
                            {item.TitleText}
                        </div>
                        <div onClick={()=>{DeletePlan(item.TitleText,item.LearningPlanId)}}>
                            <img src={iconDeleteUser} style={{height:"30px",cursor:'pointer'}} alt="deleteIcon" />
                        </div>
                    </div>
                )
            })}
            </CardBody>
        </Card>}
        </>
        
    );
};

/*
* Single User Profile
*
* This view is used to display a single
* user profile with profile information
*
* TODO: Needs to be worked on the layout
* */

const UserProfile = (props) => {
    return (
        <div id="UserProfile">
            <Container fluid className="p-0">
                <div className="alert alert-info p-3 theme_bgcolor1">
                    Please complete your details. After completing your profile, navigate through to your Open Assessments
                </div>

                <Row>
                    <Col md="9" xl="9">
                        <Profile {...props} />
                        <Details {...props} />
                    </Col>

                    <Col md="3" xl="3">
                        <AssessmentDetail {...props} />
                        {/*<CreditDetail />*/}
                    </Col>

                </Row>
            </Container>
        </div>


    );
}

export default UserProfile;

