import React,{ useState,useEffect} from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";


const Main = ({ className, children }) => {
    return (
      <div className={classNames("main", className)}>{children}</div>
    )
 
  };

export default Main;
