import React, {useState, useEffect} from "react";

// Services
import CompanyService from "../../../services/companies.service";
import AuthService from "../../../services/auth.service.js";
import PartnerService from "../../../services/partners.service.js";
import userService from "../../../services/user.service.js";
import bsCustomFileInput from 'bs-custom-file-input'

// Components
import {
    Card,
    CardHeader,
    CardTitle,
    Container,
    Col,
    Row,
    Table,
    CardBody,
    FormGroup,
    Label,
    Input

} from "reactstrap";
import validator from 'validator'
import DeleteConfirmation from "../../../../src/components/DeleteConfirmation";
import {Filter, Settings, Plus, Check, X} from "react-feather";
import Pagination from "../../../../src/components/Pagination";
import {Button, Modal, Alert} from "react-bootstrap";
import copy from 'copy-to-clipboard'; 
import { Popover } from 'antd';


// Assets
import iconEdit from "../../../assets/img/icons/edit.svg";
import iconDelete from "../../../assets/img/icons/delete.svg";
import iconAddUser from "../../../assets/img/icons/add-user.svg";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

/*
* Company List View
*
* The following view shows all the companies
* registered under the platform.
*
* The companies displayed will depend on the
* User role. (Eg: Company Manager, Partner, Super Admin)
*
* TODO: Need to implement Company Profile view
* */
const CompaniesList = (props) => {
    var currentUser = AuthService.getCurrentUser();
    var currentCompany = AuthService.getCurrentCompany();
    var host =  window.location.host;

    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    if (currentUser.roleName === "user" || ((currentUser.roleName === "team-member" || currentUser.roleName === "team-leader"))) {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    const userRoleList = [
        {
            id: 1,
            value: 'user'
        }, {
            id: 2,
            value: 'team-member'
        }, {
            id: 3,
            value: 'team-leader'
        }, {
            id: 4,
            value: 'company'
        }, {
            id: 5,
            value: 'partner'
        }
    ];

    const [companies, setCompanies] = useState([]);
    const [countries, setCountry] = useState([]);
    const [id, setId] = useState(null);
    const [type, setType] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [companyMessage, setMessage] = useState(null);
    const [companyAddMessage, setCompanyAddMessage] = useState(null);
    const [companyAddCreditMessage, setCompanyAddCreditMessage] = useState(null);

    const [companyAddCreditsSignUp, setCompanyAddCreditsSignUp] = useState("");
    const [companyNameSignup, setCompanyNameSignup] = useState("");
    const [companyId, setCompanyId] = useState("");
    //const [companyPasswordSignup, setCompanyPasswordSignup] = useState("");
    //const [companyEmailSignup, setCompanyEmailSignup] = useState("");
    const [companyABVSignup, setCompanyABVSignup] = useState("");
    const [companyDomainSignup, setCompanyDomainSignup] = useState("");
    const [companyCountrySignup, setCompanyCountrySignup] = useState(261);
    const [companyAddressSignup, setCompanyAddressSignup] = useState("");
    const [companyStateSignup, setCompanyStateSignup] = useState("");
    const [colour1, setcolour1] = useState("");
    const [colour2, setcolour2] = useState("");
    const [need_client_additional_logo, setNeed_client_additional_logo] = useState(false);
    const [companyAdditionalLogo, setCompanyAdditionalLogo] = useState("");
    const [companyAdditionalLogoType, setCompanyAdditionalLogoType] = useState('');
    const [banner_text, setBanner_text] = useState("");
    const [section_1_title, setSection_1_title] = useState("");
    const [body_end_text, setBody_end_text] = useState("");
    const [section_1_body, setSection_1_body] = useState("");
    const [need_banner_image, setNeed_banner_image] = useState(false);
    const [BannerImage, setBannerImage] = useState("");
    const [BannerImageType, setBannerImageType] = useState('');
    const [companyZipSignup, setCompanyZipSignup] = useState("");
    const [companyIndustryNameSignup, setCompanyIndustryNameSignup] = useState(0);
    const [companyLogoSignup, setCompanyLogoSignup] = useState("");
    const [need_company_logo, setNeed_company_logo] = useState(false);
    const [alreadyhave_company_logo,setAlreadyhave_company_logo] = useState(false);
    const [company_logo_type, setCompany_logo_type] = useState('');
    const [CompanyModalTitle, setCompanyModalTitle] = useState("");
    const [CompanyModalButtonTitle, setCompanyModalButtonTitle] = useState("");
    const [partners, setPartners] = useState("");
    const [partnerId, setPartnerId] = useState(0);

    const [addUserModalShow, setAddUserModalshow] = useState(false);
    const [passwordSignup, setPasswordSignup] = useState("");
    const [emailSignup, setEmailSignup] = useState("");
    const [firstNameSignup, setFirstNameSignup] = useState("");
    const [lastNameSignup, setLastNameSignup] = useState("");
    const [messageAddUser, setMessageAddUser] = useState(null);
    const [currentCompanyAddUser, setCurrentCompanyAddUser] = useState("");
    const [currentPartnerAddUser, setCurrentPartnerAddUser] = useState(0);
    const [jobRoles, setJobRoles] = useState("");
    const [currentJobRole, setCurrentJobRole] = useState(0);
    const [industries, setIndustries] = useState("");

    const [LogoSignup, setLogoSignup] = useState("Choose file");
    const [BannerImageTxt, setBannerImageTxt] = useState("Choose file");
    const [CompanyAdditionalLogotxt, setCompanyAdditionalLogotxt] = useState("Choose file");
     
    const [userUpdatedRole, setUserUpdatedRole] = useState(1);
    const [filteredUser, setFilteredUser] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const [currentPosts, setCurrentPosts] = useState(1);
    // const currentPosts = companies.slice(indexOfFirstPost, indexOfLastPost);

    //password rule
    const [PasspasswordRule1, setPasspasswordRule1] = useState(false);
    const [PasspasswordRule2, setPasspasswordRule2] = useState(false);
    const [PasspasswordRule3, setPasspasswordRule3] = useState(false);
    const [PasspasswordRule4, setPasspasswordRule4] = useState(false);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);


    //componentDidMount
    useEffect(() => {
        bsCustomFileInput.init()
        //Fill company list
        const companyData = () => {
            var companyList = CompanyService.GetCompanyList();

            var details = companyList.then((response) => {
                return response;
            })
                .then((data) => {
                    if (currentUser.roleName === "partner") {

                        setCompanies(data.companyDetails.filter(x => x.Partner_Id === currentUser.partner_id));
                    } else {
                        setCompanies(data.companyDetails);
                    }
                    setCurrentPosts(companies.slice(indexOfFirstPost, indexOfLastPost));
                })
                .catch(e => {
                    console.log(e);
                })
        };
        //fill countries dropdown data
        const countryData = () => {
            var countryList = CompanyService.GetCountryList();

            var details = countryList.then((response) => {
                return response;
            })
                .then((data) => {
                    setCountry(data);
                })
                .catch(e => {
                    console.log(e);
                })
        };
        //fill partners dropdown data
        const partnerData = () => {
            var partnerList = PartnerService.GetPartnerList();
            var details = partnerList.then((response) => {
                return response;
            })
                .then((data) => {
                    if (currentUser.roleName === "partner") {
                        setPartners(data.partnerDetails.filter(x => x.Id === currentUser.partner_id));
                    } else {
                        setPartners(data.partnerDetails);
                    }
                })
                .catch(e => {
                    console.log(e);
                })
        };
        const jobRolesList = () => {
            userService.GetJobRoles().then(
                (response) => {

                    if (response !== null) {
                        setJobRoles(response)
                    }

                }
            );
        };
        const industriesList = () => {
            userService.GetIndustries().then(
                (response) => {

                    if (response !== null) {
                        setIndustries(response)
                    }

                }
            );
        };
        partnerData();
        countryData();
        companyData();
        industriesList();
        jobRolesList();
    }, []);


    //add company model 
    const [addCompanyModalshow, setAddCompanyModalshow] = useState(false);
    const addCompanyModalClose = () => setAddCompanyModalshow(false);
    const addCompanyModal = () => setAddCompanyModalshow(true);


    //add credits Company model 
    const [addCreditCompanyModalshow, setAddCreditCompanyModalshow] = useState(false);
    const addCreditCompanyModalClose = () => setAddCreditCompanyModalshow(false);
    const addCreditDetailCompanyModal = () => setAddCreditCompanyModalshow(true);


    const addCreditCompanyModal = (id) => {
        setCompanyModalTitle("Add Credits For Client")
        setCompanyModalButtonTitle("Add Credits")
        setAddCreditCompanyModalshow(true);
        setCompanyId(id);

        setCompanyAddCreditsSignUp("");
        setMessage("");


    };

    const copyHost = (e) => {
        if(e.target.textContent){
            let text = document.location.protocol + '//' + host + '/' + e.target.textContent;
            copy(text);
            toast.success('Link copied!', {position: toast.POSITION.TOP_CENTER})
        }
    }


    //close the add credits Company modal
    const addCreditCompanyDetailsModalClose = () => {
        setAddCreditCompanyModalshow(false);
        setCompanyAddCreditMessage("");
    };

    const handleUserRoleChange = (e) => {
        setUserUpdatedRole(e.target.value);
    }


    const showDeleteModal = (type, id) => {
        //setting type in case to make this reusable , here setting company , can be set to partners etc
        setType(type);
        //setting the id of the company
        setId(id);
        setDeleteMessage(`Are you sure you want to delete the company  '${companies.find((x) => x.Id === id).CompanyName}'?`);
        setDisplayConfirmationModal(true);
    };

    const handlePartnerChange = (e) => {

        setPartnerId(e.target.value);
    };

    const editCompanyModal = (id) => {
        addCompanyDetailsModalShow();
        setPartnerId(partners[0].Id);
        setCompanyModalTitle("Edit Client")
        setCompanyModalButtonTitle("Save Changes");
        var companyDetails = CompanyService.GetCompanyDetailsById(id);
        companyDetails.then((response) => {
            return response;
        })
            .then((data) => {

                setCompanyNameSignup(data.CompanyName);
                setPartnerId(data.Partner_Id);
                setCompanyIndustryNameSignup(data.CompanyIndustry);
                setCompanyABVSignup(data.Company_Abn);
                setCompanyAddressSignup(data.CompanyAddress);
                setCompanyCountrySignup(data.CompanyCountry);
                setCompanyStateSignup(data.CompanyState);
                setCompanyZipSignup(data.CompanyZip);
                //setCompanyEmailSignup(data.Email);
                setCompanyId(data.CompanyId)
                //setCompanyPasswordSignup(data.Password);
                setAddCompanyModalshow(true);
                //setFirstNameSignup(data.FirstName)
                //setLastNameSignup(data.LastName)
                setCompanyId(id);
                setcolour1(data.colour1);
                setcolour2(data.colour2);
                setBanner_text(data.companyPageModel.banner_text);
                setSection_1_title(data.companyPageModel.section_1_title);
                setBody_end_text(data.companyPageModel.body_end_text);
                setSection_1_body(data.companyPageModel.section_1_body);
                setCompanyDomainSignup(data.CompanyDomain);
                setCompanyABVSignup(data.CompanyEmail);
                if(data.logoDetails.FileName !== ''){
                    setAlreadyhave_company_logo(true);
                }
            })
            .catch(e => {
                toast.error('There is some error fetching the details of the company', {position: toast.POSITION.TOP_CENTER})
                console.log(e);
            })


    };
    const submitDelete = async (type, id) => {
        if (type === "company") {
            CompanyService.DeleteCompanyById(id);
            toast.success(`The Company '${companies.find((x) => x.Id === id).CompanyName}' was deleted successfully.`, {position: toast.POSITION.TOP_CENTER})
            setCompanies(companies.filter((company) => company.Id !== id));
            setCurrentPosts(companies.slice(indexOfFirstPost, indexOfLastPost));
        }
        setDisplayConfirmationModal(false);
    };
    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };
    //close the add company modal
    const addCompanyDetailsModalClose = () => {
        setAddCompanyModalshow(false);
        setCompanyAddMessage("");
    };
    //displays the add company modal 
    const addCompanyDetailsModalShow = () => {
        setAddCompanyModalshow(true);
        setCompanyAddMessage("");
        setCompanyModalTitle("Add New Client")
        setCompanyModalButtonTitle("Add Company")
        setCompanyNameSignup("");
        setCompanyIndustryNameSignup("0");
        setCompanyABVSignup("");
        setCompanyDomainSignup("");
        setCompanyAddressSignup("");
        setCompanyCountrySignup("0");
        setCompanyStateSignup("");
        setCompanyZipSignup("");
        setPartnerId(0);
        //setCompanyEmailSignup("");
        //setCompanyPasswordSignup("");
        //setFirstNameSignup("");
        //setLastNameSignup("");
        setCompanyId("0");
        setMessage("");
        setLogoSignup("Choose file");
        setBannerImageTxt("Choose file");
        setCompanyAdditionalLogotxt("Choose file");
        setBanner_text("");
        setSection_1_title("");
        setBody_end_text("");
        setSection_1_body("");
        setcolour2('');
        setcolour1('');
        setCompanyAdditionalLogo('');
        setBannerImage('');
        setCompanyLogoSignup('');
        setNeed_company_logo(false);
        setNeed_client_additional_logo(false);
        setNeed_banner_image(false);
        setAlreadyhave_company_logo(false);
        setCompany_logo_type('');
        setCompanyAdditionalLogoType('');
        setBannerImageType("");
    };
    // add or updates the company, if the company id is null/0 then inserts the company else updates
    const addCompanyDetails = () => {
        if (partnerId !== "0" && /* companyCountrySignup !== "0" && */ companyNameSignup !== "" /*&& companyPasswordSignup !== ""*/ && companyABVSignup !== "" /* && companyStateSignup !== "" */ /* && companyZipSignup !== "" */ /*&& companyEmailSignup !== ""*/ /* && companyAddressSignup !== "" */ && companyIndustryNameSignup !== "0" /*&& firstNameSignup !== "" && lastNameSignup !== "" */ && companyId && companyDomainSignup != "" &&colour1 !=''&& alreadyhave_company_logo === true) {
                CompanyService.UploadFilePost(1,companyNameSignup,BannerImage).then((response)=>{
                    if(response.data.Status !== "Success"){
                        setAddCompanyModalshow(true);
                        toast.error(response.data, {position: toast.POSITION.TOP_CENTER})
                    }else if(response.data.Status === "Success"){
                        CompanyService.UploadFilePost(2,companyNameSignup,companyAdditionalLogo).then((response)=>{
                            if(response.data.Status !== "Success"){
                                setAddCompanyModalshow(true);                 
                                toast.error(response.data, {position: toast.POSITION.TOP_CENTER})
                            }else if(response.data.Status === "Success"){
                                CompanyService.UploadFilePost(3,companyNameSignup,companyLogoSignup).then((response)=>{
                                    if(response.data.Status !== "Success"){
                                        setAddCompanyModalshow(true);
                                        toast.error(response.data, {position: toast.POSITION.TOP_CENTER})
                                    }else if(response.data.Status === "Success"){  
                                        uploadcompanyInfo()
                                    }
                                })
                            }
                        })
                    }
                })
    
            // .then(()=>{
            //     uploadcompanyInfo()
            // }).catch((data)=>{
            //     toast.error(data, {position: toast.POSITION.TOP_CENTER})
            // })
          
            const uploadcompanyInfo = () =>{
                    if (companyLogoSignup !== "") {
                        CompanyService.AddCompanyDetails(companyNameSignup, /*companyPasswordSignup, */companyABVSignup, companyStateSignup, companyZipSignup,
                             /*companyEmailSignup,*/ companyCountrySignup, companyAddressSignup, companyIndustryNameSignup, /*4, firstNameSignup, lastNameSignup,*/ companyId, partnerId, companyDomainSignup,colour1,colour2,need_client_additional_logo,banner_text,section_1_title,body_end_text,section_1_body,need_banner_image,companyAdditionalLogoType,BannerImageType,need_company_logo,company_logo_type).then(
                            (response) => {
                                if (response.data.Status === "Success") {
                                    const companyData = CompanyService.GetCompanyList();
                                    var details = companyData.then((response) => {
                                        return response;
                                    })
                                        .then((data) => {
                                            if (currentUser.roleName === "partner") {
                                                setCompanies(data.companyDetails.filter(x => x.Partner_Id === currentUser.partner_id));
                                            } else {
                                                setCompanies(data.companyDetails);
                                            }
                                            setCurrentPosts(companies.slice(indexOfFirstPost, indexOfLastPost));
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        })
                                    setAddCompanyModalshow(false);
                                    if (companyId === "0") {
                                        toast.success('Company Added Successfully', {position: toast.POSITION.TOP_CENTER})
                                    } else {
                                        toast.success('Company Updated Successfully', {position: toast.POSITION.TOP_CENTER})
                                    }
                                } else if(response.data.Status === "Failure"){
                                    setAddCompanyModalshow(true);
                                    toast.success(response.data.Message, {position: toast.POSITION.TOP_CENTER})
                                }
                            }
                        );
                    }
                    else {
                        CompanyService.AddCompanyDetails(companyNameSignup, /*companyPasswordSignup,*/ companyABVSignup, companyStateSignup, companyZipSignup,
                            /* companyEmailSignup, */companyCountrySignup, companyAddressSignup, companyIndustryNameSignup,/* 4, firstNameSignup, lastNameSignup, */companyId, partnerId, companyDomainSignup,colour1,colour2,need_client_additional_logo,banner_text,section_1_title,body_end_text,section_1_body,need_banner_image,companyAdditionalLogoType,BannerImageType,need_company_logo,company_logo_type).then(
                            (response) => {
                                if (response.data.Status === "Success") {
                                    const companyData = CompanyService.GetCompanyList();
        
                                    var details = companyData.then((response) => {
                                        return response;
                                    })
                                        .then((data) => {
                                            if (currentUser.roleName === "partner") {
                                                setCompanies(data.companyDetails.filter(x => x.Partner_Id === currentUser.partner_id));
                                            } else {
                                                setCompanies(data.companyDetails);
                                            }
        
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        })
                                    setAddCompanyModalshow(false);
                                    if (companyId === "0") {
                                        toast.success('Company Added Successfully', {position: toast.POSITION.TOP_CENTER})
                                    } else {
                                        toast.success('Company Updated Successfully', {position: toast.POSITION.TOP_CENTER})
                                    }
                                } else {
                                    setAddCompanyModalshow(true);
                                    toast.error(response.data.Message, {position: toast.POSITION.TOP_CENTER})
                                }
                            }
                        );
                    }
                
            }
        } else {
            setAddCompanyModalshow(true);
            toast.error('Kindly fill all the fields', {position: toast.POSITION.TOP_CENTER})
        }
    };
    const onChangeCompanyNameSignup = (e) => {
        const companyName = e.target.value;
        setCompanyNameSignup(companyName);
    };
    const onChangeJobRole = (e) => {

        const jobRole = e.target.value;
        setCurrentJobRole(jobRole);
    };

    const onChangeCompanyABV = (e) => {
        const companyABV = e.target.value;
        setCompanyABVSignup(companyABV);
    };

    const onChangeCompanyCountrySignup = (e) => {
        const companyCountry = e.target.value;
        setCompanyCountrySignup(companyCountry);
    };

    const onChangeCompanyAddressSignup = (e) => {
        const companyAddress = e.target.value;
        setCompanyAddressSignup(companyAddress);
    };
    const onChangeCompanyStateSignup = (e) => {
        const companyState = e.target.value;
        setCompanyStateSignup(companyState);
    };
    const onChangeCompanyZipSignup = (e) => {
        const companyZip = e.target.value;
        setCompanyZipSignup(companyZip);
    };
    const onChangeCompanyDomainSignup = (e) => {
        const companyDomain = e.target.value;
        setCompanyDomainSignup(companyDomain);
    };
    const onChangeColour1 = (e) => {
        const colour1 = e.target.value;
        setcolour1(colour1);
    };
    const onChangeColour2 = (e) => {
        const colour2 = e.target.value;
        setcolour2(colour2);
    };
    const onChangeCompanyIndustryNameSignup = (e) => {
        const companyIndustryName = e.target.value;
        setCompanyIndustryNameSignup(companyIndustryName);
    };
    const onChangeLogoSignup = (e) => {
        const companylogo = e.target.files[0];
        if(e.target.files[0]){
            setLogoSignup(e.target.files[0].name)
            setNeed_company_logo(true);
            setAlreadyhave_company_logo(true);
            setCompanyLogoSignup(companylogo);
            setCompany_logo_type(companylogo.name.split('.')[1])
        }else{
            setLogoSignup('Choose file');
            setNeed_company_logo(false);
            setAlreadyhave_company_logo(false);
            setCompanyLogoSignup('');
            setCompany_logo_type(false);
        }
       
    };
    const onChangeCompanyAdditionalLogo = (e) => {
        const additionalimage = e.target.files[0];
        if(e.target.files[0]){
            setCompanyAdditionalLogotxt(e.target.files[0].name)
            setCompanyAdditionalLogoType(additionalimage.name.split('.')[1])
            setNeed_client_additional_logo(true);
            setCompanyAdditionalLogo(additionalimage);
        }else{
            setCompanyAdditionalLogotxt('Choose file');
            setCompanyAdditionalLogoType(false)
            setNeed_client_additional_logo(false);
            setCompanyAdditionalLogo('');
        }
    };
    const onChangeBannerImage = (e) => {
        const bannerimge = e.target.files[0];
        if(e.target.files[0]){
            setBannerImageTxt(e.target.files[0].name)
            setBannerImageType(bannerimge.name.split('.')[1])
            setNeed_banner_image(true);
            setBannerImage(bannerimge);
        }else{
            setBannerImageTxt('Choose file');
            setBannerImageType(false)
            setNeed_banner_image(false);
            setBannerImage('');
        }
        
    };
    const onChangeBannerText = (e) => {
        const bannertext = e.target.value;
        setBanner_text(bannertext);
    };
    const onChangeSection1Title = (e) => {
        const Section1Title = e.target.value;
        setSection_1_title(Section1Title);
    };
    const onChangeBodyendtext = (e) => {
        const Bodyendtext = e.target.value;
        setBody_end_text(Bodyendtext);
    };
    const onChangeSection1body = (e) => {
        const Section1body = e.target.value;
        setSection_1_body(Section1body);
    };
    const onChangeUserPasswordSignup = (e) => {
        let passwordRule1 = /.{8,}/;
        let passwordRule2 = /[A-Z]/;
        let passwordRule3 = /[a-z]/;
        let passwordRule4 = /\d/;
        const password = e.target.value;
        if(passwordRule1.test(password)){
            setPasspasswordRule1(true)
        }else{
            setPasspasswordRule1(false)
        }
        if(passwordRule2.test(password)){
            setPasspasswordRule2(true)
        }else{
            setPasspasswordRule2(false)
        }
        if(passwordRule3.test(password)){
            setPasspasswordRule3(true)
        }else{
            setPasspasswordRule3(false)
        }
        if(passwordRule4.test(password)){
            setPasspasswordRule4(true)
        }else{
            setPasspasswordRule4(false)
        }
        setPasswordSignup(password);
    };


    const onChangeFirstName = (e) => {
        const firstName = e.target.value;
        setFirstNameSignup(firstName);
    };

    const onChangeLastName = (e) => {
        const lastName = e.target.value;
        setLastNameSignup(lastName);
    };

    const onChangeEmailSignup = (e) => {
        const email = e.target.value;
        setEmailSignup(email);
    };

    //for add credits company  
    const onChangeCompanyAddCreditsSignup = (e) => {
        const companyCredits = e.target.value;
        setCompanyAddCreditsSignUp(companyCredits);
    }


    const addUserDetailsModalClose = () => {
        setAddUserModalshow(false);
        setMessageAddUser("")
    };
    const addUserModal = (id, partnerId) => {
        setCurrentCompanyAddUser(id);
        setCurrentPartnerAddUser(partnerId);
        setFirstNameSignup("");
        setLastNameSignup("");
        setEmailSignup("");
        setPasswordSignup("");
        setFilteredUser(userRoleList.filter(x => x.id === 2 || x.id === 3 || x.id === 4));
        setAddUserModalshow(true);

    }
    const btnClick_addUser = () => {

        if (passwordSignup !== "" && firstNameSignup !== "" && lastNameSignup !== "" && emailSignup !== "" && currentJobRole !== "0" && userUpdatedRole != "0") {
            if(!PasspasswordRule1 || !PasspasswordRule2 || !PasspasswordRule3|| !PasspasswordRule4){
                toast.error('Password is too weak. You must use a stronger password to continue.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }else{
                userService.AddUserDetails(firstNameSignup, lastNameSignup, emailSignup, "", passwordSignup, currentJobRole, currentPartnerAddUser, currentCompanyAddUser, userUpdatedRole, 0, "",true).then(
                    (response) => {

                        if (response.data.Status === "Success") {

                            setAddUserModalshow(false);
                            toast.success('User Added Successfully', {position: toast.POSITION.TOP_CENTER})
                        } else {
                            setAddUserModalshow(true);
                            toast.success(response.data.Message, {position: toast.POSITION.TOP_CENTER})
                        }
                    }
                );
            }

            
        } else {
            setAddUserModalshow(true);
            toast.error('Kindly fill all the fields!! ', {position: toast.POSITION.TOP_CENTER})
        }
    }

    const ChangeComingSoon = (companyid,v) =>{
        CompanyService.UpdateCompanyComingSoon(companyid,v).then((response)=>{
            console.log(response);
                var companyList = CompanyService.GetCompanyList();
    
                var details = companyList.then((response) => {
                    return response;
                })
                    .then((data) => {
                        if (currentUser.roleName === "partner") {
    
                            setCompanies(data.companyDetails.filter(x => x.Partner_Id === currentUser.partner_id));
                        } else {
                            setCompanies(data.companyDetails);
                        }
                        setCurrentPosts(companies.slice(indexOfFirstPost, indexOfLastPost));
                    })
                    .catch(e => {
                        console.log(e);
                    })
        })
    }


    //code for add  credits for company

    const addCreditDetailsOfCompany = () => {
        //code for credit details 

        var currentUserId = currentUser.id;
        if (companyAddCreditsSignUp !== "" && companyId) {

            CompanyService.AddCompanyCreditDetails(companyAddCreditsSignUp, companyId, currentUserId).then(
                (response) => {
                    if (response.data.Status === "Success") {
                        toast.success("Credit Successfully Added Into Company Account !!", {position: toast.POSITION.TOP_CENTER})
                    }
                }
            );
        }

    };


    if (currentUser || currentCompany) {
        return (

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0 d-flex align-items-center">
                        <h2 className="d-inline-block mr-3 mb-0">Clients</h2>
                        <button
                            className="p-2 text-white bg-primary rounded-circle"
                            onClick={addCompanyDetailsModalShow}
                        >
                            <Plus size={28}/>
                        </button>
                    </CardTitle>

                </CardHeader>
                <CardBody>

                    {/*model for add company */}
                    <Modal show={addCompanyModalshow} onHide={addCompanyDetailsModalClose} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>{CompanyModalTitle}</Modal.Title>{"\n"}

                        </Modal.Header>
                        <Modal.Body>

                            <Container>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                className="AddCompany_input"
                                                bsSize="md"
                                                type="text"
                                                name="company-name"
                                                placeholder="Client Name"
                                                onChange={onChangeCompanyNameSignup}
                                                value={companyNameSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                className="AddCompany_input"
                                                bsSize="md"
                                                type="text"
                                                name="Address"
                                                placeholder="Address(Optional)" onChange={onChangeCompanyAddressSignup}
                                                value={companyAddressSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                className="AddCompany_input"
                                                bsSize="md"
                                                type="number"
                                                name="abn"
                                                placeholder="Client ABN" onChange={onChangeCompanyABV} value={companyABVSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                className="AddCompany_input"
                                                bsSize="md"
                                                type="text"
                                                name="state"
                                                placeholder="State(Optional)" onChange={onChangeCompanyStateSignup}
                                                value={companyStateSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                className="AddCompany_input"
                                                bsSize="md"
                                                type="text"
                                                name="Company Domain"
                                                placeholder="Domain(@domain.com)" onChange={onChangeCompanyDomainSignup}
                                                value={companyDomainSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <Input
                                                className="AddCompany_input"
                                                bsSize="md"
                                                type="text"
                                                name="zipcode"
                                                placeholder="PostCode(Optional)" onChange={onChangeCompanyZipSignup}
                                                value={companyZipSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <select className="custom-select"
                                                    style={{ background:'none',appearance:'auto',height: '2rem',padding:' 0rem 0.5rem',lineHeight: '0.5rem',borderRadius: '0.1rem',border: '1px solid #ced4da'}}
                                                    onChange={onChangeCompanyIndustryNameSignup}
                                                    value={companyIndustryNameSignup}
                                            >
                                                <option value="0">Industry</option>
                                                {industries &&
                                                industries.length > 0 &&
                                                industries.map(schema => {
                                                    return <option key={schema.id}
                                                                   value={schema.id}>{schema.name}</option>;
                                                })}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <select className="custom-select"
                                                    style={{ background:'none',appearance:'auto',height: '2rem',padding:' 0rem 0.5rem',lineHeight: '0.5rem',borderRadius: '0.1rem',border: '1px solid #ced4da'}}
                                                    value={companyCountrySignup}
                                                    onChange={onChangeCompanyCountrySignup}>

                                                <option value="0">Country(Optional)</option>
                                                {countries &&
                                                countries.length > 0 &&
                                                countries.map(schema => {
                                                    return <option key={schema.id}
                                                                   value={schema.id}>{schema.country_name}</option>;
                                                })}


                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <select className="custom-select"
                                                    style={{ background:'none',appearance:'auto',height: '2rem',padding:' 0rem 0.5rem',lineHeight: '0.5rem',borderRadius: '0.1rem',border: '1px solid #ced4da'}}
                                                    value={partnerId}
                                                    onChange={handlePartnerChange}
                                            >
                                                <option value="0">Partner</option>
                                                {partners &&
                                                partners.length > 0 &&
                                                partners.map(schema => {
                                                    return <option key={schema.Id} value={schema.Id}>{schema.PartnerName}</option>;
                                                })}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <hr className="mx-auto"
                                     style={{display: 'block',width: '96%',margin:'0 0 1rem 0'}}
                                    />
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                        <Label style={{fontWeight: '600',color: '#000000',fontSize: '0.85rem'}}>Client Logo</Label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" onChange={onChangeLogoSignup}></input>
                                                <label className="custom-file-label" style={{overflow: 'hidden',lineHeight: '1.8rem',whiteSpace:'nowrap',textOverflow: 'ellipsis',paddingRight: '5rem'}}htmlFor="inputGroupFile04">{LogoSignup}</label>
                                            </div>   
                                        </div>
                                            {/* <Input
                                                bsSize="lg"
                                                type="file"
                                                name="logo"
                                                title="Kindly upload company logo"
                                                onChange={onChangeLogoSignup}
                                            /> */}
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                        <Label style={{fontWeight: '600',color: '#000000',fontSize: '0.85rem'}}>Client Additional Logo(Optional)</Label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile05" aria-describedby="inputGroupFileAddon05" onChange={onChangeCompanyAdditionalLogo}></input>
                                                <label className="custom-file-label" style={{overflow: 'hidden',lineHeight: '1.8rem',whiteSpace:'nowrap',textOverflow: 'ellipsis',paddingRight: '5rem'}}htmlFor="inputGroupFile05">{CompanyAdditionalLogotxt}</label>
                                            </div>   
                                        </div>
                                        </FormGroup>
                                    </Col>
                                    <hr className="mx-auto"
                                     style={{display: 'block',width: '96%',margin:'0 0 1rem 0'}}
                                    />
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                        <Label style={{fontWeight: '600',color: '#000000',fontSize: '0.85rem'}}>Landing Page Information</Label>
                                        <textarea className="form-control AddCompany_textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Banner Text(Optional)" value={banner_text} onChange={onChangeBannerText}></textarea>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                        {/* <Label style={{fontWeight: '600',color: '#000000'}}>Landing Page Information</Label> */}
                                            <Input
                                                className="AddCompany_input"
                                                bsSize="md"
                                                type="text"
                                                name="Section_1_title"
                                                style={{marginTop:"2rem"}}
                                                placeholder="Section 1 title(Optional)" onChange={onChangeSection1Title}
                                                value={section_1_title}
                                            />   
                                            <Input
                                                className="AddCompany_input"
                                                bsSize="md"
                                                type="text"
                                                name="Body_end_text"
                                                style={{margin:"1rem 0"}}
                                                placeholder="Body end text(Optional)" onChange={onChangeBodyendtext}
                                                value={body_end_text}
                                            />                                   
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                        <textarea className="form-control AddCompany_textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Section 1 body(Optional)" value={section_1_body} onChange={onChangeSection1body}></textarea>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                        <Label style={{fontWeight: '600',color: '#000000',fontSize: '0.85rem'}}>Banner Image(Optional)</Label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile06" aria-describedby="inputGroupFileAddon06" onChange={onChangeBannerImage}></input>
                                                <label className="custom-file-label" style={{overflow: 'hidden',lineHeight: '1.8rem',whiteSpace:'nowrap',textOverflow: 'ellipsis',paddingRight: '5rem'}}htmlFor="inputGroupFile06">{BannerImageTxt}</label>
                                            </div>   
                                        </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <Input
                                                className="AddCompany_input"
                                                bsSize="lg"
                                                type="text"
                                                name="colour1"
                                                placeholder="Primary colour" onChange={onChangeColour1}
                                                value={colour1}
                                                style={{backgroundColor:'#fff'}}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <Input
                                               className="AddCompany_input"
                                               bsSize="lg"
                                               type="text"
                                               name="colour1"
                                               placeholder="Secondary colour(Optional)" onChange={onChangeColour2}
                                               value={colour2}
                                               style={{backgroundColor:'#fff'}}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>

                        </Modal.Body>
                        <Modal.Footer style={{flexWrap: 'nowrap'}}>
                            {companyAddMessage && <Alert variant="danger">{companyAddMessage}</Alert>}
                            <Button variant="light" onClick={addCompanyDetailsModalClose} style={{width:"auto",flex:'none'}}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={addCompanyDetails} style={{width:"auto",flex:'none'}}>
                                {CompanyModalButtonTitle}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/*model for add partner */}
                    <Modal show={addUserModalShow} onHide={addUserDetailsModalClose} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Add User for Client</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Container>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="first-name"
                                                placeholder="Enter your first name"
                                                value={firstNameSignup}
                                                onChange={onChangeFirstName}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="last-name"
                                                placeholder="Enter your last name"
                                                value={lastNameSignup}
                                                onChange={onChangeLastName}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                value={emailSignup}
                                                onChange={onChangeEmailSignup}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                        <Popover
                                            content={
                                                <div>
                                                    <p>{PasspasswordRule1? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}At least 8 characters long.</p>
                                                    <p>{PasspasswordRule2? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one uppercase letter.</p>
                                                    <p>{PasspasswordRule3? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one lowercase letter.</p>
                                                    <p>{PasspasswordRule4? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one number.</p>
                                                </div>}
                                            placement="right"
                                            title={
                                                <span>
                                                    Password requirements:
                                                </span>}
                                            trigger="focus">
                                                <Input
                                                    bsSize="lg"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter your password"
                                                    value={passwordSignup}
                                                    onChange={onChangeUserPasswordSignup}
                                                    onFocus={onChangeUserPasswordSignup}
                                                />
                                            </Popover>                                 
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            Please select the job role :
                                            <select className="custom-select"
                                                    value={currentJobRole}
                                                    onChange={onChangeJobRole}
                                            >
                                                <option value="0">Select JobRole</option>
                                                {jobRoles &&
                                                jobRoles.length > 0 &&
                                                jobRoles.map(schema => {
                                                    return <option key={schema.id}
                                                                   value={schema.id}>{schema.name}</option>;
                                                })}


                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        Please select User Role
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <select className="custom-select" value={userUpdatedRole}
                                                    onChange={handleUserRoleChange}>
                                                <option value="0">Select role</option>
                                                {
                                                    filteredUser &&
                                                    filteredUser.length > 0 &&
                                                    filteredUser.map(schema => {
                                                        return <option key={schema.id}
                                                                       value={schema.id}>{schema.value}</option>;
                                                    })}


                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>

                        </Modal.Body>
                        <Modal.Footer>
                            {messageAddUser && <Alert variant="danger">{messageAddUser}</Alert>}
                            <Button variant="primary" onClick={btnClick_addUser}>
                                Add
                            </Button>
                            <Button variant="secondary" onClick={addUserDetailsModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/*model for add credits company */}
                    <Modal show={addCreditCompanyModalshow} onHide={addCreditCompanyDetailsModalClose}
                           backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>{CompanyModalTitle}</Modal.Title>{"\n"}

                        </Modal.Header>
                        <Modal.Body>

                            <Container>
                                <Row>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="company-credits"
                                                placeholder="Enter company credits"
                                                onChange={onChangeCompanyAddCreditsSignup}
                                                value={companyAddCreditsSignUp}
                                            />
                                        </FormGroup>
                                    </Col>


                                </Row>
                            </Container>

                        </Modal.Body>
                        <Modal.Footer>
                            {companyAddCreditMessage && <Alert variant="danger">{companyAddCreditMessage}</Alert>}

                            <Button variant="primary" onClick={addCreditDetailsOfCompany}>
                                {CompanyModalButtonTitle}
                            </Button>
                            <Button variant="secondary" onClick={addCreditCompanyDetailsModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/*model for add credit company */}


                    {companyMessage && <Alert variant="success" style={{"width": "400px"}}>{companyMessage}</Alert>}
                    <div className='scrollable scrollbar1'>
                        <Table striped hover>
                            <thead>
                            <tr>
                                <th className="text-center">SELECT</th>
                                <th className="text-center">LOGO</th>
                                <th className="text-center">CLIENT ID</th>
                                <th className="text-center">CLIENT NAME</th>
                                <th className="text-center">LANDING PAGE URL</th>
                                <th className="text-center">LOGIN PAGE URL</th>   
                                <th className="text-center">COMING SOON PAGE</th>   
                                <th className="text-center">ADD USER</th>
                                <th className="text-center">EDIT</th>
                                <th className="text-center">REMOVE</th>
                                {currentUser.roleName === "partner" ?
                                    <th className="text-center">ADD CREDITS</th>
                                    : <th></th>}
                            </tr>
                            </thead>
                            <tbody>

                            {companies.slice(indexOfFirstPost, indexOfLastPost).map(cmp => {
                                return <tr key={cmp.Id}>
                                    <td className="text-center">
                                        <label className="checkbox-container">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        {cmp.LogoPath !=="" &&
                                            <img
                                                src={cmp.LogoPath}
                                                style={{width:"120px",maxHeight:'50px'}}
                                                className="mr-2"
                                                alt="Avatar"
                                            />
                                        }

                                    </td>
                                    <td className="text-center">{cmp.Id}</td>
                                
                                    <td className="text-center">{cmp.CompanyName}</td>
                                    <td className="text-center theme_color1" style={{cursor:'pointer'}} onClick={(e)=>{copyHost(e)}} >{cmp.CompanyPageUrl}</td>
                                    <td className="text-center theme_color1" style={{cursor:'pointer'}} onClick={(e)=>{copyHost(e)}}>{cmp.CompanyPageUrl + '/login'}</td>
                                    <td className="text-center">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id={`customSwitch${cmp.Id}`}
                                                checked={cmp.comingSoon} onChange={() => 
                                                ChangeComingSoon(cmp.Id,!cmp.comingSoon)
                                                }></input>
                                            <label className="custom-control-label" htmlFor={`customSwitch${cmp.Id}`}></label>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            className="btn d-block mx-auto"
                                            onClick={() => addUserModal(cmp.Id, cmp.Partner_Id)}
                                        >
                                            <img src={iconAddUser} alt=""/>
                                        </button>
                                    </td>
                                    <td  className="text-center">
                                        <button
                                            className="btn d-block mx-auto"
                                            onClick={() => editCompanyModal(cmp.Id,cmp.CompanyName)}
                                        >
                                            <img src={iconEdit} alt=""/>
                                        </button>
                                    </td>
                                    <td  className="text-center">
                                        <button
                                            className="btn d-block mx-auto"
                                            onClick={() => showDeleteModal("company", cmp.Id)}
                                        >
                                            <img src={iconDelete} alt=""/>
                                        </button>
                                    </td>

                                    {currentUser.roleName === "partner" ?
                                        <td className="text-center">
                                            <button className="btn btn-primary"
                                                    onClick={() => addCreditCompanyModal(cmp.Id)}>Add Credits
                                            </button>
                                        </td>
                                        : <td></td>}
                                </tr>;
                            })}
                            </tbody>

                        </Table>
                    </div>
                    
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={companies.length}
                        paginate={paginate}
                    />

                    {/*Table for partner list*/}
                </CardBody>
                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete}
                                    hideModal={hideConfirmationModal} type={type} id={id} message={deleteMessage}/>
            </Card>

        );


    } else {
        return (<Card></Card>)
    }
}


const Tables = (props) => (
    <div id="company_div">
        <Container fluid className="p-0">
            <Row className="mb-2 mb-xl-4">

                <Col xs="auto" className="ml-auto text-right mt-n1">

                    {/* 暂时隐藏（设置和过滤按钮）
                    <Button color="primary" className="shadow-sm mr-1">
                        <Filter className="feather"/>
                    </Button>
                    <Button color="primary" className="shadow-sm">
                        <Settings className="feather"/>
                    </Button> */}

                </Col>
            </Row>


            <CompaniesList  {...props} />

        </Container>
    </div>
);

export default Tables;
