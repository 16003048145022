import React, { useState, useEffect, useRef } from "react";

// Services
import AuthService from "../../../services/auth.service.js";
import assessmentService from "../../../services/assessment.service.js";

// Components
import { Button, Modal } from 'react-bootstrap';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import { setInterval } from "timers";

// Assets
import Watch from "../../../assets/img/icons/watch.png";

/*
* Assessment Answer Review view
*
* This view is displays the questions answered within an assessment
* & allows the user to change any of the answers before submitting
* the assessment for results
*
* TODO: combine with the non time based review screen
* */

const QuizView = (props) => {

    sessionStorage.removeItem("ReSubmitQuestionKey");
    sessionStorage.removeItem("AssessmentQuizKey");

    const [show, setShow] = useState(false);
    const [showTimesUp, setShowTimesUp] = useState(false);
    const [showLoading, setshowLoading] = useState(false);

    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        setShow(false);
        setShowTimesUp(false);
        props.history.push("/");
        window.location.reload();
    }
    var userId = currentUser.id;
    if (props.location.state == null || props.location.state == undefined) {

        setShow(false);
        setShowTimesUp(false);
        props.history.push({
            pathname: '/Assessments'
        })
        window.location.reload();
    }

    if (props.history.action == "POP") {
        // var isKeyPresent = (JSON.parse(sessionStorage.getItem("ReviewAssessmentKey")));
        // if (isKeyPresent == null) {
        //     props.history.push({
        //         pathname: '/Assessments'
        //     })
        //     window.location.reload();
        // }
        props.history.push({
            pathname: '/Assessments'
        })
        window.location.reload();
    }

    //set questionObject from props
    var questions = props.location.state.questionsList;
    var assessmentId = props.location.state.assessmentId;
    var creditId = props.location.state.creditId;
    let isOwnCaseStudy = JSON.parse(sessionStorage.getItem("isOwnCaseStudy")) ? true : false;
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const TitleList = questions;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [seconds, setSeconds] = useState(null);
    const [minus, setMinus] = useState(null);

    const intervalRef = useRef();

    //let  = 0;
    var localTimeReview =sessionStorage.getItem("hourAssessment")!=='nontimer' ? Number(JSON.parse(sessionStorage.getItem("hourAssessment"))):'nontimer';
    const ReviewQuestions = (count, question, optionList, assessmentId, selectedAnswer, assessmentName, title) => {

        //once the person click on change answer then it is redirected to other page for changing the answer.
        var time = sessionStorage.getItem("hourAssessment")!=='nontimer' ? Number(JSON.parse(sessionStorage.getItem("hourAssessment"))):'nontimer';
        sessionStorage.setItem("hourAssessment", time);
        sessionStorage.setItem("ReSubmitQuestionOption", "true");
        if(time =='nontimer'){
            sessionStorage.setItem("assessmentType", "nontimer");
        }
        clearInterval(intervalRef.current);
        sessionStorage.setItem("ReSubmitQuestionKey", "false");
        sessionStorage.removeItem("AssessmentQuizKey");
        sessionStorage.removeItem("ReviewAssessmentKey");
        props.history.push({
            pathname: '/ReSubmitQuestionOption',
            state: { currentCounter: count, optionList: optionList, question: question, assessmentId: assessmentId, selectedAnswer, AssessmentName: assessmentName, title: title, creditId: creditId,questions:questions,AssessmentsDetails:props.location.state.AssessmentsDetails }

        })
    }
    const handleFinishButton = () => {
        setShow(true);
    }
    const handleFinalSubmit = () => {
        setshowLoading(true);
        setShow(false);
        sessionStorage.removeItem("hourAssessment");
        sessionStorage.removeItem('questionCounter');
        sessionStorage.removeItem('state');
        sessionStorage.removeItem('Title');
        sessionStorage.removeItem('AssessmentName');
        sessionStorage.removeItem('currentQuestionIndex');
        sessionStorage.removeItem('currentTitleIndex');
        sessionStorage.removeItem('checkedRadio');
        assessmentService.UpdateUserTestTableNew(userId, assessmentId, creditId,isOwnCaseStudy).then(
            (response) => {
                if (response !== null) {
                    if(response.ShowResult == 1){
                            setshowLoading(false);
                            if(currentUser.roleName == "team-member"||currentUser.roleName == "team-leader"){
                                if(currentUser.report_access == true){
                                    props.history.push({pathname: "/ResultDashboard"});
                                }else{
                                    props.history.push({pathname: "/Assessments"});
                                }
                            }else{
                                assessmentService.GetAllAssessmentListByCreditId(userId, assessmentId, creditId,isOwnCaseStudy).then((response) =>{
                                    if(response !== null) {
                                        console.log(response);
                                        props.history.push({
                                            pathname: '/AssessmentResult',
                                            state: {
                                                Score: response[0].Score,
                                                Data: response[0],
                                                questionsList: questions,
                                                AssessmentsDetails:props.location.state.AssessmentsDetails
                                            }
                                        })
                                    }
                                    
                                })
                                
                            }
                    }else{
                        if(response.LinkedAssessmentId !=0 && response.LinkedAssessmentCreditId!=0 ){
                            props.history.push({
                                pathname: '/Assessments/Completed',
                                state: { GoOnInfo: true, LinkedAssessmentId: response.LinkedAssessmentId, LinkedAssessmentCreditId: response.LinkedAssessmentCreditId,AssessmentName:TitleList.AssessmentName,AssessmentsDetails:props.location.state.AssessmentsDetails }
                            })
                        }else if(response.LinkedAssessmentId ==0 && response.LinkedAssessmentCreditId==0 ){
                            props.history.push({
                                pathname: '/Assessments/Completed',
                                state: { GoOnInfo: false, LinkedAssessmentId: null, LinkedAssessmentCreditId: null,AssessmentsDetails:props.location.state.AssessmentsDetails}
                            })
                        }else{
                            props.history.push({pathname: "/Assessments"});
                        }
                    }
               
                }
            });
    }

    useEffect(() => {
        //set the values after taking from the session/local storage
        assessmentService.GetCurrentAssessmentSessionValues(userId, assessmentId, creditId).then(
            (response) => {
                if (response !== null) {
                    var timeForStorage = sessionStorage.getItem("hourAssessment")!=='nontimer' ? Number(JSON.parse(sessionStorage.getItem("hourAssessment"))):'nontimer';
                    if (timeForStorage != 0 && timeForStorage > 0) {
                        setSeconds(secondsToMinutesFormatting(timeForStorage));
                        localTimeReview = timeForStorage;
                    }else if(timeForStorage == 'nontimer'){
                        setSeconds(secondsToMinutesFormatting(0));
                        localTimeReview = 0;
                    }else {
                        setSeconds(secondsToMinutesFormatting(timeForStorage));
                        localTimeReview = timeForStorage;
                        sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReview));
                    }
                }
            });

    }, []);
    const secondsToMinutesFormatting = (secondsVal) => {
        if (secondsVal < 0) {
            setMinus("-")
            secondsVal = Math.abs(secondsVal);
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v,i) => (v < 10 ? "0" + v : v))
                .filter((v, i) =>  v !== "00" || i > 0)
                .join(":");

        }else{
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v) => (v < 10 ? "0" + v : v))
                .filter((v, i) => v !== "00" || i > 0)
                .join(":");
        }
    }

    useEffect(() => {
        // alert("in use effect");
        const startTimerAgain = () => {
            if (window.location.href.indexOf("ReviewAssessment") > -1 &&sessionStorage.getItem("hourAssessment")!=='nontimer') {
                // if (localTimeReview > 0 && localTimeReview != undefined) {
                    //var test = localTimeReview;
                    //test = localTimeReview - 1;
                    localTimeReview = localTimeReview - 1;
                    setSeconds(secondsToMinutesFormatting(localTimeReview));
                    sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReview));
                // }
                // else {
                //     setSeconds(secondsToMinutesFormatting(0));
                //     localTimeReview = 0;
                //     sessionStorage.removeItem("hourAssessment");
                // }

                if (localTimeReview % 5 == 0 && window.location.href.indexOf("ReviewAssessment") > -1) {
                    assessmentService.SetPendingTimeForTimer(userId, assessmentId, creditId).then(
                        (response) => {
                            if (response !== null && response != 0) {

                                // var timeForStorage = Number(JSON.parse(sessionStorage.getItem("hourAssessment")));
                                // if (timeForStorage != 0 && timeForStorage > 0) {
                                //     setSeconds(secondsToMinutesFormatting(timeForStorage));
                                //     localTimeReview = timeForStorage;
                                //     sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReview));
                                // } else {
                                    setSeconds(secondsToMinutesFormatting(response));
                                    localTimeReview = response;
                                    sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReview));

                                // }

                            }
                            else {
                                assessmentService.UpdateUserTestTableNew(userId, assessmentId, creditId,isOwnCaseStudy).then(
                                    (response) => {
                                        if (response !== null) {
                                            setSeconds(secondsToMinutesFormatting(0));
                                            sessionStorage.removeItem("hourAssessment");
                                            localTimeReview = 0;
                                            // clearInterval(interval.current);
                                            setShowTimesUp(true);
                                            setTimeout(function () {
                                                setSeconds(secondsToMinutesFormatting(0));
                                                sessionStorage.removeItem("hourAssessment");
                                                localTimeReview = 0;
                                                setShowTimesUp(false);
                                                props.history.push({
                                                    pathname: '/Assessments'
                                                })
                                                window.location.reload();
                                            }, 2000);
                                        }
                                    });
                            }
                        });

                }
            }
        }
        if (window.Worker) {
            var worker = new Worker('/timerWorker.js');
            // 设置接收到/timerWorker.js消息时的执行函数;
            worker.onmessage = () =>{
                startTimerAgain()
            };
            // 发送start类型消息
            worker.postMessage({ type: 'start', interval: 1000 });
          }else{
              // 考虑添加退回方案
              alert('浏览器不支持');
          }
        return () => {
            // clear all interval
            if (worker) {
                worker.terminate();
            }
        }
    }, []);
    var count = 0;

    return (
        <Row>
             {showLoading && (<div className="loadingBG">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>)}
            <Col md="12">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">

                            <Card className="my-card">
                                <CardBody>
                                    <Row>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="float-left">
                                                <h2 className="mb-4">Review Assessment</h2>
                                                <div className="">
                                                    <div className="text-left">
                                                        {TitleList.TitleList.map((x,index) => (
                                                            <div key={index}>

                                                                <h5 className="right-sidebar-h5 text-primary">Topic: {x.TitleName}</h5>
                                                                <hr className="border-secondary" />
                                                                {x.QuestionList.map((p, i) => (
                                                                    <Card className="p-3" key={i}>
                                                                        {i !== 0 && <hr className="my-hr mt-0" />}
                                                                        <h5 className="quiz-question-h5 textDisable">Question {count += 1}: {p.Questions}</h5>
                                                                        <p className="quiz-question-h5 textDisable">
                                                                            <span className="text-success font-weight-bold d-block">
                                                                                Selected Answer:
                                                                            </span>
                                                                            {p.OptionsWithScore.filter(u => u.OptionsId == p.SelectedAnswer)[0].Options}
                                                                        </p>


                                                                        <button id="review-button"
                                                                            className="quiz-submit-btn btn-pill reviewButton btn-primary col-3"
                                                                            count={count}
                                                                            onClick={(e) => {e.preventDefault();ReviewQuestions(e.currentTarget.getAttribute("count"), p.Questions, p.OptionsWithScore, TitleList.AssessmentId, p.SelectedAnswer, TitleList.AssessmentName, x.TitleName)}}>
                                                                            Change Answer
                                                                        </button>

                                                                    </Card>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <Card className="my-card">
                                {sessionStorage.getItem("hourAssessment")!=='nontimer'&&  
                                <CardHeader className="pb-0">
                                    <Row>
                                        <div className="col-lg-12 col-md-12">
                                            <Row>
                                                <Col sm={4} md={4}>
                                                    <img
                                                        src={Watch}
                                                        className="img-responsive mt-2 mx-auto d-block"
                                                        width="80"
                                                        height="80"
                                                    />
                                                </Col>
                                                <Col sm={8} md={8}>
                                                    <div>
                                                        <p className="mb-0">Your time left</p>
                                                        <h3 className="display-4 font-weight-bold text-dark" style={{fontSize:'2.6rem'}}>
                                                            {minus}{seconds}
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </CardHeader>}
                              
                                <button id="submit-button" className=" btn btn-pill my-3 btn-success" style={{ marginLeft: "20%", marginRight: "20%" }} onClick={handleFinalSubmit}>Submit Assessment</button>
                            </Card>
                        </div>
                    </div>
                </div>
            </Col>


        </Row>

    );
}

const AssessmentQuiz = (props) => (
    <>
        <QuizView {...props} />
    </>
);



export default AssessmentQuiz;
