import React, {useState, useEffect} from "react";

// Services
import userService from "../../../services/user.service.js";
import AuthService from "../../../services/auth.service.js";
import CompanyService from "../../../services/companies.service";
import PartnerService from "../../../services/partners.service.js";
import TeamsService from "../../../services/teams.service.js";
import Select from 'react-select'
// Components
import {
    Card,
    CardHeader,
    CardTitle,
    Container,
    Col,
    Row,
    Table,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import validator from "validator";
import DeleteConfirmation from "../../../../src/components/DeleteConfirmation";
import {Button, Modal, Alert} from "react-bootstrap";
import { Pagination } from 'antd';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Calendar,
    Filter,
    RefreshCw,
    Settings,
    Plus,
    X,
    Search,
    Check
} from "react-feather";
import {
    faCircle,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortNumericDown,
    faSortNumericUp,
    faSort,
    faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import iconChangeRole from "../../../assets/img/icons/change-role.svg";
import iconDeleteUser from "../../../assets/img/icons/delete.svg";
import iconEmail from "../../../assets/img/icons/email.png";
import icondownload from "../../../assets/img/icons/download.png";
import iconExport from "../../../assets/img/icons/export.png"
import { Popover } from 'antd';


// Assets
import imgDefaultAvatar from '../../../assets/img/avatars/avatar.png'
import sort from "../../../assets/img/icons/sort.png"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

{
    /*...................................................User list operations code part..............................................................*/
}
/*
* User List View
*
* The following view displays the list
* of users registered under the platform
*
* The Users displayed will depend on the
* user role. (Eg: Company Manager, Partner, Super Admin)
*
* */
const SgaUsers = (props) => {
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];
    const filterOptions = (
        candidate,
        input
      ) => {
        if (input) {
          return candidate.label.indexOf(input)!=-1;
        }
        return true;
      };
    const userRoleList = [
        {
            id: 1,
            value: "user",
        },
        {
            id: 2,
            value: "team-member",
        },
        {
            id: 3,
            value: "team-leader",
        },
        {
            id: 4,
            value: "company",
        },
        {
            id: 5,
            value: "AHRI Administrator",
        },
    ];

    var currentUser = AuthService.getCurrentUser();
    var currentCompany = AuthService.getCurrentCompany();
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    if (
        currentUser.roleName === "user" ||
        // currentUser.roleName === "team-leader" ||
        currentUser.roleName === "team-member"
    ) {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    const [optionsData,setOptionsData] = useState(options);
    const [filteredUser, setFilteredUser] = useState([]);
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [filteredCompany, setfilteredCompany] = useState([]);
    const [filteredTeam, setfilteredTeam] = useState([]);
    const [id, setId] = useState(null);
    const [type, setType] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] =
        useState(false);
    const [userMessage, setMessage] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [jobRoles, setJobRoles] = useState("");
    const [currentJobRole, setCurrentJobRole] = useState(0);
    const [partners, setPartners] = useState("");
    const [disabledCompany, setDisabledCompany] = useState(true);
    const [disabledTeam, setDisabledTeam] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const [currentPosts, setCurrentPosts] = useState(1);
    const [teamSignUp, setTeamUserSignup] = useState(0);
    const [iscompanyLogin, setIscompanyLogin] = useState(false);
    const [totalUsers, setTotalUsers] = useState([]);
    const [chooseUser, setChooseUser] = useState([]);
    const [specialistNames, setSpecialistNames] = useState([]);
    const [specialistNamesdata, setSpecialistNamesdata] = useState([]);
    //password rule
    const [PasspasswordRule1, setPasspasswordRule1] = useState(false);
    const [PasspasswordRule2, setPasspasswordRule2] = useState(false);
    const [PasspasswordRule3, setPasspasswordRule3] = useState(false);
    const [PasspasswordRule4, setPasspasswordRule4] = useState(false);
        
    

    // Sorting useState
    const [arrowPositionFirstName, setArrowPositionFirstName] =
        useState(faSort);
    const [arrowPositionLastName, setArrowPositionLastName] =
        useState(faSort);
    const [arrowPositionRegisterationDate, setArrowPositionRegisterationDate] =
        useState(faSort);
    const [arrowPositionCompany, setArrowPositionCompany] =
        useState(faSort);
    const [arrowPositionPartner, setArrowPositionPartner] =
        useState(faSort);
    const [arrowPositionRole, setArrowPositionRole] = useState(faSort);
    const [arrowPositionJobRole, setArrowPositionJobRole] =
        useState(faSort);
    const [arrowPositionCountry, setArrowPositionCountry] =
        useState(faSort);
    const [arrowPositionState, setArrowPositionState] = useState(faSort);
    const [arrowPositionForPosition, setArrowPositionForPosition] =
        useState(faSort);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //componentDidMount
    useEffect(() => {
        if (teamSignUp === 0 && currentUser.roleName === "company") {
            setFilteredUser(userRoleList.filter((x) => x.id !== 2 && x.id !== 3));
        }

        // Loading Users
        const  userData = () => {
            var userDataList = userService.GetUserList();

            var details = userDataList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    let datas = []
                    if (currentUser.roleName === "partner") {
                        setUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))));
                        setFilteredUser(userRoleList.filter(x => x.id === 2 || x.id === 1 || x.id === 3|| x.id === 4 ));
                        setState({data: data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id)))});
                        setTotalUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))));
                        data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))).forEach(user => {
                            user.specialist.forEach(x => {
                                datas.push(x)
                            })
                        });
                        setSpecialistNamesdata(datas);
                    } else if (currentUser.roleName === "company") {

                        setIscompanyLogin(true);
                        setUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader")));
                        setFilteredUser(userRoleList.filter(x => x.id === 3|| x.id === 2));
                        setState({data: data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader"))});
                        setTotalUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member" ||x.roleName === "team-leader")));
                        data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member" ||x.roleName === "team-leader")).forEach(user => {
                            user.specialist.forEach(x => {
                                datas.push(x)
                            })
                        });
                        setSpecialistNamesdata(datas);
                    } else if(currentUser.roleName === "team-leader"){
                        setUsers(data.listOfUsers.filter(x => x.team_id === currentUser.team_id));
                        setFilteredUser(userRoleList.filter(x => x.id === 1));
                        setState({data: data.listOfUsers.filter(x => x.team_id === currentUser.team_id)});
                        setTotalUsers(data.listOfUsers.filter(x => x.team_id === currentUser.team_id));
                        data.listOfUsers.filter(x => x.team_id === currentUser.team_id).forEach(user => {
                            user.specialist.forEach(x => {
                                datas.push(x)
                            })
                        })
                        setSpecialistNamesdata(datas);
                    } else {
                        setUsers(data.listOfUsers);
                        setFilteredUser(userRoleList.filter(x => x.id === 1));
                        setState({data: data.listOfUsers});
                        setTotalUsers(data.listOfUsers);
                        data.listOfUsers.forEach(user => {
                            user.specialist.forEach(x => {
                                datas.push(x)
                            })
                        })
                        setSpecialistNamesdata(datas);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        // GET specialistNames Data
        const specialistNamesData = () =>{
            const dropdownCompany = userService.GetSpecialist().then(
                (response1) => {

                    if (response1 !== null) {
                        // let SpecialistNamesdata = [];
                        // totalUsers.forEach(user => {
                        //     user.specialist.forEach(x => {
                        //         if(!SpecialistNamesdata.includes(x)){
                        //             SpecialistNamesdata.push(x);
                        //         }
                        //     })
                        // })
                        // response1.forEach(x => {
                        //     totalUsers.forEach(j =>{
                        //         if(j.specialist.includes(Number(x.id))){
                        //             if(!SpecialistNamesdata.includes(x.id)){
                        //                 SpecialistNamesdata.push(x);
                        //             }
                        //         }
                        //     })
                        // })

                        setSpecialistNames(response1)
                    }

                }
            );
        };

        // Loading Teams dropdown list
        const teamListData = () => {
            var teamListValues = TeamsService.GetTeamList();

            var details = teamListValues
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    setTeamList(data.teamDetails);
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        // Loading Partners dropdown data
        const partnerData = () => {
            var partnerListData = PartnerService.GetPartnerList();
            var details = partnerListData
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    if (
                        currentUser.roleName === "partner" ||
                        currentUser.roleName === "company" ||
                        currentUser.roleName === "team-leader"
                    ) {
                        setPartners(
                            data.partnerDetails.filter((x) => x.Id === currentUser.partner_id)
                        );
                    } else {
                        setPartners(data.partnerDetails);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        // Loading Job Role list
        const jobRolesData = () => {
            userService.GetJobRoles().then((response) => {
                if (response !== null) {
                    setJobRoles(response);
                }
            });
        };
        // Loading company list
        const companyData = () => {
            var companyListData = CompanyService.GetCompanyList();

            var details = companyListData
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    if (currentUser.roleName === "company" || currentUser.roleName === "team-leader") {
                        setCompanies(
                            data.companyDetails.filter((x) => x.Id === currentUser.company_id)
                        );
                    } else {
                        setCompanies(data.companyDetails);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        userService.GetJobRoles().then(
            (response) => {
                if (response !== null) {
                    let JobOptionData = []
                    response.map((job) => {
                        JobOptionData.push({
                            value: job.id , label: job.name
                        });
                    });
                    setOptionsData(JobOptionData);
                }
            }
        );
        teamListData();
        companyData();
        partnerData();
        specialistNamesData();
        jobRolesData();
        userData();
    }, []);

    //Import model
    const [importModel, setImportModel] = useState(false);
    const importToggle = () => {setImportModel(!importModel)};
    const [uploadName, setUploadName] = useState("Choose file");
    const [uploadContent, setUploadContent] = useState("");
    //Export model
    const [exportModel, setExportModel] = useState(false);
    const exportToggle = () => {setExportModel(!exportModel)};
    const [exportStatus, setExportStatus] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');
    //add user model
    const [addUserModalShow, setAddUserModalshow] = useState(false);
    const [userCurrentRole, setUserCurrentRole] = useState(false);
    const [userCurrentRoleId, setUserCurrentRoleId] = useState(false);
    const [userUpdatedRole, setUserUpdatedRole] = useState(1);
    const [userId, setUserId] = useState(false);
    const [passwordSignup, setPasswordSignup] = useState("");
    const [confirmPasswordSignup, setConfirmPasswordSignup] = useState("");
    const [emailSignup, setEmailSignup] = useState("");
    const [phoneSignup, setPhoneSignup] = useState("");
    const [firstNameSignup, setFirstNameSignup] = useState("");
    const [lastNameSignup, setLastNameSignup] = useState("");
    const [currentCompanyAddUser, setCurrentCompanyAddUser] = useState(0);
    const [partnerId, setPartnerId] = useState("0");
    const [jobTitleSignup, setJobTitleSignup] = useState("");
    const [ProgramNameSignup, setProgramNameSignup] = useState("");
    const [roleChangeMessage, setRoleChangeMessage] = useState(null);
    const [messageAddUser, setMessageAddUser] = useState(null);
    const [state, setState] = useState({
        data: [],
        sort: {
            column: null,
            direction: "desc",
        },
    });

    const addUserModalClose = () => setAddUserModalshow(false);

    const addUserModal = () => {
        if (companies.length > 0) {
            setCurrentCompanyAddUser(companies[0].Id);
        } else {
            setCurrentCompanyAddUser("");
        }
        setFirstNameSignup("");
        setLastNameSignup("");
        setPasswordSignup("");
        setConfirmPasswordSignup("");
        setEmailSignup("");
        setPhoneSignup("");
        setCurrentJobRole(0);
        setPartnerId(0);
        setCurrentCompanyAddUser(0);
        setTeamUserSignup(0);
        setUserUpdatedRole("0");
        setJobTitleSignup("");
        setProgramNameSignup('')
        setAddUserModalshow(true);
    };
    const handlePartnerChange = (e) => {
        setDisabledCompany(false);
        if (e.target.value === "0") {
            setDisabledCompany(true);
            setCurrentCompanyAddUser(0);
            setDisabledTeam(true);
            setTeamUserSignup(0)
            setFilteredUser(userRoleList);
        } else {
            if(currentCompanyAddUser == 0 && teamSignUp == 0){
                setFilteredUser(
                    userRoleList.filter((x) => x.id === 1 || x.id === 5)
                );
            }
        }
        var filteredCompany = companies.filter(
            (x) => x.Partner_Id == e.target.value
        );
        setfilteredCompany(filteredCompany);
        if(filteredCompany.length === 1){
            setCurrentCompanyAddUser(filteredCompany[0].Id);
            setFilteredUser(
                userRoleList.filter((x) => x.id === 2 || x.id === 4)
            );
            var filteredteam = teamList.filter((x) => x.CompanyId == filteredCompany[0].Id);
            setfilteredTeam(filteredteam);
            setDisabledTeam(false);
            if (filteredteam.length === 0) {
                setDisabledTeam(true);
            } else {
                if(filteredteam.length ==1){
                    setTeamUserSignup(filteredteam[0].Id);
                    setFilteredUser(userRoleList.filter((x) => x.id === 2 || x.id === 3))
                }
                setDisabledTeam(false);
            }
        }else{
            setCurrentCompanyAddUser(0);
        }
        if (filteredCompany.length === 0) {
            setDisabledCompany(true);
            setDisabledTeam(true);
        }
        
        setPartnerId(e.target.value);
    };
    const handleLinkClk = (id, fName, lName) => {
        console.log("TeamId===" + id);
        props.history.push({
            pathname: "/userProfile",
            state: {data: id, firstName: fName, lastName: lName},
        });
    };
    const [addUserRoleChangeModalShow, setAddUserRoleChangeModalShow] =
        useState(false);
    const addUserRoleChangeModalClose = () =>
        setAddUserRoleChangeModalShow(false);
    const addUserRoleChangeModal = () => setAddUserRoleChangeModalShow(true);

    //add user model
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };
    const onChangeConfirmPasswordSignup = (e) => {
        let passwordRule1 = /.{8,}/;
        let passwordRule2 = /[A-Z]/;
        let passwordRule3 = /[a-z]/;
        let passwordRule4 = /\d/;
        const password = e.target.value;
        if(passwordRule1.test(password)){
            setPasspasswordRule1(true)
        }else{
            setPasspasswordRule1(false)
        }
        if(passwordRule2.test(password)){
            setPasspasswordRule2(true)
        }else{
            setPasspasswordRule2(false)
        }
        if(passwordRule3.test(password)){
            setPasspasswordRule3(true)
        }else{
            setPasspasswordRule3(false)
        }
        if(passwordRule4.test(password)){
            setPasspasswordRule4(true)
        }else{
            setPasspasswordRule4(false)
        }
        setConfirmPasswordSignup(password);
    };
    const addUserDetailsModalClose = () => {
        setAddUserModalshow(false);
        setMessageAddUser("");
        setCurrentCompanyAddUser(0);
        setTeamUserSignup(0);
        setPartnerId(0);
        setDisabledTeam(true);
        setDisabledCompany(true);
    };
    const onChangePhoneSignup = (e) => {
        const phone = e.target.value;
        setPhoneSignup(phone);
    };

    const validatePhone = (e) => {
        var phoneValidate = e.target.value;
        setMessageAddUser("");
    };

    const onChangePasswordSignup = (e) => {
        let passwordRule1 = /.{8,}/;
        let passwordRule2 = /[A-Z]/;
        let passwordRule3 = /[a-z]/;
        let passwordRule4 = /\d/;
        const password = e.target.value;
        if(passwordRule1.test(password)){
            setPasspasswordRule1(true)
        }else{
            setPasspasswordRule1(false)
        }
        if(passwordRule2.test(password)){
            setPasspasswordRule2(true)
        }else{
            setPasspasswordRule2(false)
        }
        if(passwordRule3.test(password)){
            setPasspasswordRule3(true)
        }else{
            setPasspasswordRule3(false)
        }
        if(passwordRule4.test(password)){
            setPasspasswordRule4(true)
        }else{
            setPasspasswordRule4(false)
        }
        setPasswordSignup(password);
    };

    const onChangeFirstName = (e) => {
        const firstName = e.target.value;
        setFirstNameSignup(firstName);
    };

    const onChangeLastName = (e) => {
        const lastName = e.target.value;
        setLastNameSignup(lastName);
    };

    const onChangeEmailSignup = (e) => {
        const email = e.target.value;
        setEmailSignup(email);
    };
    const onChangeJobTitleSignup = (e) => {
        const jobTitle = e.target.value;
        setJobTitleSignup(jobTitle);
    };
    const onChangeProgramNameSignup = (e) => {
        const ProgramNameSignup = e.target.value;
        setProgramNameSignup(ProgramNameSignup);
    };
    
    const ChangeRoleModal = (id, roleName, roleId) => {
        setUserId(id);
        setRoleChangeMessage("");
        setMessage("");
        setUserCurrentRole(roleName);
        setUserCurrentRoleId(roleId);
        setAddUserRoleChangeModalShow(true);

        if (roleId == 3) {
            setFilteredUser(userRoleList.filter((x) => x.id === 2 ));
        } else if (roleId == 2) {
            setFilteredUser(userRoleList.filter((x) => x.id === 3));
        } else if (roleId == 4) {
            setFilteredUser(
                userRoleList.filter((x) => x.id === 2 || x.id == 3 || x.id == 4)
            );
        } else if (roleId == 5) {
            setFilteredUser(
                userRoleList.filter((x) => x.id === 1 || x.id == 5 || x.id == 4)
            );
        } else if(roleId ==1){
            setFilteredUser(userRoleList.filter((x) => x.id === 1 || x.id == 5));
        } else{
            setFilteredUser(userRoleList.filter((x) => x.id === 1));
        } 
    };
    const handleUserRoleChange = (e) => {
        setUserUpdatedRole(e.target.value);
    };
    const handleTeamSignUpChange = (e) => {
        if (e.target.value === "0") {
            setFilteredUser(
                userRoleList.filter((x) => x.id === 2 || x.id === 4)
            );
        } else {
            setFilteredUser(userRoleList.filter((x) => x.id === 2 || x.id === 3))
        }
        setTeamUserSignup(e.target.value);
        debugger
    };
    const onChangeJobRole = (e) => {
        var jobRole = e.target.value;
        setCurrentJobRole(jobRole);
    };

    const onChangeCompanyUserAdd = (e) => {
        var filteredteam = teamList.filter((x) => x.CompanyId == e.target.value);
        setfilteredTeam(filteredteam);
        if (e.target.value === "0") {
            // setFilteredUser(userRoleList.filter((x) => x.id !== 2 && x.id !== 3));
            // if (currentUser.roleName === "super-admin") {
            //     setFilteredUser(
            //         userRoleList.filter((x) => x.id === 1 || x.id === 4 || x.id === 5)
            //     );
            // }
            setDisabledTeam(true);
            setTeamUserSignup(0)
            setFilteredUser(
                userRoleList.filter((x) => x.id === 1 || x.id === 5)
            );
        } else {
            if(teamSignUp == 0){
                setFilteredUser(
                    userRoleList.filter((x) => x.id === 2 || x.id === 4)
                );
            }
            // if (currentUser.roleName === "company") {
            //     setFilteredUser(
            //         userRoleList.filter(
            //             (x) => x.id !== 1 && x.id !== 4 && x.id !== 5 && x.id !== 3
            //         )
            //     );
            // }
            // if (currentUser.roleName === "partner") {
            //     setFilteredUser(
            //         userRoleList.filter((x) => x.id === 2 || x.id === 1 || x.id === 4)
            //     );
            // }
            // if (currentUser.roleName === "super-admin") {
            //     setFilteredUser(
            //         userRoleList.filter((x) => x.id == 2 || x.id === 4 || x.id === 3)
            //     );
            // }
        }
        if (filteredteam.length === 0) {
            setDisabledTeam(true);
        } else {
            if(filteredteam.length ==1){
                setTeamUserSignup(filteredteam[0].Id);
                setFilteredUser(userRoleList.filter((x) => x.id === 2 || x.id === 3))
            }
            setDisabledTeam(false);
            //userRoleList = userRoleList;
        }
        setCurrentCompanyAddUser(e.target.value);
    };
    const showDeleteModal = (type, id) => {
        //setting type in case to make this reusable , here setting company , can be set to partners etc
        setType(type);
        //setting the id of the company
        setId(id);
        setDeleteMessage(
            `Are you sure you want to delete the user  '${
                users.find((x) => x.id === id).FirstName
            }'?`
        );
        setDisplayConfirmationModal(true);
    };
    const submitDelete = (type, id) => {
        if (type === "user") {
            var DeleteUserById =userService.DeleteUserById(id);
            var details = DeleteUserById.then((response) => {
                if(response.Message === "success"){
                    toast.success(`The User '${users.find((x) => x.id === id).FirstName}' was deleted successfully.`, {position: toast.POSITION.TOP_CENTER})
                    setMessage();
                    setState({data: users});
                    var userDataList = userService.GetUserList();

                    var details = userDataList
                        .then((response) => {
                            return response;
                        })
                        .then((data) => {
                            if (currentUser.roleName === "partner") {

                                setUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))));
                                setFilteredUser(userRoleList.filter(x => x.id === 2 || x.id === 1 ||x.id === 3||  x.id === 4));
                                setState({data: data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id)))});
                                setTotalUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))));

                            } else if (currentUser.roleName === "company") {

                                setIscompanyLogin(true);
                                setUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader")));
                                setFilteredUser(userRoleList.filter(x => x.id === 3|| x.id === 2));
                                setState({data: data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member")||x.roleName === "team-leader")});
                                setTotalUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member")||x.roleName === "team-leader"));


                            } else {
                                setUsers(data.listOfUsers);
                                setFilteredUser(userRoleList.filter(x => x.id === 1));
                                setState({data: data.listOfUsers});
                                setTotalUsers(data.listOfUsers);
                            }

                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }else{
                    toast.success(response.Message, {position: toast.POSITION.TOP_CENTER})
                }
            })
            // toast.success(`The User '${users.find((x) => x.id === id).FirstName}' was deleted successfully.`, {position: toast.POSITION.TOP_CENTER})
            // setMessage();
            // setUsers(users.filter((user) => user.id !== id));
            // setState({data: users});
        }
        setDisplayConfirmationModal(false);
    };
    const btnClick_addUser = () => {
        if (
            passwordSignup !== "" &&
            firstNameSignup !== "" &&
            lastNameSignup !== "" &&
            emailSignup !== "" &&
            confirmPasswordSignup !== "" &&
            jobTitleSignup !== "" &&
            /*phoneSignup !== "" &&*/ currentJobRole !==
            "0" /*&& currentCompanyAddUser !== "0"*/ /*&& teamSignUp != "0"*/ &&
            userUpdatedRole !== "0" 
            && partnerId != "0"
            
        ) {
            if(!PasspasswordRule1 || !PasspasswordRule2 || !PasspasswordRule3|| !PasspasswordRule4){
                toast.error('Password is too weak. You must use a stronger password to continue.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }else{
                userService
                .AddUserDetails(
                    firstNameSignup,
                    lastNameSignup,
                    emailSignup,
                    phoneSignup,
                    passwordSignup,
                    currentJobRole,
                    partnerId,
                    currentCompanyAddUser,
                    userUpdatedRole,
                    teamSignUp,
                    jobTitleSignup,
                    ProgramNameSignup,
                    true
                )
                .then((response) => {
                    if (response.data.Status === "Success") {
                        var userDataList = userService.GetUserList();

                        var details = userDataList
                            .then((response) => {
                                return response;
                            })
                            .then((data) => {
                                if (currentUser.roleName === "partner") {
                                    setUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))));
                                    setFilteredUser(userRoleList.filter(x => x.id === 2 || x.id === 1 ||x.id === 3||  x.id === 4));
                                    setState({data: data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id)))});
                                    setTotalUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))));

                                } else if (currentUser.roleName === "company") {

                                    setIscompanyLogin(true);
                                    setUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader")));
                                    setFilteredUser(userRoleList.filter(x => x.id === 3|| x.id === 2));
                                    setState({data: data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader"))});
                                    setTotalUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader")));


                                } else {
                                    setUsers(data.listOfUsers);
                                    setFilteredUser(userRoleList.filter(x => x.id === 1));
                                    setState({data: data.listOfUsers});
                                    setTotalUsers(data.listOfUsers);
                                }

                            })
                            .catch((e) => {
                                console.log(e);
                            });
                        setAddUserModalshow(false);
                        //setMessage("User Added Successfully");
                        toast.success('User Added Successfully', {position: toast.POSITION.TOP_CENTER})
                    } else {
                        setAddUserModalshow(true);
                        toast.success(response.data.Message, {position: toast.POSITION.TOP_CENTER});
                    }
                });
            }
        } else {
            setAddUserModalshow(true);
            toast.error('Kindly fill all the fields', {position: toast.POSITION.TOP_CENTER})
        }
    };

    const ChooseUser = (data,userId) =>{
        var ChooseData = state.data
        if(data.target.checked){
            ChooseData.map((item) => {
                if(item.id == userId){
                    item.checked = true
                }
            })
            chooseUser.push(userId)
            setChooseUser(chooseUser)
        }else{
            ChooseData.map((item) => {
                if(item.id == userId){
                    item.checked = false
                }
            })
            chooseUser.map((item,index) => {
                if(item == userId){
                    chooseUser.splice(index,1)
                }
            })
            setChooseUser(chooseUser)
        }
        setState({data: ChooseData});
    }
    const ChooseAll = () =>{
        var ChooseData = state.data
        var chooseUserData = [];
        ChooseData.map((item) => {
            item.checked = true;
            chooseUserData.push(item.id);
        })
        setChooseUser(chooseUserData);
        setState({data: ChooseData});
    }
    const ClearAll = () =>{
        var ChooseData = state.data;
        ChooseData.map((item) => {
            item.checked = false;
        })
        setChooseUser([]);
        setState({data: ChooseData});
    }
    //下载摸板
    const downloadTemplate = () =>{
        var downloadUrl =  window.location.href.split('user')[0]+'files/ExampleFiles/ExampleUserImport.csv';
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    }
    //上传文件
    const uploadfile = () =>{
        if(uploadContent !== ''){
            CompanyService.UploadFilePost(4,currentUser.id,uploadContent).then((response)=>{
                if(response.data.Status !== "Success"){
                    setImportModel(false)
                    toast.error('Failed to upload, please try again later.', {position: toast.POSITION.TOP_CENTER})
                }else if(response.data.Status === "Success"){
                    toast.success('File upload successfully, you can check the result in your email later.', {position: toast.POSITION.TOP_CENTER})         
                }
            })
        }else{
            toast.error('Please select Upload file!', {position: toast.POSITION.TOP_CENTER})
        }
        
    }
    //Export function
    const ExportUser = () =>{
        exportToggle()
        ClearAll()
        userService.CreateUsersFile(chooseUser).then((response) => {
            if(response.Status == "Success"){
                setExportStatus(true)
                setDownloadUrl(response.Message)
            }else{
                toast.error(response.Message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    })
            }
        })
    }
    const downloadfile = () =>{
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    }
    const SendLoginReminder = (e,userId) =>{
        if(userId){
            userService.UserEmailRemind([userId],currentUser.id).then((response) => {
                if(response.Status == "Success"){
                    toast.success(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        })
                }else if(response.Status == "Failed"){
                    toast.error(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        })
                }else if(response.Status == "NoData"){
                    toast.info(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        })
                }
            })
        }else{
            userService.UserEmailRemind(chooseUser,currentUser.id).then((response) => {
                if(response.Status == "Success"){
                    toast.success(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        })
                }else if(response.Status == "Failed"){
                    toast.error(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        })
                }else if(response.Status == "NoData"){
                    toast.info(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        })
                }
            })
        }
       
    }
    const onSort = (column) => {
        const direction =
            state.sort != undefined && state.sort.column
                ? state.sort.direction === "asc"
                ? "desc"
                : "asc"
                : "desc";
        const sortedData = state.data.sort((a, b) => {
            if (column === "FirstName") {
                if (direction === "desc") {
                    setArrowPositionFirstName(faSortAlphaUp);
                } else {
                    setArrowPositionFirstName(faSortAlphaDown);
                }
                const nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === "LastName") {
                if (direction === "desc") {
                    setArrowPositionLastName(faSortAlphaUp);
                } else {
                    setArrowPositionLastName(faSortAlphaDown);
                }
                const nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === "RegisterationDate") {
                if (direction === "desc") {
                    setArrowPositionRegisterationDate(faSortNumericUp);
                } else {
                    setArrowPositionRegisterationDate(faSortNumericDown);
                }
                const nameA = a.registration_date; // ignore upper and lowercase
                const nameB = b.registration_date; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === "Company") {
                if (direction === "desc") {
                    setArrowPositionCompany(faSortNumericUp);
                } else {
                    setArrowPositionCompany(faSortNumericDown);
                }

                const nameA = a.CompanyName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.CompanyName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            if (column === "Partner") {
                if (direction === "desc") {
                    setArrowPositionPartner(faSortNumericUp);
                } else {
                    setArrowPositionPartner(faSortNumericDown);
                }

                const nameA = a.partnerName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.partnerName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            if (column === "Role") {
                if (direction === "desc") {
                    setArrowPositionRole(faSortAlphaUp);
                } else {
                    setArrowPositionRole(faSortAlphaDown);
                }

                const nameA = a.roleName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.roleName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            if (column === "JobRole") {
                if (direction === "desc") {
                    setArrowPositionJobRole(faSortNumericUp);
                } else {
                    setArrowPositionJobRole(faSortNumericDown);
                }

                const nameA = a.jobRoleName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.jobRoleName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            if (column === "Country") {
                if (direction === "desc") {
                    setArrowPositionCountry(faSortNumericUp);
                } else {
                    setArrowPositionCountry(faSortNumericDown);
                }

                const nameA = a.countryName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.countryName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            if (column === "State") {
                if (direction === "desc") {
                    setArrowPositionState(faSortNumericUp);
                } else {
                    setArrowPositionState(faSortNumericDown);
                }

                const nameA = a.state.toUpperCase(); // ignore upper and lowercase
                const nameB = b.state.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            if (column === "Position") {
                if (direction === "desc") {
                    setArrowPositionForPosition(faSortNumericUp);
                } else {
                    setArrowPositionForPosition(faSortNumericDown);
                }

                const nameA = a.positionName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.positionName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
        });

        if (direction === "desc") {
            setUsers(sortedData.reverse());
        }
        setState({
            data: sortedData,
            sort: {
                column,
                direction,
            },
        });
    };
    const ClearAllFilterinfo =() =>{
        setPartnerIdFilter(0);
        setCompanyIdFilter("0");
        setProLevelUserProfile("0");
        setPositionUserProfile("0");
        setGeoInfluenceUserProfile("0");
        setExperienceUserProfile("0");
        setIndustryUserProfile("0");
        setEducationUserProfile("0");
        setSpecialistUserProfile("0");
        setSectorUserProfile("0");
        setCountryUserProfile("0");
        setJobLevelUserProfile("");
        setJobRoleUserProfile("0");
        setFirstNameFilter("");
        setEmailAddressFilter("");
        setLastNameFilter("");
        setRoleFilter(0);
        setPostCodeFilterProfile("");

    }
    const btnClick_addUserRoleChange = () => {
        let UserRole = Number(document.getElementsByClassName('Change-User-Role')[0].options[document.getElementsByClassName('Change-User-Role')[0].selectedIndex].value)
        if (userCurrentRoleId !== UserRole) {
            var message = userService
                .UpdateUserRole(userId,UserRole)
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    if (data.Message !== "success") {
                        setAddUserRoleChangeModalShow(true);
                        //setRoleChangeMessage("There is some error saving the role, please try again");
                        toast.error(data.Message, {position: toast.POSITION.TOP_CENTER})
                    } else {
                        var userDataList = userService.GetUserList();

                        var details = userDataList
                            .then((response) => {
                                return response;
                            })
                            .then((data) => {
                                if (currentUser.roleName === "partner") {

                                    setUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))));
                                    setFilteredUser(userRoleList.filter(x => x.id === 2 || x.id === 1 ||x.id === 3||  x.id === 4));
                                    setState({data: data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id)))});
                                    setTotalUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" ||x.roleName === "team-leader"|| x.roleName === "company" || x.roleName === "user" || (x.roleName === "partner"&&x.id !== currentUser.id))));

                                } else if (currentUser.roleName === "company") {

                                    setIscompanyLogin(true);
                                    setUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader")));
                                    setFilteredUser(userRoleList.filter(x => x.id === 3|| x.id === 2));
                                    setState({data: data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader"))});
                                    setTotalUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && (x.roleName === "team-member"||x.roleName === "team-leader")));


                                } else {
                                    setUsers(data.listOfUsers);
                                    setFilteredUser(userRoleList.filter(x => x.id === 1));
                                    setState({data: data.listOfUsers});
                                    setTotalUsers(data.listOfUsers);
                                }

                            })
                            .catch((e) => {
                                console.log(e);
                            });
                        setRoleChangeMessage("");

                        toast.success('Role Updated Successfully', {position: toast.POSITION.TOP_CENTER})
                        setAddUserRoleChangeModalShow(false);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            setRoleChangeMessage("");

            toast.success('Role Updated Successfully', {position: toast.POSITION.TOP_CENTER})
            setAddUserRoleChangeModalShow(false);
        }
    };

    {
        /*...................................................End User list operatios code part..............................................................*/
    }

    // Filters
    const [filterViewShow, setFilterViewShow] = useState(false);
    const [partnerIdFilter, setPartnerIdFilter] = useState(0);
    const [companyIdFilter, setCompanyIdFilter] = useState("0");
    const [proLevelUserProfile, setProLevelUserProfile] = useState("0");
    const [stateUserProfile, setStateUserProfile] = useState("0");
    const [positionUserProfile, setPositionUserProfile] = useState("0");
    const [geoInfluenceUserProfile, setGeoInfluenceUserProfile] = useState("0");
    const [experienceUserProfile, setExperienceUserProfile] = useState("0");
    const [industryUserProfile, setIndustryUserProfile] = useState("0");
    const [educationUserProfile, setEducationUserProfile] = useState("0");
    const [specialistUserProfile, setSpecialistUserProfile] = useState("0");
    const [sectorUserProfile, setSectorUserProfile] = useState("0");
    const [countryUserProfile, setCountryUserProfile] = useState("0");
    const [jobLevelUserProfile, setJobLevelUserProfile] = useState(null);
    const [jobRoleUserProfile, setJobRoleUserProfile] = useState("0");
    const [firstNameFilter, setFirstNameFilter] = useState(null);
    const [emailAddressFilter, setEmailAddressFilter] = useState(null);
    const [lastNameFilter, setLastNameFilter] = useState(null);
    const [roleFilter, setRoleFilter] = useState(0);
    const [postCodeFilterProfile, setPostCodeFilterProfile] = useState(null);
    const filterView = () => {
        setFilterViewShow((s) => !s);
    };

    const handleCompanyChange = (e) => {
        setCompanyIdFilter(e.target.value);
    };
    const handlePartnerChangeFilter = (e) => {
        setPartnerIdFilter(e.target.value);
    };

    const onChangeJobRoleFilter = (e) => {
        setJobRoleUserProfile(e.target.value);
    };
    const onChangeCompanyCountrySignup = (e) => {
        const companyCountry = e.target.value;
        setCountryUserProfile(companyCountry);
    };
    const onChangeCompanyStateSignup = (e) => {
        const companyState = e.target.value;
        setStateUserProfile(companyState);
    };
    const onChangeExperience = (e) => {
        const experience = e.target.value;
        setExperienceUserProfile(experience);
    };
    const onChangeIndustry = (e) => {
        const industry = e.target.value;
        setIndustryUserProfile(industry);
    };
    const onChangeEducation = (e) => {
        const education = e.target.value;
        setEducationUserProfile(education);
    };
    const onChangePositionUserProfile = (e) => {
        const position = e.target.value;
        setPositionUserProfile(position);
    };
    const onChangeSectorUserProfile = (e) => {
        const sector = e.target.value;
        setSectorUserProfile(sector);
    };
    const onChangeGeoInfluenceUserProfile = (e) => {
        const geoInfluence = e.target.value;
        setGeoInfluenceUserProfile(geoInfluence);
    };
    const onChangeProLevelUserProfile = (e) => {
        const proLevel = e.target.value;
        setProLevelUserProfile(proLevel);
    };
    const onChangeSpecialist = (e) => {
        const specialist = e.target.value;
        setSpecialistUserProfile(specialist);
    };
    const onChangePostCodeFilter = (e) => {
        const postCode = e.target.value;
        setPostCodeFilterProfile(postCode);
    };
    const onChangeJobLevel = (e) => {
        const jobLevel = e.target.value;
        setJobLevelUserProfile(jobLevel);
    };
    const onChangeLastNameFilter = (e) => {
        const lastName = e.target.value;
        setLastNameFilter(lastName);
    };
    const onChangeFirstNameFilter = (e) => {
        const firstName = e.target.value;
        setFirstNameFilter(firstName);
    };
    const onChangeEmailAddressFilter = (e) => {
        const firstName = e.target.value;
        setEmailAddressFilter(firstName);
    };
    const onChangeRoleFilter = (e) => {
        setRoleFilter(e.target.value);
    };
    const onChangeCompanyAdditionalLogo = (e) => {
        const additionalimage = e.target.files[0];
        if(additionalimage.type === "text/csv"){
            if(e.target.files[0]){
                setUploadName(e.target.files[0].name)
                setUploadContent(additionalimage);
            }else{
                setUploadName('Choose file');
                setUploadContent('');
            }
        }else{
            toast.error("Only csv files can be uploaded", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                })
        } 
    };
    
    const onFilterButtonClick = () => {
        ClearAll();
        let data1 = totalUsers;
        if (
            firstNameFilter != null &&
            firstNameFilter !== undefined &&
            firstNameFilter !== ""
        ) {
            data1 = data1.filter(
                (x) =>
                    x.FirstName.toUpperCase().indexOf(firstNameFilter.toUpperCase()) > -1
            );
        }
        if (
            lastNameFilter != null &&
            lastNameFilter !== undefined &&
            lastNameFilter !== ""
        ) {
            data1 = data1.filter(
                (x) =>
                    x.LastName.toUpperCase().indexOf(lastNameFilter.toUpperCase()) > -1
            );
        }
        if (roleFilter != 0) {
            data1 = data1.filter((x) => x.roleName == roleFilter);
        }
        if (
            emailAddressFilter != null &&
            emailAddressFilter !== undefined &&
            emailAddressFilter !== ""
        ) {
            data1 = data1.filter((x) => x.Email.indexOf(emailAddressFilter) != -1);
        }
        if (companyIdFilter != 0) {
            data1 = data1.filter((x) => x.CompanyName == companyIdFilter);
        }
        if (partnerIdFilter != 0) {
            data1 = data1.filter((x) => x.partnerName == partnerIdFilter);
        }
        if (industryUserProfile != 0) {
            data1 = data1.filter((x) => x.industryName == industryUserProfile);
        }
        if (educationUserProfile != 0) {
            data1 = data1.filter((x) => x.educationName == educationUserProfile);
        }
        if (experienceUserProfile != 0) {
            data1 = data1.filter((x) => x.experienceName == experienceUserProfile);
        }
        if (jobRoleUserProfile != 0) {
            data1 = data1.filter((x) => x.jobRoleName == jobRoleUserProfile);
        }
        if (countryUserProfile != 0) {
            data1 = data1.filter((x) => x.countryName == countryUserProfile);
        }
        if (stateUserProfile != 0) {
            data1 = data1.filter((x) => x.state == stateUserProfile);
        }
        if (
            postCodeFilterProfile != null &&
            postCodeFilterProfile != undefined &&
            postCodeFilterProfile != ""
        ) {
            data1 = data1.filter((x) => x.postcode == postCodeFilterProfile);
        }
        if (
            jobLevelUserProfile != null &&
            jobLevelUserProfile != undefined &&
            jobLevelUserProfile != ""
        ) {
            data1 = data1.filter(
                (x) => x.job_level.indexOf(jobLevelUserProfile) > -1
            );
        }
        if (specialistUserProfile != 0) {
            console.log(specialistUserProfile);
            data1 = data1.filter((x) => x.specialist.includes(Number(specialistUserProfile)));
        }
        if (proLevelUserProfile != 0) {
            data1 = data1.filter((x) => x.proLevelName == proLevelUserProfile);
        }
        if (geoInfluenceUserProfile != 0) {
            data1 = data1.filter(
                (x) => x.geoInfluenceName == geoInfluenceUserProfile
            );
        }
        if (sectorUserProfile != 0) {
            data1 = data1.filter((x) => x.sectorName == sectorUserProfile);
        }
        if (positionUserProfile != 0) {
            data1 = data1.filter((x) => x.positionName == positionUserProfile);
        }
        setCurrentPage(1);
        setState({data: data1});
    };
    {
        /*..............................................................End filter view  section code................................................................*/
    }

    if (currentUser || currentCompany) {
        return (
            <>
                <Row className="mb-2 mb-xl-4">
                    <Col xs="auto" className="ml-auto text-right mt-n1">
                        <Button
                            color="primary"
                            className="shadow-sm mr-1"
                            onClick={filterView}
                        >
                            <Filter className="feather"/>
                        </Button>
                        {/* 暂时隐藏（设置按钮）
                        <Button color="primary" className="shadow-sm">
                            <Settings className="feather"/>
                        </Button> */}
                    </Col>
                </Row>

                <Row>
                    <Col md={filterViewShow ? "9" : "12"}>
                        <Card>
                            <CardHeader style={{paddingBottom:"0"}}>
                                <CardTitle className="mb-0 d-flex align-items-center">
                                    <h2 className="d-inline-block mr-3 mb-0">Users</h2>
                                    <button
                                        className="p-2 text-white bg-primary rounded-circle"
                                        onClick={addUserModal}
                                    >
                                        <Plus size={28}/>
                                    </button>
                                    {(currentUser.roleName === "company" || currentUser.roleName === "partner"||currentUser.roleName === "super-admin")&&
                                        <div  style={{position: 'absolute',right: '1rem'}} >
                                            {chooseUser.length > 0 &&
                                                <div style={{display:"inline-block",marginRight:"0.4rem"}}>
                                                    <div className="closeicon" onClick={ClearAll}></div>
                                                    {chooseUser.length} Selected
                                                </div>
                                            }
                                            <button
                                                className="shadow-sm mr-1 btn btn-primary"
                                                onClick={ChooseAll}
                                            >
                                                Select All
                                            </button>
                                            <button
                                                className="shadow-sm mr-1 btn btn-primary disabledcolor"
                                                onClick={SendLoginReminder}
                                                disabled={chooseUser.length > 0 ? false : true}
                                            >
                                                Send Login Reminder
                                            </button>
                                            <button
                                                className="shadow-sm mr-1 btn btn-primary disabledcolor"
                                                onClick={importToggle}
                                            >
                                                Import
                                                <img src={icondownload} width="16" height="16" style={{margin:'0 0 0.2rem 0.5rem'}} alt=""/>
                                            </button>
                                            <button
                                                className="shadow-sm mr-1 btn btn-primary disabledcolor"
                                                onClick={ExportUser}
                                                disabled={chooseUser.length > 0 ? false : true}
                                            >
                                                Export
                                                <img src={iconExport} width="16" height="16" style={{margin:'0 0 0.2rem 0.5rem'}} alt=""/>
                                            </button>
                                        </div>
                                    }        
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/*model for import*/}
                                <Modal
                                    show={importModel}
                                    onHide={importToggle}
                                    backdrop="static"
                                    centered
                                    size="sm"
                                >
                                    <ModalHeader toggle={importToggle} style={{border: 'none',paddingLeft:"1.7rem",marginTop:"0.7rem",paddingRight:"1.7rem"}}>
                                        <div style={{color:"#000000",fontSize:"1.3rem",fontWeight:"bold",marginBottom:"0"}}>Import Users</div>
                                    </ModalHeader>
                                    <hr className="mx-auto"
                                     style={{display: 'block',width: '86%',margin:'0'}}
                                    />
                                    <ModalBody style={{padding:"1rem 1.8rem 0.7rem 1.8rem",fontSize:"11px"}}>
                                        You can use this import function to create multiple users and assign assessments to those users.<br/>
                                        (Please note you can only import users for companies using this)<br/>
                                        <p></p>
                                        Please use the Example button to download the upload template.<br/>
                                        <p></p>
                                        <div className="input-group" style={{fontSize:'1rem',width:"90%",marginLeft:"1rem"}}>
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile05" aria-describedby="inputGroupFileAddon05" onChange={onChangeCompanyAdditionalLogo}></input>
                                                <label className="custom-file-label" style={{overflow: 'hidden',lineHeight: '1.8rem',whiteSpace:'nowrap',textOverflow: 'ellipsis',paddingRight: '5rem'}}htmlFor="inputGroupFile05">{uploadName}</label>
                                            </div>   
                                        </div>
                                    </ModalBody>
                                    <ModalFooter style={{borderTop: 'none',padding: '0.75rem 1.5rem',marginBottom:'0.8rem'}}>
                                        <button variant="primary" onClick={downloadTemplate} style={{width:"auto",flex:'none',color:"#00995E",backgroundColor:'#ffffff',fontWeight:"600",borderRadius:"5px",border:"1px solid",height:"2rem"}}>
                                            Example
                                        </button>
                                        <div style={{flex:"1 1 auto"}}></div>
                                        <button onClick={importToggle} style={{width:"auto",flex:'none',backgroundColor:'#ffffff',borderColor:'#000000',color:"#000000",borderRadius:"1rem",fontWeight:"600",border:"1px solid",height:"2rem",padding: '0 0.7rem'}}>
                                            Cancel
                                        </button>
                                        <button variant="primary" onClick={uploadfile} style={{width:"auto",flex:'none',borderRadius:"1rem",fontWeight:"600",height:"2rem",padding: '0 0.7rem',color: '#ffffff',background: '#00995E'}}>
                                            Upload
                                        </button>
                                    </ModalFooter>
                                </Modal>    
                                {/*model for export*/}
                                <Modal
                                    show={exportModel}
                                    onHide={exportToggle}
                                    backdrop="static"
                                    centered
                                    size="sm"
                                >
                                    <ModalHeader toggle={exportToggle} style={{border: 'none',paddingLeft:"1.7rem",marginTop:"0.7rem",paddingRight:"1.7rem"}}>
                                        <div style={{color:"#000000",fontSize:"1.3rem",fontWeight:"bold",marginBottom:"0"}}>Exporting Users</div>
                                    </ModalHeader>
                                    <hr className="mx-auto"
                                     style={{display: 'block',width: '86%',margin:'0'}}
                                    />
                                    <ModalBody style={{padding:"1rem 1.8rem 0 1.8rem",fontSize:"11px"}}>
                                        {!exportStatus &&<div style={{textAlign:"center"}}>
                                            <div className="spinner-border text-primary" style={{width: '1.5rem', height: '1.5rem'}} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <p style={{color:"#000000",margin:'0.5rem 0',fontSize:' 1.1rem',fontWeight: 'bold',marginBottom: '0'}}>Exporting users, please wait...</p>
                                            <p style={{color:"rgb(255 0 0)",margin:'0',fontWeight: '600'}}>Please don't close the window untill the task is completed.</p>
                                        </div>}
                                        {exportStatus &&<div style={{textAlign:"center"}}>
                                           <div className="iconCheck"></div>
                                           <p style={{color:"#000000",margin:'0.5rem 0',fontSize:' 1.1rem',fontWeight: 'bold',marginBottom: '0'}}>Exporting Complete!</p>
                                        </div>}
                                    </ModalBody>
                                    <ModalFooter style={{borderTop: 'none',padding: '0.75rem 1.5rem',marginBottom:'0.8rem'}}>
                                        {!exportStatus &&<button onClick={exportToggle} style={{width:"auto",flex:'none',backgroundColor:'#ffffff',borderColor:'#000000',color:"#000000",borderRadius:"1rem",fontWeight:"600",border:"1px solid",height:"2rem",padding: '0 0.7rem'}}>
                                            Cancel
                                        </button>}
                                        {exportStatus &&
                                        <button variant="primary" onClick={downloadfile} style={{width:"auto",flex:'none',borderRadius:"1rem",fontWeight:"600",height:"2rem",padding: '0 0.7rem',color: '#ffffff',background: '#00995E',margin:'0 auto'}}>
                                            Download File
                                        </button>}
                                    </ModalFooter>
                                </Modal> 
                                {/*model for add user */}
                                <Modal show={addUserModalShow} onHide={addUserDetailsModalClose} backdrop="static">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add User</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                        <span style={{color: 'red'}}>*</span> User's first name:
                                                        <Input
                                                            bsSize="lg"
                                                            type="text"
                                                            name="first-name"
                                                            placeholder="Enter user's first name"
                                                            value={firstNameSignup}
                                                            onChange={onChangeFirstName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                        <span style={{color: 'red'}}>*</span> User's last name:
                                                        <Input
                                                            bsSize="lg"
                                                            type="text"
                                                            name="last-name"
                                                            placeholder="Enter user's last name"
                                                            value={lastNameSignup}
                                                            onChange={onChangeLastName}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                    <span style={{color: 'red'}}>*</span> User's email address:
                                                        <Input
                                                            bsSize="lg"
                                                            type="email"
                                                            name="email"
                                                            placeholder="Enter user's email"
                                                            value={emailSignup}
                                                            onChange={onChangeEmailSignup}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                    <span style={{color: 'red'}}>*</span> User's phone:
                                                        <Input
                                                            bsSize="lg"
                                                            type="tel"
                                                            name="phone"
                                                            placeholder="Enter user's phone"
                                                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                            value={phoneSignup}
                                                            onChange={onChangePhoneSignup}
                                                            onBlur={validatePhone}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                    <span style={{color: 'red'}}>*</span> Temporary password:
                                                        <Popover
                                                            content={
                                                                <div>
                                                                    <p>{PasspasswordRule1? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}At least 8 characters long.</p>
                                                                    <p>{PasspasswordRule2? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one uppercase letter.</p>
                                                                    <p>{PasspasswordRule3? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one lowercase letter.</p>
                                                                    <p>{PasspasswordRule4? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one number.</p>
                                                                </div>}
                                                            placement="right"
                                                            title={
                                                                <span>
                                                                    Password requirements:
                                                                </span>}
                                                            trigger="focus">
                                                                <Input
                                                                    bsSize="lg"
                                                                    type="password"
                                                                    name="password"
                                                                    placeholder="Enter user's password"
                                                                    value={passwordSignup}
                                                                    onChange={onChangePasswordSignup}
                                                                    onFocus={onChangePasswordSignup}
                                                                />
                                                        </Popover>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                    <span style={{color: 'red'}}>*</span> Confirm password:
                                                        <Popover
                                                            content={
                                                                <div>
                                                                    <p>{PasspasswordRule1? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}At least 8 characters long.</p>
                                                                    <p>{PasspasswordRule2? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one uppercase letter.</p>
                                                                    <p>{PasspasswordRule3? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one lowercase letter.</p>
                                                                    <p>{PasspasswordRule4? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one number.</p>
                                                                </div>}
                                                            placement="right"
                                                            title={
                                                                <span>
                                                                    Password requirements:
                                                                </span>}
                                                            trigger="focus">
                                                                <Input
                                                                    bsSize="lg"
                                                                    type="password"
                                                                    name="password"
                                                                    placeholder="Confirm user's password"
                                                                    value={confirmPasswordSignup}
                                                                    onChange={onChangeConfirmPasswordSignup}
                                                                    onFocus={onChangeConfirmPasswordSignup}
                                                                />
                                                        </Popover>                                   
                                                    </FormGroup>
                                                </Col>
                                                
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                    <span style={{color: 'red'}}>*</span> Job Title:
                                                        <Input
                                                            bsSize="lg"
                                                            type="text"
                                                            name="JobTitle"
                                                            placeholder="Job Title"
                                                            value={jobTitleSignup}
                                                            onChange={onChangeJobTitleSignup}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                    Program Name:
                                                        <Input
                                                            bsSize="lg"
                                                            type="text"
                                                            name="ProgramName"
                                                            placeholder="Program Name"
                                                            value={ProgramNameSignup}
                                                            onChange={onChangeProgramNameSignup}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                    Select user's job role:
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            defaultValue={optionsData[0]}
                                                            filterOption={filterOptions}
                                                            // isDisabled={isDisabled}
                                                            // isLoading={isLoading}
                                                            isClearable={true}
                                                            // isRtl={isRtl}
                                                            isSearchable={true}
                                                            name="color"
                                                            options={optionsData}
                                                        />
                                                        {/* <select
                                                            className="custom-select"
                                                            value={currentJobRole}
                                                            onChange={onChangeJobRole}
                                                        >
                                                            <option value="0">Select JobRole</option>
                                                            {jobRoles &&
                                                            jobRoles.length > 0 &&
                                                            jobRoles.map((schema) => {
                                                                return (
                                                                    <option key={schema.id} value={schema.id}>
                                                                        {schema.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select> */}
                                                    </FormGroup>
                                                </Col>

                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                    <span style={{color: 'red'}}>*</span> Partner:
                                                        <select
                                                            className="custom-select"
                                                            value={partnerId}
                                                            onChange={handlePartnerChange}
                                                        >
                                                            <option value="0">Select Partner</option>
                                                            {partners &&
                                                            partners.length > 0 &&
                                                            partners.map((schema) => {
                                                                return (
                                                                    <option key={schema.Id} value={schema.Id}>
                                                                        {schema.PartnerName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                        For Org Assessments:
                                                        <select
                                                            className="custom-select"
                                                            disabled={disabledCompany}
                                                            value={currentCompanyAddUser}
                                                            onChange={onChangeCompanyUserAdd}
                                                        >
                                                            <option value="0">Select Client</option>
                                                            {filteredCompany &&
                                                            filteredCompany.length > 0 &&
                                                            filteredCompany.map((schema) => {
                                                                return (
                                                                    <option key={schema.Id} value={schema.Id}>
                                                                        {schema.CompanyName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                        Team:
                                                        <select
                                                            className="custom-select"
                                                            value={teamSignUp}
                                                            onChange={handleTeamSignUpChange}
                                                            disabled={disabledTeam}
                                                        >
                                                            <option value="0">Select Team</option>
                                                            {filteredTeam &&
                                                            filteredTeam.length > 0 &&
                                                            filteredTeam.map((schema) => {
                                                                return (
                                                                    <option key={schema.Id} value={schema.Id}>
                                                                        {schema.TeamName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <FormGroup>
                                                        <span style={{color: 'red'}}>*</span>System Role:
                                                        <select
                                                            className="custom-select"
                                                            value={userUpdatedRole}
                                                            onChange={handleUserRoleChange}
                                                        >
                                                            <option value="0">Select role</option>
                                                            {filteredUser &&
                                                            filteredUser.length > 0 &&
                                                            filteredUser.map((schema) => {
                                                                return (
                                                                    <option key={schema.id} value={schema.id}>
                                                                        {schema.value}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {messageAddUser && (
                                            <Alert variant="danger">{messageAddUser}</Alert>
                                        )}
                                        <Button variant="primary" onClick={btnClick_addUser}>
                                            Add User
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            onClick={addUserDetailsModalClose}
                                        >
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={addUserRoleChangeModalShow} onHide={addUserRoleChangeModalClose}
                                       backdrop="static">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Change Role</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container>
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    <FormGroup>
                                                        User's current role is :
                                                        <Input
                                                            bsSize="lg"
                                                            type="text"
                                                            name="role-name"
                                                            value={userCurrentRole}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    Change User Role to:
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <FormGroup>
                                                        <select
                                                            className="custom-select Change-User-Role"
                                                            value={userUpdatedRole}
                                                            onChange={handleUserRoleChange}
                                                        >
                                                            {/*<option value="0">Select r</option>*/}
                                                            {filteredUser &&
                                                            filteredUser.length > 0 &&
                                                            filteredUser.map((schema) => {
                                                                return (
                                                                    <option key={schema.id} value={schema.id}>
                                                                        {schema.value}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {roleChangeMessage && (
                                            <Alert variant="danger">{roleChangeMessage}</Alert>
                                        )}
                                        <Button
                                            variant="primary"
                                            onClick={btnClick_addUserRoleChange}
                                        >
                                            Save Changes
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            onClick={addUserRoleChangeModalClose}
                                        >
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/*model for add user */}

                                {/*Table for user list*/}
                                {userMessage && (
                                    <Alert variant="success" style={{width: "400px"}}>
                                        {userMessage}
                                    </Alert>
                                )}

                                <div className="scrollable scrollbar1">
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th>SELECT</th>
                                            <th></th>

                                            <th nowrap="nowrap">STATUS</th>
                                            <th nowrap="nowrap">
                                                <button
                                                    type="button"
                                                    className="SortButton"
                                                    onClick={() => onSort("FirstName")}
                                                >
                                                    FIRST NAME <FontAwesomeIcon icon={arrowPositionFirstName} />
                                                </button>
                                               
                                            </th>
                                            <th nowrap="nowrap">
                                                <button
                                                    type="button"
                                                    className="SortButton"
                                                    onClick={() => onSort("LastName")}
                                                >
                                                    LAST NAME <FontAwesomeIcon icon={arrowPositionLastName} />
                                                </button>
                                            </th>

                                            {/* <th>Industry</th> */}
                                            {/*<th>Partner Name</th>*/}
                                            <th nowrap="nowrap">PHONE</th>
                                            <th nowrap="nowrap">
                                                <div
                                                    // type="button"
                                                    // className="SortButton"
                                                    // onClick={() => onSort("RegisterationDate")}
                                                >
                                                    REGISTRATION DATE{" "}
                                                    <i className={arrowPositionRegisterationDate}></i>
                                                </div>
                                            </th>
                                            <th nowrap="nowrap">EMAIL</th>
                                            {/* <th>
                                                <button
                                                    type="button"
                                                    className="SortButton"
                                                    onClick={() => onSort("Company")}
                                                >
                                                    Company <i className={arrowPositionCompany}></i>
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className="SortButton"
                                                    onClick={() => onSort("Partner")}
                                                >
                                                    Partner <i className={arrowPositionPartner}></i>
                                                </button>
                                            </th>
                                            <th>Education</th>
                                            <th>Experience</th> */}
                                            <th nowrap="nowrap">
                                                <button
                                                    type="button"
                                                    className="SortButton"
                                                    onClick={() => onSort("Role")}
                                                >
                                                    ROLE <FontAwesomeIcon icon={arrowPositionRole} />
                                                </button>
                                            </th>
                                            {/* <th>
                                                <button
                                                    type="button"
                                                    className="SortButton"
                                                    onClick={() => onSort("JobRole")}
                                                >
                                                    JobRole <i className={arrowPositionJobRole}></i>
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className="SortButton"
                                                    onClick={() => onSort("Country")}
                                                >
                                                    Country <i className={arrowPositionCountry}></i>
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className="SortButton"
                                                    onClick={() => onSort("State")}
                                                >
                                                    State <i className={arrowPositionState}></i>
                                                </button>
                                            </th>
                                            <th>Post Code</th>
                                            <th>Specialist</th>
                                            <th>Pro Level</th>
                                            <th>Geo Influence</th> */}
                                            <th nowrap="nowrap">SECTOR</th>
                                            <th nowrap="nowrap">
                                                <div
                                                    // type="button"
                                                    // className="SortButton"
                                                    // onClick={() => onSort("Position")}
                                                >
                                                    POSITION{" "}
                                                    <i className={arrowPositionForPosition}></i>
                                                </div>
                                            </th>
                                            <th nowrap="nowrap">REMINDER</th>
                                            <th nowrap="nowrap"
                                                style={{
                                                    display: iscompanyLogin ? "none" : "revert",
                                                }}
                                            >
                                                CHANGE ROLE
                                            </th>
                                            <th nowrap="nowrap">REMOVE</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {state.data
                                            .slice(indexOfFirstPost, indexOfLastPost)
                                            .map((userItem) => {
                                                return (
                                                    <tr key={userItem.id}>
                                                        <td>
                                                            <label className="checkbox-container">
                                                                <input type="checkbox" readOnly  checked={userItem.checked ? true : false} onClick={(e) =>{ChooseUser(e,userItem.id)}}/>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <div
                                                            style={{cursor:'pointer'}}
                                                             onClick={() =>
                                                                handleLinkClk(
                                                                    userItem.id,
                                                                    userItem.FirstName,
                                                                    userItem.LastName
                                                                )}
                                                            >
                                                            <img
                                                                src={imgDefaultAvatar}
                                                                style={{width: "50px"}}
                                                                alt=""
                                                            />
                                                            </div>
                                                        </td>

                                                        <td>
                                                            {userItem.user_status ? 
                                                               <div>Active</div>
                                                             : 
                                                                <div>Inactive</div>
                                                            }
                                                            {/* {userItem.roleName === "team-leader" ? (
                                                                <FontAwesomeIcon
                                                                    className="align-middle mr-2"
                                                                    icon={faStar}
                                                                    fixedWidth
                                                                />
                                                            ) : (
                                                                <div></div>
                                                            )} */}
                                                        </td>

                                                        <td>{userItem.FirstName}</td>
                                                        <td> {userItem.LastName}</td>
                                                        {/* <td>
                                                            {userItem.industryName == ""
                                                                ? "NA"
                                                                : userItem.industryName}
                                                        </td> */}
                                                        {/*<td>{userItem.partner_name == "" ? "NA" : userItem.partner_name}</td>*/}
                                                        <td>
                                                            {userItem.phone_number == ""
                                                                ? "NA"
                                                                : userItem.phone_number}
                                                        </td>
                                                        <td>
                                                            {userItem.registration_date == ""
                                                                ? "NA"
                                                                : userItem.registration_date}
                                                        </td>
                                                        <td>
                                                            <div
                                                                className="my-pointer"
                                                                style={{cursor:'pointer'}}
                                                                onClick={() =>
                                                                    handleLinkClk(
                                                                        userItem.id,
                                                                        userItem.FirstName,
                                                                        userItem.LastName
                                                                    )
                                                                }
                                                            >
                                                                {" "}
                                                                {userItem.Email}{" "}
                                                            </div>
                                                        </td>
                                                        {/* <td>
                                                            {userItem.CompanyName == ""
                                                                ? "NA"
                                                                : userItem.CompanyName}
                                                        </td>
                                                        <td>
                                                            {userItem.partnerName == ""
                                                                ? "NA"
                                                                : userItem.partnerName}
                                                        </td>
                                                        <td>
                                                            {userItem.educationName == ""
                                                                ? "NA"
                                                                : userItem.educationName}
                                                        </td>
                                                        <td>
                                                            {userItem.experienceName == ""
                                                                ? "NA"
                                                                : userItem.experienceName}
                                                        </td> */}
                                                        <td>
                                                            {userItem.roleName == ""
                                                                ? "NA"
                                                                : userItem.roleName}
                                                        </td>
                                                        {/* <td>
                                                            {userItem.jobRoleName == ""
                                                                ? "NA"
                                                                : userItem.jobRoleName}
                                                        </td>
                                                        <td>
                                                            {userItem.countryName == ""
                                                                ? "NA"
                                                                : userItem.countryName}
                                                        </td>
                                                        <td>
                                                            {userItem.state == "" ? "NA" : userItem.state}
                                                        </td>
                                                        <td>
                                                            {userItem.postcode == "0"
                                                                ? "NA"
                                                                : userItem.postcode}
                                                        </td>
                                                        <td>
                                                            {userItem.specialistName == ""
                                                                ? "NA"
                                                                : userItem.specialistName}
                                                        </td>
                                                        <td>
                                                            {userItem.proLevelName == ""
                                                                ? "NA"
                                                                : userItem.proLevelName}
                                                        </td>
                                                        <td>
                                                            {userItem.geoInfluenceName == ""
                                                                ? "NA"
                                                                : userItem.geoInfluenceName}
                                                        </td> */}
                                                        <td>
                                                            {userItem.sectorName == ""
                                                                ? "NA"
                                                                : userItem.sectorName}
                                                        </td>
                                                        <td>
                                                            {userItem.positionName == ""
                                                                ? "NA"
                                                                : userItem.positionName}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn mx-auto d-block"
                                                                onClick={(e) =>
                                                                    SendLoginReminder(e, userItem.id)
                                                                }
                                                            >
                                                                <img src={iconEmail} width="45" height="45" alt=""/>
                                                            </button>
                                                        </td>
                                                        <td
                                                            style={{
                                                                display: iscompanyLogin ? "none" : "revert",
                                                            }}
                                                        >
                                                            <button
                                                                className="btn mx-auto d-block"
                                                                onClick={() =>
                                                                    ChangeRoleModal(
                                                                        userItem.id,
                                                                        userItem.roleName,
                                                                        userItem.RoleId
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={iconChangeRole}
                                                                    alt=""
                                                                    // className=""
                                                                />
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn mx-auto d-block"
                                                                onClick={() =>
                                                                    showDeleteModal("user", userItem.id)
                                                                }
                                                            >
                                                                <img src={iconDeleteUser} alt=""/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination 
                                        defaultPageSize={6}
                                        showSizeChanger={false}
                                        onChange={paginate}
                                        defaultCurrent={1}
                                        current={currentPage}
                                        total={state.data.length == 0 ? 1 : state.data.length}
                                        style={{margin:'1rem 0'}}
                                    />
                                    {/*Table for user list*/}
                                </div>
                            </CardBody>
                            <DeleteConfirmation
                                showModal={displayConfirmationModal}
                                confirmModal={submitDelete}
                                hideModal={hideConfirmationModal}
                                type={type}
                                id={id}
                                message={deleteMessage}
                            />
                        </Card>
                    </Col>

                    {/*........................................ filter view................................................................................ */}
                    {filterViewShow ? (
                        <Col md={3}>
                            <Card className="app-filters">
                                <CardHeader className="px-4 border-bottom">
                                    <div style={{float: "right"}}>
                                        <button
                                            className="btn text-dark rounded-pill"
                                            onClick={() => setFilterViewShow(false)}
                                        >
                                            <X size={35}></X>
                                        </button>
                                    </div>
                                    <p className="mb-0 h1  text-left text-primary font-weight-bold">
                                        <Filter size={40}></Filter> Filter
                                    </p>
                                    <div style={{position: 'relative',height: '2rem'}}>
                                        <Button style={{background: '#ffffff',color: '#00995E',position: 'absolute',right: '0',top: '0.1rem',height: '2rem'}}onClick={ClearAllFilterinfo}>Clear all</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Email Address</Label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="email-ddress"
                                                placeholder="EMAIL ADDRESS"
                                                value={emailAddressFilter}
                                                onChange={onChangeEmailAddressFilter}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>First Name</Label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="first-name"
                                                placeholder="FIRST NAME"
                                                value={firstNameFilter}
                                                onChange={onChangeFirstNameFilter}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Last Name</Label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="first-name"
                                                placeholder="LAST NAME"
                                                value={lastNameFilter}
                                                onChange={onChangeLastNameFilter}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Role</Label>
                                            <select
                                                name="role"
                                                className="form-control"
                                                value={roleFilter}
                                                onChange={onChangeRoleFilter}
                                            >
                                                <option value="0">Role</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex((t) => t.roleName === v.roleName) ===
                                                            i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.roleName}
                                                                value={schema.roleName}
                                                            >
                                                                {schema.roleName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    {currentUser.roleName !== "team-leader" && <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Client</Label>
                                            <select
                                                className="custom-select"
                                                value={companyIdFilter}
                                                onChange={handleCompanyChange}
                                            >
                                                <option key="0">Client</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.CompanyName === v.CompanyName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.CompanyName}
                                                                value={schema.CompanyName}
                                                            >
                                                                {schema.CompanyName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>}

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Partner</Label>
                                            <select
                                                className="custom-select"
                                                value={partnerIdFilter}
                                                onChange={handlePartnerChangeFilter}
                                            >
                                                <option key="0">Partner</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.partnerName === v.partnerName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.partnerName}
                                                                value={schema.partnerName}
                                                            >
                                                                {schema.partnerName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Industry</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={industryUserProfile}
                                                onChange={onChangeIndustry}
                                            >
                                                <option value="0">Industry</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.industryName === v.industryName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.industryName}
                                                                value={schema.industryName}
                                                            >
                                                                {schema.industryName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Education</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={educationUserProfile}
                                                onChange={onChangeEducation}
                                            >
                                                <option value="0">Education</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.educationName === v.educationName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.educationName}
                                                                value={schema.educationName}
                                                            >
                                                                {schema.educationName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Experience</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={experienceUserProfile}
                                                onChange={onChangeExperience}
                                            >
                                                <option value="0">Experience</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.experienceName === v.experienceName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.experienceName}
                                                                value={schema.experienceName}
                                                            >
                                                                {schema.experienceName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Job Role</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={jobRoleUserProfile}
                                                onChange={onChangeJobRoleFilter}
                                            >
                                                <option value="0">JobRole</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.jobRoleName === v.jobRoleName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.jobRoleName}
                                                                value={schema.jobRoleName}
                                                            >
                                                                {schema.jobRoleName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Country</Label>
                                            <select
                                                name="country"
                                                className="form-control"
                                                value={countryUserProfile}
                                                onChange={onChangeCompanyCountrySignup}
                                            >
                                                <option value="0">Country</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.countryName === v.countryName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.countryName}
                                                                value={schema.countryName}
                                                            >
                                                                {schema.countryName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>State</Label>
                                            <select
                                                name="state"
                                                className="form-control"
                                                value={countryUserProfile}
                                                onChange={onChangeCompanyStateSignup}
                                            >
                                                <option value="0">State</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex((t) => t.state === v.state) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option key={schema.state} value={schema.state}>
                                                                {schema.state}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Post Code</Label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="post-code"
                                                placeholder="Post code"
                                                value={postCodeFilterProfile}
                                                onChange={onChangePostCodeFilter}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Job Level</Label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="job-level"
                                                placeholder="Job level"
                                                value={jobLevelUserProfile}
                                                onChange={onChangeJobLevel}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Specialist</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={specialistUserProfile}
                                                onChange={onChangeSpecialist}
                                            >
                                                <option value="0">Specialist</option>
                                                {specialistNames.filter(x=>{
                                                 return specialistNamesdata.includes(x.id)
                                                }).map(schema=>{
                                                        return (
                                                        <option
                                                            key={schema.id}
                                                            value={schema.id}
                                                        >
                                                            {schema.name}
                                                        </option>);
                                                    })
                                                }      
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Pro level</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={proLevelUserProfile}
                                                onChange={onChangeProLevelUserProfile}
                                            >
                                                <option value="0">Pro-Level</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.proLevelName === v.proLevelName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.proLevelName}
                                                                value={schema.proLevelName}
                                                            >
                                                                {schema.proLevelName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Geo Influence</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={geoInfluenceUserProfile}
                                                onChange={onChangeGeoInfluenceUserProfile}
                                            >
                                                <option value="0">Geo-Influence</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.geoInfluenceName === v.geoInfluenceName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.geoInfluenceName}
                                                                value={schema.geoInfluenceName}
                                                            >
                                                                {schema.geoInfluenceName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Sector</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={sectorUserProfile}
                                                onChange={onChangeSectorUserProfile}
                                            >
                                                <option value="0">Sector</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.sectorName === v.sectorName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.sectorName}
                                                                value={schema.sectorName}
                                                            >
                                                                {schema.sectorName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Position</Label>
                                            <select
                                                name="expirience"
                                                className="form-control"
                                                value={positionUserProfile}
                                                onChange={onChangePositionUserProfile}
                                            >
                                                <option value="0">Position</option>
                                                {totalUsers
                                                    .filter(
                                                        (v, i, a) =>
                                                            a.findIndex(
                                                                (t) => t.positionName === v.positionName
                                                            ) === i
                                                    )
                                                    .map((schema) => {
                                                        return (
                                                            <option
                                                                key={schema.positionName}
                                                                value={schema.positionName}
                                                            >
                                                                {schema.positionName}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <div className="text-center">
                                            <button
                                                className="btn btn-primary d-block col-12"
                                                onClick={onFilterButtonClick}
                                            >
                                                <Search></Search> Search
                                            </button>
                                        </div>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    ) : (
                        <div></div>
                    )}
                </Row>
            </>
        );
    } else {
        return <Card></Card>;
    }
};

const Tables = (props) => (
    <div id="team_leader_view_div">
        <SgaUsers {...props} />
    </div>
);

export default Tables;
