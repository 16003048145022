import React from "react";
import { Radar } from "react-chartjs-2";
import { connect } from "react-redux";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
const RadarChart = ({ theme, title, description, chartData }) => {
  const data = {
    labels: chartData.labels,
    datasets: chartData.datasets
  };

  const options = { 
    maintainAspectRatio: false,
    scales: {
        r:{
            beginAtZero:true,
            suggestedMin: 0,
            suggestedMax: 100
        }
    },
    plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 20
              }
          }
      }
    },
  };

  return (
    <Card className="shadow-none">
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
        <h6 className="card-subtitle text-muted">
          {description}
        </h6>
      </CardHeader>
      <CardBody style={{paddingTop:'0'}}>
        <div className="chart" style={{height:'44rem'}}>
          <Radar data={data} options={options} />
        </div>
      </CardBody>
    </Card>
  );
};

export default connect(store => ({
  theme: store.theme.currentTheme
}))(RadarChart);
