import React, { useState, useEffect } from "react";

// Components
import {Container , ModalHeader,ModalBody,ModalFooter, Card, CardBody, Media, Table, Progress, Row, Col, Input, FormGroup} from "reactstrap";
import { Pagination } from 'antd';
import { Modal,Button} from "react-bootstrap";
// Services
import AuthService from "../../../services/auth.service.js";
import assessmentService from "../../../services/assessment.service.js";
import dayjs from 'dayjs';
// Assets
import dashboard_1 from "../../../assets/img/photos/dashboard_1.png";
import dashboard_2 from "../../../assets/img/photos/dashboard_2.png";
import dashboard_3 from "../../../assets/img/photos/dashboard_3.png";
import resultdashboard_1 from "../../../assets/img/icons/resultsIcon.svg";
import shareIcon from "../../../assets/img/icons/share.png";
import {toast} from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

toast.configure()




/*
* DASHBOARD
*
* The following dashboard will include
*
* Welcome Message - (COMPLETED)
* Credit Balance - (COMPLETED)
* TODO: Show current status of the Assessments (Eg: open, pending, completed) - (OPEN)
* TODO: Latest Results of the final assessment completed - (OPEN)
*
* */

const ResultDsahboard = (props) => {
    // Get current user from local storage if exist
    var currentUser = AuthService.getCurrentUser();

    // Get current user company from local storage if exist
    var company = AuthService.getCurrentCompany();

    // Push user out to the login page if login session not found
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    sessionStorage.removeItem('results');
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);
    const [allAssessments,setAllAssessments]= useState([]);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const [assessmentDetails, setAssessmentDetails] = useState([]);
    const [firstNameVal,setFirstNameVal]= useState('');
    const [lastNameVal,setLastNameVal]= useState('');
    const [emailAddressVal,setEmailAddressVal]= useState('');
    const [companyNameVal,setCompanyNameVal]= useState('');
    const [personVal,setPersonVal]= useState('');
    const [Report_Id,setReport_Id]= useState('');
    const [textAreaVal,setTextAreaVal]= useState(``);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (Report_Id) => {
      setIsModalOpen(true);
      setFirstNameVal('');
      setLastNameVal('');
      setEmailAddressVal('');
      setCompanyNameVal('');
      setPersonVal('');
      setTextAreaVal(`Hey there,

I've just completed an HR capability analysis through AHRI (Australian Human Resources Institute) and received some great insights into where I'm at and where I can focus my development to grow my HR capability and career.

I found this AHRI member benefit really helpful, and I thought you and/or your teammates might too.

Visit the website at ahriassessments.app

${currentUser.FirstName}`);
      setReport_Id(Report_Id);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    // 输入框数据
    const onChangefirstNameVal = (e) =>{
        setFirstNameVal(e.target.value);
    }
    const onChangelastNameVal = (e) =>{
        setLastNameVal(e.target.value);
    }
    const onChangeemailAddressVal = (e) =>{
        setEmailAddressVal(e.target.value);
    }
    const onChangecompanyNameVal = (e) =>{
        console.log(e.target.value);
        setCompanyNameVal(e.target.value);
    }
    const onChangetextAreaVal = (e) =>{
        console.log(e.target.value);
        setTextAreaVal(e.target.value);
    }
    const onChangepersonVal = (e) =>{
        setPersonVal(e.target.value);
    }

    const share = () => {
        var firstName = firstNameVal;
        var lastName = lastNameVal;
        var emailAddress = emailAddressVal;
        var companyName = companyNameVal;
        var person = personVal;
        var textArea = textAreaVal;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(firstName != ''&&lastName != ''&&emailAddress != ''&&companyName != ''&&person != '0'&&textArea != ''&& emailRegex.test(emailAddress)){
            assessmentService.AddAssessmentResultShare(Report_Id,firstName,lastName,emailAddress,companyName,person,textArea).then((response) => {
                if(response.Status == "Success"){
                    setIsModalOpen(false);
                    toast.success("Successfully shared!", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                }else{
                    setIsModalOpen(false);
                    toast.error(response.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((e) => {
                console.log(e);
            });
        }else{
            if(firstName != ''&&lastName != ''&&emailAddress != ''&&companyName != ''&&person != '0'&&textArea != ''&& !emailRegex.test(emailAddress)){
                toast.error("Email address is not valid!", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }else{
                toast.error("Please fill in all the fields!", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }
    
    useEffect(() =>{
        var assessmentList = assessmentService.GetAllAssessmentList(currentUser.id);
            var details = assessmentList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    for(let i = 0; i < data.length - 1; i ++) {
                        for(let j = 0; j < data.length - 1 - i; j ++) {
                            if(data[j].EndTime == ''){
                                if(data[j].Status == 'COMPLETED'){
                                    if(data[j+1].EndTime !== ''){
                                        const temp = data[j];
                                        data[j] = data[j+1];
                                        data[j+1] = temp;
                                    }
                                }else{
                                    const temp = data[j];
                                    data[j] = data[j+1];
                                    data[j+1] = temp;
                                }
                            } else{
                                if(dayjs(data[j].EndTime) < dayjs(data[j+1].EndTime)) {
                                    const temp = data[j];
                                    data[j] = data[j+1];
                                    data[j+1] = temp;
                                }
                            }
                        }
                    }
                    setAllAssessments(data.filter(x => (currentUser.roleName === "user" ? (x.UserId == currentUser.id&&currentUser.report_access==true &&x.ButtonText == "View Results"&&x.ShowResults=="True"):(x.UserId == currentUser.id))));
                })
                .catch((e) => {
                    console.log(e);
                });
        const assessmentDetail = () => {
            var assessmentDetailList = assessmentService.GetAllAssessmentDetails();

            var details = assessmentDetailList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                       
                    setAssessmentDetails(data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        assessmentDetail()
    },[])
        // Handler for starting an assessment
        const handleStartAssesmentClk = (Schema) => {
            var downloadUrl =  Schema.ReportLink;
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
        }

    return (
        <div id="dashboard_div">
            <Modal 
                show={isModalOpen}
                onHide={handleCancel}
                backdrop="static"
                centered
                size="md"
            >
                 <ModalHeader  style={{border: 'none',paddingLeft:"3rem",marginTop:"0.7rem",paddingRight:"3rem"}}>
                    <div style={{color:"#0071B8",fontSize:"1.3rem",fontWeight:"bold",marginBottom:"0"}}>SHARE WITH A COLLEAGUE OR PEER!</div>
                </ModalHeader>
                <hr className="mx-auto"
                    style={{display: 'block',width: '84%',margin:'0',border: '2px #0071B8 solid'}}
                />
                <ModalBody style={{padding:"1rem 1.8rem 0 1.8rem",fontSize:"11px"}}>
                   <p style={{color:"#4A4C57",fontSize:"15px",padding: '0 20px'}}>Thank you for sharing the Australian HR Capability Analysis.</p>
                   <p style={{color:"#4A4C57",fontSize:"15px",padding: '0 20px'}}>NOTE: Your actual results are NOT being shared! You are simply letting someone else know about this self-assessment. Aggregate data from this challenge will provide an important insight into the Human Resource capability in Australia. You can edit your email to each person so you know exactly what is being sent.</p>
                   <p style={{color:"#4A4C57",fontSize:"15px",padding: '0 20px',fontWeight:'bold'}}>The richer the data - the greater the insights!</p>
                   <p style={{color:"#4A4C57",fontSize:"15px",padding: '0 20px'}}>Please send this on to peers, colleagues, team members or your manager.</p>
                    <hr className="mx-auto"
                    style={{display: 'block',width: '92%',margin:'0',border: '2px #0071B8 solid',marginBottom:"2rem"}}
                    />
                    <Row style={{padding: '0 4%'}}>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Input
                                    className="form-control ShareModal_input"
                                    bsSize="lg"
                                    type="text"
                                    name="FirstName"
                                    placeholder="First Name"
                                    value={firstNameVal}
                                    onChange={onChangefirstNameVal}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Input
                                    className="form-control ShareModal_input"
                                    bsSize="lg"
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={lastNameVal}
                                    onChange={onChangelastNameVal}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Input
                                    className="form-control ShareModal_input"
                                    bsSize="lg"
                                    type="text"
                                    name="emailAddress"
                                    placeholder="Email Address"
                                    value={emailAddressVal}
                                    onChange={onChangeemailAddressVal}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Input
                                    className="form-control ShareModal_input"
                                    bsSize="lg"
                                    type="text"
                                    name="companyName"
                                    placeholder="Company Name"
                                    value={companyNameVal}
                                    onChange={onChangecompanyNameVal}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <select className="custom-select ShareModal_input"
                                        style={{ background:'none',appearance:'auto',height: '2.3rem',padding:' 0rem 0.5rem',lineHeight: '0.5rem',borderRadius: '10px',border: '1px solid #ced4da'}}
                                        onChange={onChangepersonVal}
                                        value={personVal}
                                >
                                    <option value="0">This person is my</option>
                                    <option value="Manager">Manager</option>
                                    <option value="Peer">Peer</option>
                                    <option value="Member of my team">Member of my team</option>
                                    <option value="Industry Colleague">Industry Colleague</option>
                                </select>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                            <FormGroup>
                            <Input
                                className="form-control ShareModal_input"
                                bsSize="lg"
                                type="textarea"
                                name="companyName"
                                placeholder=""
                                style={{minHeight:'220px'}}
                                value={textAreaVal}
                                onChange={onChangetextAreaVal}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className="mx-auto"
                    style={{display: 'block',width: '92%',margin:'0',border: '2px #0071B8 solid'}}
                    />
                   
                </ModalBody>
                <ModalFooter style={{borderTop: 'none',padding: '0.75rem 3rem',marginBottom:'0.8rem'}}>
                    <button onClick={handleCancel} style={{width:"auto",flex:'none',backgroundColor:'#ffffff',color:"#000000",borderRadius:"6px",fontWeight:"400",border:"1px #D5D5D5 solid",height:"32px",padding: '0 0.7rem'}}>
                        Cancel
                    </button>
                    <Button
                        color="primary"
                        className="shadow-sm mr-1"
                        onClick={share}
                        style={{maxWidth: '116px',height:"32px",borderRadius:"6px"}}
                    >
                        Share
                    </Button>
                </ModalFooter>
            </Modal>
            <Container fluid className="p-0">
                <Row className="mb-2 mb-xl-4">
                    <Col xs="auto" className="d-none d-sm-block">
                        <h2>Welcome to your Result Dashboard</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} xl={3}>
                        <Card className="flex-fill bg-primary text-white mb-0" style={{ height: '89%', overflow: "hidden" }}>
                            <CardBody>
                                <Media>
                                    <Media body>
                                        <h5 className="mb-2 text-white">Congratulations, </h5>
                                        <h3 className="text-white">{currentUser.FirstName}</h3>
                                        <div className="mb-0" style={{whiteSpace:'nowrap'}}>
                                            Here are your results
                                        </div>
                                    </Media>
                                    {/* <img src={resultdashboard_1} width='40%' alt="" className=" " /> */}
                                </Media>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row >
                    {allAssessments.slice(indexOfFirstPost, indexOfLastPost).map((item, i) => {
                        return(
                            <Col xs={12} md={6} xl={3} key={i} className={item.Status == 'COMPLETED' ? 'mt-3':'disableCard mt-3'}>
                                <Card className="flex-fill mb-0" style={{ height: '100%'}}>
                                    <CardBody className="py-4" style={{display: 'flex',flexDirection: 'column',justifyContent: 'space-between'}}>
                                        <div style={{ minHeight: '2rem'}}>
                                        {/* <img src={item.AssessmentIcon} style={{ height: '5rem'}}  alt="AssessmentIcon" /> */}
                                            {/* <div onClick={()=> showModal(item.Report_Id)} 
                                                style={{
                                                    display: 'flex',
                                                    alignItems:'center',
                                                    color:'#00995E',
                                                    position: 'absolute',
                                                    top:'25px',
                                                    right:"20px",
                                                    fontSize:'16px',
                                                    fontWeight:'600',
                                                    cursor:'pointer'
                                                }}>
                                                Share
                                                <img src={shareIcon} alt="AssessmentIcon" style={{height:'1.5rem',marginLeft: '0.2rem'}}></img>
                                            </div> */}
                                        </div>
                                        <p style={{fontSize:'20px',fontWeight:'700',margin:'0'}}>{item.AssessmentName}</p>
                                        <p style={{color:'#00995E',fontSize:'16px',fontWeight:'600',cursor:'pointer'}} className={item.ReportLink =='' ? 'disableCard':''} onClick={()=>{ handleStartAssesmentClk(item)}}>{item.Status == 'COMPLETED' && item.ReportLink ==''?'PREPARING (takes 1 min)':'VIEW RESULTS'}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
            <Pagination 
                defaultPageSize={12}
                showSizeChanger={false}
                onChange={paginate}
                defaultCurrent={1}
                current={currentPage}
                total={allAssessments.length}
                style={{margin:'1rem 0'}}
            />
        </div>
    );
};
export default ResultDsahboard;
