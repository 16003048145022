import React from "react";

import {  Container, Row } from "reactstrap";

import Main from "../components/Main";
import Navbar from "../components/Navbar";

const Auth = ({ children }) => (
    <React.Fragment>
        {window.location.pathname !== "/auth/interest" && <Navbar />}
        <div className="auth-box">
            <Main className="d-flex w-100 pt-1 pb-1 justify-content-center" >
                <Container >
                    <Row>
                        {children}
                    </Row>
                </Container>
            </Main>
        </div>

    </React.Fragment>
);

export default Auth;
